import Localization from "@/i18n/Localization"
import de from "@/i18n/localizations/de"
import en from "@/i18n/localizations/en"
import { injectRequired } from "@/utility"
import { App } from "vue"


const VueLocalization = {
	install(app: App) {
		const localization = Object.freeze(loadLocalization(determineLanguage()))
		app.config.globalProperties.$l = localization
		app.provide("localization", localization)
	},
}
export default VueLocalization


function determineLanguage(): "de" | "en" {
	const locales = navigator.languages ?? [navigator.language]
	for (const locale of locales) {
		const language = locale.split("-")[0]
		if (language === "de" || language === "en")
			return language
	}

	return "en"
}


function loadLocalization(language: "de" | "en"): Localization {
	switch (language) {
		case "de":
			return de
		case "en":
			return en
	}
}


export function useLocalization(): Localization {
	return injectRequired<Localization>("localization")
}
