import MatchClubStatistics from "@/model/MatchClubStatistics"
import Club from "./Club"


interface StatItem {
	homeClub: Club;
	awayClub: Club;
	isRate: boolean;
	stat: string;
	unitOfMeasurement: string;
	divisor: number;
}


export default class MatchStatistics {

	constructor(
		readonly homeClubStatistics: MatchClubStatistics,
		readonly awayClubStatistics: MatchClubStatistics,
	) {
		Object.freeze(this)
	}


	// FIXME Remove.
	formatStat({
		           stat,
		           isRate = false,
		           unitOfMeasurement = "",
		           divisor = 1,
	           }): StatItem {

		// if (!homeClubStatistics || !awayClubStatistics) return null;
		const homeClub = this.homeClubStatistics[stat]
		const awayClub = this.awayClubStatistics[stat]
		return {
			homeClub,
			awayClub,
			isRate,
			stat,
			unitOfMeasurement,
			divisor,
		}
	}

	static fromJson(json: any) {
		return new this(
			MatchClubStatistics.fromJson(json.homeClubStatistics),
			MatchClubStatistics.fromJson(json.awayClubStatistics),
		)
	}
}
