import { inject, InjectionKey } from "vue"


export function arrayEquals<Element>(a: ReadonlyArray<Element>, b: ReadonlyArray<Element>): boolean {
	if (a === b)
		return true

	return a.length === b.length && a.every((element, index) => element === b[index])
}


export function asString(value: any): string | null {
	return typeof value === "string" ? value : null
}


export function associateBy<K, T>(items: readonly T[], keySelector: (item: T, index: number) => K): Map<K, T> {
	const map = new Map<K, T>()
	items.forEach((item, index) => {
		map.set(keySelector(item, index), item)
	})

	return map
}


export function associateByTo<K, T, U>(items: readonly T[], keyValueSelector: (item: T, index: number) => [K, U]): Map<K, U> {
	const map = new Map<K, U>()
	items.forEach((item, index) => {
		const [key, value] = keyValueSelector(item, index)
		map.set(key, value)
	})

	return map
}


export function associateTo<K, T>(items: readonly K[], valueSelector: (key: K, index: number) => T): Map<K, T> {
	const map = new Map<K, T>()
	items.forEach((key, index) => {
		map.set(key, valueSelector(key, index))
	})

	return map
}


export function error(message?: string): never {
	throw new Error(message === undefined ? "This should never happen…" : message)
}


export function filterNotNull<Element>(elements: ReadonlyArray<Element | null>): Exclude<Element, null>[] {
	return elements.filter(it => it !== null) as Exclude<Element, null>[]
}


export function injectRequired<T>(key: InjectionKey<T> | string): T {
	const value = inject(key)
	if (value === undefined)
		error(`No value provided for injection key '${key.toString()}'.`)

	return value
}


export function localStorageOrNull(): Storage | null {
	try {
		return window.localStorage
	} catch {
		// Not available when in an iframe and 3rd party cookies are disabled.
		return null
	}
}


export function objectToMap<Value>(object: Record<string, Value>): Map<String, Value>

export function objectToMap<Value, TransformedValue = Value>(
	object: Record<string, Value>,
	transformValue: (value: Value, key: string) => TransformedValue,
): Map<String, TransformedValue>


export function objectToMap<Value, TransformedValue = Value>(
	object: Record<string, Value>,
	transformValue?: (value: Value, key: string) => TransformedValue,
): Map<String, TransformedValue> {
	if (transformValue === undefined)
		transformValue = it => it as unknown as TransformedValue

	const map = new Map<string, TransformedValue>()
	for (const key of Object.keys(object))
		map.set(key, transformValue(object[key], key))

	return map
}
