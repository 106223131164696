import { BufferGeometry, Material, Object3D, Texture } from "three"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"
import { IUniform } from "three/src/renderers/shaders/UniformsLib"


// https://threejsfundamentals.org/threejs/lessons/threejs-cleanup.html
export default class {

	private readonly disposables = new Set<Disposable>()

	private isDisposed = false


	add<TResource extends Resource>(resource: TResource): TResource
	add<TResource extends Resource[]>(...resource: TResource): TResource

	add<TResource extends Resource>(resource: TResource): TResource {
		if (!resource)
			return resource

		if (Array.isArray(resource)) {
			resource.forEach(it => this.add(it))

			return resource
		}

		if ("dispose" in resource)
			this.track(resource)

		if ("scenes" in resource)
			this.add(resource.scenes)

		const anyResource = resource as any

		if (resource instanceof Object3D) {
			this.add(anyResource.children as Object3D[])
			this.add(anyResource.geometry as BufferGeometry | null)
			this.add(anyResource.material as Material)
		}
		else if (resource instanceof Material) {
			for (const value of Object.values(resource))
				if (value instanceof Texture)
					this.add(value)

			if (anyResource.uniforms) {
				for (const uniform of Object.values(anyResource.uniforms) as IUniform[])
					if (uniform) {
						const uniformValue = uniform.value
						if (uniformValue instanceof Texture || Array.isArray(uniformValue))
							this.add(uniformValue)
					}
			}
		}

		return resource
	}


	dispose() {
		if (this.isDisposed)
			return

		this.isDisposed = true

		for (const disposable of this.disposables)
			disposable.dispose()

		this.disposables.clear()
	}


	private track(disposable: Disposable) {
		if (this.isDisposed)
			disposable.dispose()
		else
			this.disposables.add(disposable)
	}
}


type Resource = Disposable | GLTF | Object3D | null | undefined | Resource[]


interface Disposable {

	dispose()
}
