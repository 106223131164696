
	import { MatchSquadsMemberFull } from "@/menu/VMatchSquadsClub.vue"
	import { defineComponent, PropType } from "vue"


	export default defineComponent({
		props: {
			member: { type: Object as PropType<MatchSquadsMemberFull>, required: true },
		},
		emits: {
			"playerClicked": (_: string) => true,
		},
		setup(props, { emit }) {
			function onPlayerClicked(id: string) {
				emit("playerClicked", id)
			}

			return { onPlayerClicked }
		},
	})
