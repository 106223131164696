
	import { useLocalization } from "@/i18n/VueLocalization"
	import VLiveMatchTime from "@/live/components/VLiveMatchTime.vue"
	import LiveState from "@/live/model/LiveState"
	import { VMMatchStatistics } from "@/statistics/model/VMMatchStatistics"
	import VMatchStatistics from "@/statistics/views/VMatchStatistics.vue"
	import { computed, defineComponent, PropType } from "vue"


	export default defineComponent({
		components: {
			VMatchStatistics,
			VLiveMatchTime,
		},
		props: {
			state: { type: Object as PropType<LiveState>, required: true },
		},
		emits: {
			"playerClicked": (_: string) => true,
		},
		setup(props, { emit }) {
			const $l = useLocalization()

			const awayClubRef = computed(() => props.state.fixture.awayClub)
			const homeClubRef = computed(() => props.state.fixture.homeClub)
			const matchIsRunningRef = computed(() => ["inFirstHalf", "inSecondHalf"].includes(props.state.status))
			const scoreRef = computed(() => props.state.score)
			const statisticsRef = computed(() => props.state.statistics ? VMMatchStatistics(props.state.statistics, props.state.players, $l) : null)
			const styleRef = computed(() => ({
				"--VMatchStatisticsScreen-awayClubLogoBackground": `url("${awayClubRef.value.logoUrl}")`,
				"--VMatchStatisticsScreen-homeClubLogoBackground": `url("${homeClubRef.value.logoUrl}")`,
			}))

			function onPlayerClicked(id: string) {
				emit("playerClicked", id)
			}

			return {
				awayClub: awayClubRef,
				homeClub: homeClubRef,
				matchIsRunning: matchIsRunningRef,
				onPlayerClicked,
				score: scoreRef,
				statistics: statisticsRef,
				style: styleRef,
			}
		},
	})
