
	import { computed, defineComponent } from "vue"


	export default defineComponent({
		props: {
			color: { type: String, required: true },
			iconUrl: String,
			imageUrl: String,
			showsImageBackground: Boolean,
			subtitle: String,
			title: { type: String, required: true },
			title2: String,
		},
		setup(props) {
			const styleRef = computed(() => ({
				"--VMatchEventNotification-color": props.color,
				"--VMatchEventNotification-iconBackground": props.iconUrl ? `url("${props.iconUrl}")` : "none",
				"--VMatchEventNotification-imageBackgroundColor": props.showsImageBackground ? "#EDE5DC" : "none",
				"--VMatchEventNotification-imageBorderRadius": props.showsImageBackground ? "26px" : "0",
				"--VMatchEventNotification-imageBackground": props.imageUrl ? `url("${props.imageUrl}")` : "none",
			}))

			return { style: styleRef }
		},
	})
