<template>
    <div :class="$style.container" ref="containerRef">
        <div :class="$style.bar">
            <div :class="$style.played" :style="playedStyle" ref="playedRef">
            </div>
            <div :class="$style.loaded" :style="loadedStyle" ref="loadedRef">
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { onMounted, ref, watchEffect, nextTick } from "vue"

    export default {
        props:{
            minValue: { type: Number, required: true },
            maxValue: { type: Number, required: true },
            loadedValue: { type: Number, required: true },
            value: { type: Number, required: true },
        },

        setup(props) {
            const containerRef = ref<HTMLDivElement>()
            const playedStyleRef = ref<string>()
            const loadedStyleRef = ref<string>()
            const loadedRef = ref<HTMLDivElement>()

            watchEffect(() => {
                if (props.minValue > props.value) return;
                if (props.value > props.loadedValue) return;
                if (props.loadedValue > props.maxValue) return;
                const scale = props.maxValue - props.minValue
                const value = (props.value - props.minValue) / scale;
                const loadedValue = (props.loadedValue - props.minValue) / scale;
                playedStyleRef.value = `width:${value*100}%;`
                loadedStyleRef.value = `left:${value*100}%;width:${(loadedValue-value)*100}%;`
            })

            onMounted(() => {
                nextTick(() => {
                    containerRef.value!.onclick = (e) => {
                        console.log('click', e.x, e.target)

                    }
                })
                //this.loaded!.onclick = (e) => {
                //    console.log(e)
                //}
            })            

            return { 
                containerRef,
                playedStyle:playedStyleRef,
                loadedStyle:loadedStyleRef,
                loadedRef,
            }
        },
        onMounted(){
            

        }
    }

</script>

<style lang="scss" module>
    .container {
        position:absolute;
        width:80%;
        left:10%;
        bottom:0px;
        height:50px;
        margin:0 auto;
        padding:20px;
    }
    .bar {
        background-color: rgba(26, 163, 226, 0.486);
        height: 100%;
        margin:0 auto;
    }
    .played {
        float:left;
        background-color: rgb(82, 192, 255);
        height: 100%;
        
        left:0%;
        margin:0 auto;
    }
    .loaded {
        float:left;
        background-color: rgba(26, 163, 226, 0.767);
        height: 100%;
        width: 20%;
        left:30%;
        margin:0 auto;
    }    
</style>