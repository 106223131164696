
	import VMMatchStatistics from "@/statistics/model/VMMatchStatistics"
	import VMatchStatisticsCardComparisonItem from "@/statistics/views/VMatchStatisticsCardComparisonItem.vue"
	import VMatchStatisticsPlayerComparisonItem from "@/statistics/views/VMatchStatisticsPlayerComparisonItem.vue"
	import VMatchStatisticsSimpleComparisonItem from "@/statistics/views/VMatchStatisticsSimpleComparisonItem.vue"
	import { defineComponent, PropType } from "vue"


	export default defineComponent({
		components: {
			VMatchStatisticsCardComparisonItem,
			VMatchStatisticsPlayerComparisonItem,
			VMatchStatisticsSimpleComparisonItem,
		},
		props: {
			statistics: { type: Object as PropType<VMMatchStatistics>, required: true },
		},
		emits: {
			"playerClicked": (_: string) => true,
		},
		setup(props, { emit }) {
			function onPlayerClicked(id: string) {
				emit("playerClicked", id)
			}

			return {
				onPlayerClicked,
			}
		},
	})
