
    import { onMounted, ref, watchEffect, nextTick } from "vue"

    export default {
        props:{
            minValue: { type: Number, required: true },
            maxValue: { type: Number, required: true },
            loadedValue: { type: Number, required: true },
            value: { type: Number, required: true },
        },

        setup(props) {
            const containerRef = ref<HTMLDivElement>()
            const playedStyleRef = ref<string>()
            const loadedStyleRef = ref<string>()
            const loadedRef = ref<HTMLDivElement>()

            watchEffect(() => {
                if (props.minValue > props.value) return;
                if (props.value > props.loadedValue) return;
                if (props.loadedValue > props.maxValue) return;
                const scale = props.maxValue - props.minValue
                const value = (props.value - props.minValue) / scale;
                const loadedValue = (props.loadedValue - props.minValue) / scale;
                playedStyleRef.value = `width:${value*100}%;`
                loadedStyleRef.value = `left:${value*100}%;width:${(loadedValue-value)*100}%;`
            })

            onMounted(() => {
                nextTick(() => {
                    containerRef.value!.onclick = (e) => {
                        console.log('click', e.x, e.target)

                    }
                })
                //this.loaded!.onclick = (e) => {
                //    console.log(e)
                //}
            })            

            return { 
                containerRef,
                playedStyle:playedStyleRef,
                loadedStyle:loadedStyleRef,
                loadedRef,
            }
        },
        onMounted(){
            

        }
    }

