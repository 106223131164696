import MatchPositionsBall from "@/model/MatchPositionsBall"
import MatchPositionsPlayer from "@/model/MatchPositionsPlayer"


export default class MatchPositions {

	constructor(
		readonly ball: MatchPositionsBall,
		readonly players: ReadonlyMap<string, MatchPositionsPlayer>,
		readonly timestamp: number,
	) {
		Object.freeze(this)
	}
}
