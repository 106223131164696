/* 
Introduce exponential smooting to a position trajectory 
on a timescale given by the lagMilliseconds parameter. 
By applying this transform to the positions, jitter in the
trajectory is being removed, thus resulting in a more relaxed
visual impression of moving objects.

Basically the transformed (smoothened) position will be 
an average of the last transformed position and the new observation,
where the averaging factor exponentially depends on the 
time since last observation. 

It can be shown that the such transformed position does not depend on 
the frame rate (modulo finite size effects). Thus it will result in a
smooth trajectory even if frames come in irregular intervals.

*/

export default class ExpSmoothingPositionTransform {
    private lagMilliseconds: number = 10
    x = 0
    y = 0
    z = 0
    private lastTimestamp: number = 0

    constructor (
        lagMilliseconds: number
    ) {
        this.lagMilliseconds = lagMilliseconds
    }

    update(x: number, y: number, z: number) {
        const timestamp = Date.now()
		const timeDifference = timestamp - this.lastTimestamp
		const discountFactor = Math.exp( - timeDifference / this.lagMilliseconds )
		this.x = discountFactor * this.x + (1 - discountFactor) * x
		this.y = discountFactor * this.y + (1 - discountFactor) * y
		this.z = discountFactor * this.z + (1 - discountFactor) * z
		this.lastTimestamp = timestamp
    }
}