
	import LiveState from "@/live/model/LiveState"
	import { computed, defineComponent, PropType } from "vue"


	export default defineComponent({
		props: {
			state: { type: Object as PropType<LiveState>, required: true },
		},
		setup(props) {
			const time = computed(() => props.state.formattedTime() ?? "--:--")

			return { time }
		},
	})
