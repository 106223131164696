import Localization from "@/i18n/Localization"
import MatchStatistics from "@/model/MatchStatistics"
import Player from "@/model/Player"
import VMMatchStatisticsComparisonItem, {
	VMMatchStatisticsCardComparisonItem,
	VMMatchStatisticsPlayerComparisonItem,
	VMMatchStatisticsSimpleComparisonItem,
} from "@/statistics/model/VMMatchStatisticsComparisonItem"
import { VMMatchStatisticsValue } from "@/statistics/model/VMMatchStatisticsValue"
import { filterNotNull } from "@/utility"


export default interface VMMatchStatistics {

	readonly items: ReadonlyArray<VMMatchStatisticsComparisonItem>
}


export function VMMatchStatistics(
	statistics: MatchStatistics,
	players: ReadonlyMap<string, Player>,
	$l: Localization,
): VMMatchStatistics {
	return {
		items: filterNotNull([
			VMMatchStatisticsSimpleComparisonItem(
				statistics,
				$l.statistics_ballPossession,
				club => VMMatchStatisticsValue(club.ballPossessionRate * 100, 0, "%"),
			),
			VMMatchStatisticsSimpleComparisonItem(
				statistics,
				$l.statistics_passCompletion,
				club => VMMatchStatisticsValue(club.passCompletionRate * 100, 0, "%"),
				club => VMMatchStatisticsValue(club.passCompletionCount),
			),
			VMMatchStatisticsSimpleComparisonItem(
				statistics,
				$l.statistics_shotsAndOnTarget,
				club => VMMatchStatisticsValue(club.shotCount),
				club => VMMatchStatisticsValue(club.goalShotCount),
			),
			VMMatchStatisticsSimpleComparisonItem(
				statistics,
				$l.statistics_duelSuccess,
				club => VMMatchStatisticsValue(club.duelSuccessRate * 100, 0, "%"),
				club => VMMatchStatisticsValue(club.duelSuccessCount),
			),
			VMMatchStatisticsSimpleComparisonItem(
				statistics,
				$l.statistics_distanceCovered,
				club => VMMatchStatisticsValue(club.distanceCovered / 1_000, 1, " km"),
			),
			VMMatchStatisticsSimpleComparisonItem(
				statistics,
				$l.statistics_cornerKicks,
				club => VMMatchStatisticsValue(club.cornerKickCount),
			),
			VMMatchStatisticsCardComparisonItem(statistics),
			VMMatchStatisticsPlayerComparisonItem(
				statistics,
				players,
				$l.statistics_mostShotsAndOnTarget,
				club => club.mostShotsCountPlayerId,
				club => VMMatchStatisticsValue(club.mostShotsCount),
				club => VMMatchStatisticsValue(club.mostShotsOnTargetCount),
			),
			VMMatchStatisticsPlayerComparisonItem(
				statistics,
				players,
				$l.statistics_bestPassCompletionRate,
				club => club.bestPassCompletionRatePlayerId,
				club => VMMatchStatisticsValue(club.bestPassCompletionRate * 100, 0, "%"),
				club => VMMatchStatisticsValue(club.bestPassCompletionCount),
			),
			VMMatchStatisticsPlayerComparisonItem(
				statistics,
				players,
				$l.statistics_bestDuelSuccessRate,
				club => club.bestDuelSuccessRatePlayerId,
				club => VMMatchStatisticsValue(club.bestDuelSuccessRate * 100, 0, "%"),
				club => VMMatchStatisticsValue(club.bestDuelSuccessCount),
			),
		]),
	}
}
