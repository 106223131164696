<template>
	<!-- views -->
	<v-match-loading v-if="view === 'loading'"/>
	<v-match-before-start v-else-if="view === 'beforeStart'" :fixture="fixture"/>
	<v-match-visualization
		v-else-if="view === 'visualization'"
		:camera-mode="cameraMode"
		:fullscreen="fullscreen"
		:stadium-model-name="stadiumModelName"
		:state="state"
	/>
	<v-match-after-section-end v-else-if="view === 'halfTime' || view === 'afterEnd'" :state="state" @player-clicked="onPlayerClicked"/>

	<!-- overlays -->
	<v-match-score-bar v-if="showsScoreBar" :state="state"/>
	<v-match-notifications v-if="showsNotifications" :state="state"/>
	<v-control-bar
		v-if="showsControlBar"
		:camera-mode="cameraMode"
		:shows-camera-toggle="showsCameraToggle"
		:shows-sound-toggle="hasBackgroundAudio"
		v-model:sound-enabled="soundEnabled"
		@update:cameraMode="onCameraModeChanged"
	/>
	<v-menu v-if="showsMenu" :fixture="fixture" :has-sound="hasBackgroundAudio" ref="menuRef" :state="state" v-model:sound-volume="soundVolume"/>
	<v-time-slider  v-show="showsTimeSlider"
		:minValue="sliderMinValue" 
		:maxValue="sliderMaxValue" 
		:loadedValue="sliderLoadedValue"
		:value="sliderValue" />
	<v-stats v-if="showsTimeSlider" :stats="stats" />

	<!-- background -->
	<v-background-audio v-if="hasBackgroundAudio" :enabled="soundEnabled" :url="backgroundAudioUrl" :volume="soundVolume"/>
</template>

<script lang="ts">
	import ApiClient from "@/api/ApiClient"
	import VBackgroundAudio from "@/live/components/VBackgroundAudio.vue"
	import VControlBar from "@/live/components/VControlBar.vue"
	import VMatchScoreBar from "@/live/components/VMatchScoreBar.vue"
	import VTimeSlider from "@/live/components/VTimeSlider.vue"
	import LiveMatch from "@/live/LiveMatch"
	import LiveState from "@/live/model/LiveState"
	import VMatchVisualization from "@/live/views/visualization/VMatchVisualization.vue"
	import VMatchAfterSectionEnd from "@/live/views/VMatchAfterSectionEnd.vue"
	import VMatchBeforeStart from "@/live/views/VMatchBeforeStart.vue"
	import VMatchLoading from "@/live/views/VMatchLoading.vue"
	import VMatchNotifications from "@/live/views/VMatchNotifications.vue"
	import VMenu from "@/menu/VMenu.vue"
	import VStats from "@/live/components/VStats.vue"
	import CameraMode from "@/model/CameraMode"
	import Fixture from "@/model/Fixture"
	import { asString, error, localStorageOrNull } from "@/utility"
	import { computed, defineComponent, onBeforeMount, onBeforeUnmount, ref, watch } from "vue"
	import { useRoute } from "vue-router"


	export default defineComponent({
		components: {
			VBackgroundAudio,
			VControlBar,
			VMatchAfterSectionEnd,
			VMatchBeforeStart,
			VMatchLoading,
			VMatchNotifications,
			VMatchScoreBar,
			VTimeSlider,
			VMatchVisualization,
			VMenu,
			VStats
		},
		setup() {
			const localStorage = localStorageOrNull()

			const apiClient = new ApiClient()
			let liveMatch: LiveMatch | undefined

			const route = useRoute()

			const backgroundAudioUrlRef = ref<string>()
			const cameraModeRef = ref<CameraMode>("auto")
			const fixtureRef = ref<Fixture>()
			const fullscreenRef = ref(false)
			const menuRef = ref<typeof VMenu>()
			const soundEnabledRef = ref(false)
			const soundVolumeRef = ref(loadSoundVolume())
			const stadiumModelNameRef = ref<string>()
			const stateRef = ref<LiveState>()
			const statsRef = ref<Object>()
			const sliderMinValueRef = ref<number>(10.0)
			const sliderMaxValueRef = ref<number>(40.0)
			const sliderLoadedValueRef = ref<number>(30.0)
			const sliderValueRef = ref<number>(15.0)

			const hasBackgroundAudioRef = computed(() => Boolean(backgroundAudioUrlRef.value))
			const viewRef = computed<"loading" | "afterEnd" | "beforeStart" | "halfTime" | "visualization">(() => {
				if (!fixtureRef.value)
					return "loading"

				const state = stateRef.value
				
				if (!state)
					return "beforeStart"
				switch (state.status) {
					case "beforeMatch":
						return "beforeStart"
					case "inFirstHalf":
						return "visualization"
					case "beforeSecondHalf":
						return "halfTime"
					case "inSecondHalf":
						return "visualization"
					case "afterMatch":
						return "afterEnd"
				}
			})
			const showsCameraToggleRef = computed(() => viewRef.value === "visualization")
			const showsControlBarRef = computed(() => ["afterEnd", "beforeStart", "halfTime", "visualization"].includes(viewRef.value))
			const showsScoreBarRef = computed(() => viewRef.value === "visualization")
			const showsMenuRef = computed(() => ["afterEnd", "beforeStart", "halfTime", "visualization"].includes(viewRef.value))
			const showsNotificationsRef = computed(() => ["afterEnd", "halfTime", "visualization"].includes(viewRef.value))
			const showsTimeSliderRef = computed(() => (process.env.VUE_APP_ADMIN_MODE ? true : false) && (viewRef.value === "visualization") && statsRef.value)

			function onCameraModeChanged(mode: CameraMode) {
				cameraModeRef.value = mode
			}

			onBeforeMount(async () => {
				const matchKey = asString(route.query.match)
				const partnerId = asString(route.query.partner)
				if (!partnerId)
					throw error("Incorrect integration: query parameter 'partner' is missing.")

				const integrationInfo = (await apiClient.fetchIntegrationInfo(matchKey, partnerId)).data
				if (!integrationInfo.fixture) {
					// FIXME Display message that there is no match scheduled.
					return
				}

				fixtureRef.value = integrationInfo.fixture
				backgroundAudioUrlRef.value = integrationInfo.partner?.backgroundAudioUrl ?? undefined
				stadiumModelNameRef.value = integrationInfo.partner?.stadiumModelName ?? "default"

				liveMatch = LiveMatch.start(
					integrationInfo.fixture.id,
					fixture => fixtureRef.value = fixture,
					state => {
						fixtureRef.value = state.fixture
						stateRef.value = state
						statsRef.value = state.stats
					},
				)
			})

			onBeforeUnmount(() => {
				apiClient.abortRetries()
				liveMatch?.stop()
			})


			if (localStorage)
				watch(soundVolumeRef, volume => localStorage.setItem("soundVolume", volume.toString()))

			function loadSoundVolume() {
				const volume = Number.parseFloat(localStorage?.getItem("soundVolume") || "")

				return (isNaN(volume) || volume < 0 || volume > 1) ? 0.2 : volume
			}

			const onPlayerClicked = (id: string) => {
				menuRef.value?.presentStatisticsOfPlayer(id)
			}


			return {
				backgroundAudioUrl: backgroundAudioUrlRef,
				cameraMode: cameraModeRef,
				fixture: fixtureRef,
				fullscreen: fullscreenRef,
				hasBackgroundAudio: hasBackgroundAudioRef,
				menuRef,
				onCameraModeChanged,
				onPlayerClicked,
				showsCameraToggle: showsCameraToggleRef,
				showsControlBar: showsControlBarRef,
				showsMenu: showsMenuRef,
				showsNotifications: showsNotificationsRef,
				showsScoreBar: showsScoreBarRef,
				showsTimeSlider: showsTimeSliderRef,
				soundEnabled: soundEnabledRef,
				soundVolume: soundVolumeRef,
				sliderMinValue: sliderMinValueRef,
				sliderMaxValue: sliderMaxValueRef,
				sliderLoadedValue: sliderLoadedValueRef,
				sliderValue: sliderValueRef,
				stadiumModelName: stadiumModelNameRef,
				state: stateRef,
				stats: statsRef,
				view: viewRef,
			}
		},
	})
</script>
