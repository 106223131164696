import { error } from "@/utility/index"
import { InjectionKey, ref } from "vue"


export default class FullscreenController {

	private isInFullscreenRef = ref(false)


	constructor() {
		this.updateIsInFullscreen()

		// FIXME Remove listener on cleanup.
		;["fullscreenchange", "mozfullscreenchange", "msfullscreenchange", "webkitfullscreenchange"]
			.forEach(event => document.addEventListener(event, () => this.updateIsInFullscreen()))
	}


	enterFullscreen() {
		if (!this.isFullscreenSupported)
			error("Fullscreen is not supported.")
		if (this.isInFullscreen)
			error("Already in fullscreen.")

		FullscreenController._requestFullscreen!!()
			?.catch(e => console.error("Cannot enter fullscreen.", e))
	}


	exitFullscreen() {
		if (!this.isInFullscreen)
			error("Not in fullscreen.")

		FullscreenController._exitFullscreen!!()
			?.catch(e => console.error("Cannot exit fullscreen.", e))
	}


	private static get fake_exitFullscreen(): () => Promise<void> {
		return async () => window.close()
	}


	private static get fake_isInFullscreen(): boolean {
		return Boolean(window.name === "fullscreen" && window.opener)
	}


	private static get fake_isFullscreenSupported(): boolean {
		return this.isEmbedded || this.fake_isInFullscreen
	}


	private static get fake_requestFullscreen(): (() => Promise<void>) | undefined {
		if (this.isEmbedded)
			return async () => {
				window.open(window.location.href, "fullscreen")
			}
	}


	private static get isEmbedded(): boolean {
		return Boolean(window.parent !== window)
	}


	get isFullscreenSupported(): boolean {
		return FullscreenController.native_isFullscreenSupported || FullscreenController.fake_isFullscreenSupported
	}


	get isInFullscreen(): boolean {
		return this.isInFullscreenRef.value
	}


	private static get native_exitFullscreen(): (() => Promise<void>) | undefined {
		const anyDocument = document as any

		return document.exitFullscreen?.bind(document)
			?? anyDocument.mozExitFullscreen?.bind(document)
			?? anyDocument.msExitFullscreen?.bind(document)
			?? anyDocument.webkitExitFullscreen?.bind(document)
	}


	private static get native_isFullscreenSupported(): boolean {
		const anyDocument = document as any

		return Boolean(
			(
				document.fullscreenEnabled
				|| anyDocument.mozFullscreenEnabled
				|| anyDocument.msFullscreenEnabled
				|| anyDocument.webkitFullscreenEnabled
			) && FullscreenController._requestFullscreen,
		)
	}


	private static get native_isInFullscreen(): boolean {
		const anyDocument = document as any

		return Boolean(
			document.fullscreenElement
			|| anyDocument.mozFullScreenElement
			|| anyDocument.msFullscreenElement
			|| anyDocument.webkitFullscreenElement,
		)
	}


	private static get native_requestFullscreen(): ((options?: FullscreenOptions) => Promise<void>) | undefined {
		const anyDocumentElement = document.documentElement as any

		return document.documentElement.requestFullscreen?.bind(document.documentElement)
			?? anyDocumentElement.mozRequestFullscreen?.bind(document.documentElement)
			?? anyDocumentElement.msRequestFullscreen?.bind(document.documentElement)
			?? anyDocumentElement.webkitRequestFullscreen?.bind(document.documentElement)
	}


	private updateIsInFullscreen() {
		this.isInFullscreenRef.value = FullscreenController.native_isInFullscreen || FullscreenController.fake_isInFullscreen
	}


	private static get _exitFullscreen(): (() => Promise<void> | undefined) | undefined {
		return this.native_exitFullscreen || this.fake_exitFullscreen
	}


	private static get _requestFullscreen(): ((options?: FullscreenOptions) => Promise<void> | undefined) | undefined {
		return this.native_requestFullscreen || this.fake_requestFullscreen
	}
}


export const FullscreenControllerInjectionKey: InjectionKey<FullscreenController> = Symbol("FullscreenController")
