
	import LiveState from "@/live/model/LiveState"
	import Card from "@/model/Card"
	import Club from "@/model/Club"
	import MatchEvent from "@/model/MatchEvent"
	import { GoalMatchEventType, SubstitutionMatchEventType } from "@/model/MatchEventType"
	import Player from "@/model/Player"
	import { computed, PropType } from "@vue/runtime-core"
	import { defineComponent, useCssModule } from "vue"


	export default defineComponent({
		props: {
			state: { type: Object as PropType<LiveState>, required: true },
			event: { type: Object as PropType<MatchEvent>, required: true },
			locale: String,
		},
		emits: {
			"playerClicked": (_: string) => true,
		},
		setup(props, { emit }) {
			const minuteOfPlayRef = computed(() => props.state.formattedMinuteOfPlay(props.event.timestamp))
			const score = computed(() => {
				if (props.event.type instanceof GoalMatchEventType)
					return props.event.type.score
				return null
			})
			const playerId = computed<string | null>(() => {
				if (props.event.type instanceof SubstitutionMatchEventType) {
					return props.event.type.outPlayerId
				}
				if ("playerId" in props.event.type) {
					const type: any = props.event.type
					return type.playerId
				}
				return null
			})
			const player = computed<Player | undefined>(() => playerId.value ? props.state.players.get(playerId.value) : undefined)
			const secondPlayerId = computed<string | null>(() => {
				if (props.event.type instanceof SubstitutionMatchEventType) {
					return props.event.type.inPlayerId
				}
				return null
			})
			const secondPlayer = computed(() => secondPlayerId.value && props.state.players.get(secondPlayerId.value))
			const clubId = computed<string | null>(() => {
				if ("clubId" in props.event.type) {
					const type: any = props.event.type
					return type.clubId
				}
				return null
			})
			const club = computed<Club | null>(() => {
				if (props.state.fixture.homeClub.id === clubId.value)
					return props.state.fixture.homeClub
				if (props.state.fixture.awayClub.id === clubId.value)
					return props.state.fixture.awayClub
				return null
			})
			const homeClub = computed<Club>(() => props.state.fixture.homeClub)

			function onPlayerClicked(id: string) {
				emit("playerClicked", id)
			}

			function shouldAlignLeft() {
				const shouldAlignLeft = (club.value && club.value.id != homeClub.value.id)
				if (props.event.type instanceof GoalMatchEventType && props.event.type.isOwn) {
					return !shouldAlignLeft
				}
				return shouldAlignLeft
			}

			const $style = useCssModule()

			function historyEventsStyle() {
				return [
					$style.historyEvent,
					shouldAlignLeft() ? $style.reverse : null,
				]
			}

			function cardStyle(card: Card) {
				let cardStyle
				switch (card) {
					case "red":
						cardStyle = $style.cardRed
						break
					case "yellow":
						cardStyle = $style.cardYellow
						break
					case "yellowRed":
						cardStyle = $style.cardYellowRed
						break
					default:
						cardStyle = null
						break
				}
				return [$style.card, cardStyle]
			}

			const eventIcon = computed(() => {
				if (props.event.type instanceof GoalMatchEventType && props.event.type.isOwn) {
					return "/images/events/GoalOwn.svg"
				}
				return `/images/events/${props.event.type.__type}.svg`
			})

			return {
				cardStyle,
				club,
				eventIcon,
				historyEventsStyle,
				minuteOfPlay: minuteOfPlayRef,
				onPlayerClicked,
				player,
				score,
				secondPlayer,
			}
		},
	})
