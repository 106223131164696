import MatchClubStatistics from "@/model/MatchClubStatistics"
import MatchStatistics from "@/model/MatchStatistics"
import Player from "@/model/Player"
import VMMatchStatisticsValue from "@/statistics/model/VMMatchStatisticsValue"


export interface VMMatchStatisticsCardComparisonItem {

	readonly leftRedCount: number
	readonly leftYellowCount: number
	readonly rightRedCount: number
	readonly rightYellowCount: number
	readonly type: "cards"
}

export interface VMMatchStatisticsPlayerComparisonItem {

	readonly label: string
	readonly leftPlayerId: string
	readonly leftPlayerImageUrl: string | null
	readonly leftPlayerName: string
	readonly leftPlayerSecondaryValue: VMMatchStatisticsValue | null
	readonly leftPlayerValue: VMMatchStatisticsValue
	readonly rightPlayerId: string
	readonly rightPlayerImageUrl: string | null
	readonly rightPlayerName: string
	readonly rightPlayerSecondaryValue: VMMatchStatisticsValue | null
	readonly rightPlayerValue: VMMatchStatisticsValue
	readonly type: "player"
}

export interface VMMatchStatisticsSimpleComparisonItem {

	readonly label: string
	readonly leftSecondaryValue: VMMatchStatisticsValue | null
	readonly leftValue: VMMatchStatisticsValue
	readonly rightSecondaryValue: VMMatchStatisticsValue | null
	readonly rightValue: VMMatchStatisticsValue
	readonly type: "simple"
}

type VMMatchStatisticsComparisonItem = VMMatchStatisticsCardComparisonItem | VMMatchStatisticsPlayerComparisonItem | VMMatchStatisticsSimpleComparisonItem

export default VMMatchStatisticsComparisonItem


export function VMMatchStatisticsCardComparisonItem(statistics: MatchStatistics): VMMatchStatisticsCardComparisonItem {
	const leftStatistics = statistics.homeClubStatistics
	const rightStatistics = statistics.awayClubStatistics

	return {
		leftRedCount: leftStatistics.cardCountRed + leftStatistics.cardCountYellowRed,
		leftYellowCount: leftStatistics.cardCountYellow,
		rightRedCount: rightStatistics.cardCountRed + rightStatistics.cardCountYellowRed,
		rightYellowCount: rightStatistics.cardCountYellow,
		type: "cards",
	}
}


export function VMMatchStatisticsPlayerComparisonItem(
	statistics: MatchStatistics,
	players: ReadonlyMap<string, Player>,
	label: string,
	playerId: (statistics: MatchClubStatistics) => string | null,
	value: (statistics: MatchClubStatistics) => VMMatchStatisticsValue,
	secondaryValue?: (statistics: MatchClubStatistics) => VMMatchStatisticsValue,
): VMMatchStatisticsPlayerComparisonItem | null {
	const leftStatistics = statistics.homeClubStatistics
	const rightStatistics = statistics.awayClubStatistics

	const leftPlayerId = playerId(leftStatistics)
	const rightPlayerId = playerId(rightStatistics)
	if (!leftPlayerId || !rightPlayerId)
		return null

	const leftPlayer = players.get(leftPlayerId)
	const rightPlayer = players.get(rightPlayerId)
	if (!leftPlayer || !rightPlayer)
		return null

	return {
		label,
		leftPlayerId,
		leftPlayerImageUrl: leftPlayer.faceImageUrl,
		leftPlayerName: leftPlayer.shortName,
		leftPlayerValue: value(leftStatistics),
		leftPlayerSecondaryValue: secondaryValue ? secondaryValue(leftStatistics) : null,
		rightPlayerId,
		rightPlayerImageUrl: rightPlayer.faceImageUrl,
		rightPlayerName: rightPlayer.shortName,
		rightPlayerValue: value(rightStatistics),
		rightPlayerSecondaryValue: secondaryValue ? secondaryValue(rightStatistics) : null,
		type: "player",
	}
}


export function VMMatchStatisticsSimpleComparisonItem(
	statistics: MatchStatistics,
	label: string,
	value: (statistics: MatchClubStatistics) => VMMatchStatisticsValue,
	secondaryValue?: (statistics: MatchClubStatistics) => VMMatchStatisticsValue,
): VMMatchStatisticsSimpleComparisonItem {
	const leftStatistics = statistics.homeClubStatistics
	const rightStatistics = statistics.awayClubStatistics

	return {
		label,
		leftValue: value(leftStatistics),
		leftSecondaryValue: secondaryValue ? secondaryValue(leftStatistics) : null,
		rightValue: value(rightStatistics),
		rightSecondaryValue: secondaryValue ? secondaryValue(rightStatistics) : null,
		type: "simple",
	}
}
