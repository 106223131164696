import LiveMessage from "@/live/model/LiveMessage"
import parseLiveMessage from "@/live/model/LiveMessage.parse"
import ByeLiveMessage from "@/live/model/messages/ByeLiveMessage"
import PositionsFrameSetLiveMessage from "@/live/model/messages/PositionsFrameSetLiveMessage"
import WelcomeLiveMessage from "@/live/model/messages/WelcomeLiveMessage"
import { error } from "@/utility"


export default class LiveMatchConnection {

	private connectionAttempt = 0
	private pingTimerId = 0
	private reconnectTimerId = 0
	private shouldReconnect = true
	private socket: WebSocket | null = null


	constructor(
		private readonly id: string,
		private readonly onMessage: (message: LiveMessage) => void,
	) {
		this.connect()
	}


	private cancelPingTimer() {
		window.clearTimeout(this.pingTimerId)
		this.pingTimerId = 0
	}


	private cancelReconnectTimer() {
		window.clearTimeout(this.reconnectTimerId)
		this.reconnectTimerId = 0
	}


	private connect() {
		if (this.socket) error("Already connected.")

		this.connectionAttempt += 1

		this.cancelReconnectTimer()
		this.cancelPingTimer()

		this.socket = new WebSocket(`${process.env.VUE_APP_LIVE_API_URL}${this.id}`)
		this.socket.binaryType = "arraybuffer"
		this.socket.addEventListener("close", it => this.onSocketClose(it))
		this.socket.addEventListener("error", it => this.onSocketError(it))
		this.socket.addEventListener("message", it => this.onSocketMessage(it))
		this.socket.addEventListener("open", it => this.onSocketOpen(it))
	}


	disconnect() {
		this.cancelPingTimer()
		this.cancelReconnectTimer()

		this.shouldReconnect = false

		this.socket?.close()
		this.socket = null
	}


	private onSocketClose(event: CloseEvent) {
		if (this.shouldReconnect)
			console.error(`LiveMatch socket closed (${event.code}: ${event.reason}).`)

		this.socket = null

		this.cancelPingTimer()
		this.startReconnectTimer()
	}


	// noinspection JSMethodCanBeStatic
	private onSocketError(event: Event | ErrorEvent) {
		console.error("LiveMatch socket error.", "error" in event ? event.error : event)
	}


	private onSocketMessage(event: MessageEvent<string | ArrayBuffer>) {
		this.startPingTimer()

		const data = event.data

		let message: LiveMessage
		if (typeof data === "string") {
			message = this.parseMessage(data)

			if (message instanceof ByeLiveMessage)
				this.disconnect()
			else if (message instanceof WelcomeLiveMessage)
				this.connectionAttempt = 0
		}
		else {
			const view = new DataView(data)
			const type = view.getInt8(0)
			switch (type) {
				case 1: {
					message = PositionsFrameSetLiveMessage.fromBinary(new DataView(data, 1))
					break
				}

				default: {
					console.error(`Unknown binary live message type: ${type}`)
					return
				}
			}
		}

		this.onMessage(message)
	}


	// noinspection JSUnusedLocalSymbols
	private onSocketOpen(event: Event) {
		this.startPingTimer()
	}


	// noinspection JSMethodCanBeStatic
	private parseMessage(data: string): LiveMessage {
		return parseLiveMessage(JSON.parse(data))
	}


	private ping() {
		this.socket?.send("")

		this.startPingTimer()
	}


	private startPingTimer() {
		this.cancelPingTimer()

		const isInMatch = false// FIXME ["inFirstHalf", "inSecondHalf"].includes(this.state?.status ?? "")
		const pingInterval = isInMatch ? 5_000 : 30_000
		this.pingTimerId = window.setTimeout(() => this.ping(), pingInterval)
	}


	private startReconnectTimer() {
		this.cancelReconnectTimer()

		if (!this.shouldReconnect)
			return

		const delay = Math.min(this.connectionAttempt - 1, 10) * 1_000
		this.reconnectTimerId = window.setTimeout(() => this.connect(), delay)
	}
}
