import LivePositionsBall from "@/live/model/LivePositionsBall"
import LivePositionsPlayer from "@/live/model/LivePositionsPlayer"


export default class LivePositionsFrame {

	constructor(
		readonly ball: LivePositionsBall,
		readonly players: ReadonlyArray<LivePositionsPlayer>,
		readonly timestamp: number,
	) {
		Object.freeze(this)
	}
}
