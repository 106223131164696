
	import { useLocalization } from "@/i18n/VueLocalization"
	import LiveState from "@/live/model/LiveState"
	import VMatchTimelineEvent from "@/menu/components/VMatchTimelineEvent.vue"
	import VMatchTimelineEventSection from "@/menu/components/VMatchTimelineEventSection.vue"
	import Club from "@/model/Club"
	import MatchEventType, {
		CautionMatchEventType,
		FinalWhistleMatchEventType,
		GoalMatchEventType,
		KickOffMatchEventType,
		SubstitutionMatchEventType,
	} from "@/model/MatchEventType"
	import { computed, defineComponent, PropType } from "@vue/runtime-core"


	export default defineComponent({
		components: { VMatchTimelineEvent, VMatchTimelineEventSection },
		props: {
			state: { type: Object as PropType<LiveState>, required: true },
		},
		emits: {
			"playerClicked": (_: string) => true,
		},
		setup(props, { emit }) {
			const $l = useLocalization()
			const homeClub = computed<Club>(() => props.state.fixture.homeClub)
			const awayClub = computed<Club>(() => props.state.fixture.awayClub)
			const events = computed(() =>
				Array.from(props.state.events.values())
					.filter(e =>
						(e.type instanceof KickOffMatchEventType &&
							e.type.section === "firstHalf") ||
						e.type instanceof FinalWhistleMatchEventType ||
						e.type instanceof CautionMatchEventType ||
						e.type instanceof GoalMatchEventType ||
						e.type instanceof SubstitutionMatchEventType,
					)
					.sort((a, b) => b.timestamp - a.timestamp),
			)

			function onPlayerClicked(id: string) {
				emit("playerClicked", id)
			}

			const getTranslation = (type: MatchEventType): string | null => {
				if (type instanceof CautionMatchEventType) {
					if (type.card === "red") {
						return $l.event_redCard
					}
					if (type.card === "yellow") {
						return $l.event_yellowCard
					}
					if (type.card === "yellowRed") {
						return $l.event_yellowRedCard
					}
				}
				if (type instanceof GoalMatchEventType)
					return $l.event_goal
				if (type instanceof SubstitutionMatchEventType)
					return $l.event_substitution
				return null
			}

			function isEventTransition(event: MatchEventType) {
				return event instanceof FinalWhistleMatchEventType ||
					event instanceof KickOffMatchEventType
			}

			return {
				awayClub,
				events,
				homeClub,
				isEventTransition,
				onPlayerClicked,
				getTranslation,
			}
		},
	})
