
	import { ref, watchEffect } from "vue"


	export default {
		props: {
			enabled: { type: Boolean, required: true },
			url: { type: String, required: true },
			volume: { type: Number, required: true },
		},
		setup(props) {
			const audioRef = ref<HTMLAudioElement>()

			watchEffect(() => {
				const audio = audioRef.value
				if (!audio)
					return

				if (props.enabled) {
					audio.muted = false
					audio.play()
				} else
					audio.muted = true
			})

			watchEffect(() => {
				const audio = audioRef.value
				if (audio)
					audio.volume = props.volume
			})

			return { audioRef }
		},
	}
