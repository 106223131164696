
	import ApiClient from "@/api/ApiClient"
	import clockIcon from "@/assets/clock.svg"
	import Fixture from "@/model/Fixture"
	import { computed, defineComponent, onBeforeUnmount, PropType, ref } from "@vue/runtime-core"
	import { useRoute } from "vue-router"


	interface MatchesByLeagues {
		[key: string]: {
			name: string;
			matches: Fixture[];
		}
	}

	interface MatchesByLeaguesGroupedByTime {
		[key: string]: {
			name: string;
			matches: {
				[ket: string]: Fixture[]
			};
		}
	}

	export default defineComponent({
		props: {
			fixture: { type: Object as PropType<Fixture>, required: true },
		},
		async setup(props) {
			const route = useRoute()
			const partner = ref(route.query.partner)
			const apiClient = new ApiClient()
			onBeforeUnmount(() => apiClient.abortRetries())
			const fixtures = ref((await apiClient.listFixtures(new Date(props.fixture.startTimestamp))).data)

			const matchesByLeagues = computed<MatchesByLeaguesGroupedByTime>(() => {
				const leaguesMatches: MatchesByLeagues = {}
				fixtures.value.forEach(m => {
					leaguesMatches[m.competition.id] = {
						name: m.competition.name,
						matches: leaguesMatches[m.competition.id]?.matches
							? [...leaguesMatches[m.competition.id].matches, m]
							: [m],
					}
				})
				return groupByTime(leaguesMatches)
			})

			const groupByTime = (leagueMatches: MatchesByLeagues): MatchesByLeaguesGroupedByTime => {
				const leaguesIds = Object.keys(leagueMatches)
				let leaguesGrouped: MatchesByLeaguesGroupedByTime = {}
				for (let i = 0; i < leaguesIds.length; i++) {
					let matches = {}

					leagueMatches[leaguesIds[i]].matches.forEach(match => {
						matches[match.startTimestamp.getTime()] = matches[match.startTimestamp.getTime()] ?
							[...matches[match.startTimestamp.getTime()], match] :
							[match]
					})

					leaguesGrouped[leaguesIds[i]] = {
						name: leagueMatches[leaguesIds[i]].name,
						matches,
					}
				}

				return leaguesGrouped
			}

			const getTime = (time) => {
				const options: any = {
					hour: "2-digit",
					minute: "2-digit",
				}
				return new Date(time).toLocaleTimeString(navigator.language, options)
			}

			return {
				clockIcon,
				getTime,
				matchesByLeagues,
				partner,
			}
		},
	})
