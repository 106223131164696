import LiveFrame from "@/live/model/LiveFrame"
import LivePositionsFrame from "@/live/model/LivePositionsFrame"
import LiveStateClub from "@/live/model/LiveStateClub"
import Fixture from "@/model/Fixture"
import MatchEvent from "@/model/MatchEvent"
import { DeleteMatchEventType, GoalMatchEventType } from "@/model/MatchEventType"
import MatchPositions from "@/model/MatchPositions"
import MatchPositionsBall from "@/model/MatchPositionsBall"
import MatchPositionsPlayer from "@/model/MatchPositionsPlayer"
import MatchStatistics from "@/model/MatchStatistics"
import MatchStatus from "@/model/MatchStatus"
import MatchTiming from "@/model/MatchTiming"
import Player from "@/model/Player"
import Score from "@/model/Score"
import { associateByTo, error } from "@/utility"
import { ref, Ref } from "vue"
import CyclcicFramesBuffer from "@/utility/CyclicFramesBuffer"


export default class LiveState {

	private readonly _awayClub: Ref<LiveStateClub>
	private readonly _events: Ref<Map<string, MatchEvent>>
	private readonly _fixture: Ref<Fixture>
	private readonly _homeClub: Ref<LiveStateClub>
	private readonly _playerIdsOnField: Ref<ReadonlyArray<string>>
	private readonly _players: Ref<ReadonlyMap<string, Player>>
	private readonly _positions: Ref<MatchPositions | null>
	private readonly _score: Ref<Score>
	private readonly _statistics: Ref<MatchStatistics | null>
	private readonly _status: Ref<MatchStatus>
	private readonly _timestamp: Ref<number>
	private readonly _timing: Ref<MatchTiming>

	readonly _framesBuffer: CyclcicFramesBuffer
	readonly stats: Object = {}
	
	readonly maxFramesBufferLength = 25 * 60 // one minute


	constructor(
		awayClub: LiveStateClub,
		events: ReadonlyMap<string, MatchEvent>,
		fixture: Fixture,
		homeClub: LiveStateClub,
		playerIdsOnField: ReadonlyArray<string>,
		players: ReadonlyMap<string, Player>,
		positions: MatchPositions | null,
		score: Score,
		statistics: MatchStatistics | null,
		status: MatchStatus,
		timestamp: number,
		timing: MatchTiming,
		
	) {
		this._awayClub = ref(awayClub)
		this._events = ref(new Map(events))
		this._fixture = ref(fixture)
		this._homeClub = ref(homeClub)
		this._playerIdsOnField = ref(playerIdsOnField)
		this._players = ref(new Map(players))
		this._positions = ref(null)
		this._score = ref(score)
		this._statistics = ref(statistics)
		this._status = ref(status)
		this._timestamp = ref(timestamp)
		this._timing = ref(timing)
		this._framesBuffer = new CyclcicFramesBuffer(this.maxFramesBufferLength)

		Object.freeze(this)
	}


	applyFrame(frame: LiveFrame) {
		const update = frame.update
		if (update.awayClub)
			this._awayClub.value = update.awayClub

		const events = this._events.value
		for (const event of update.events.values())
			if (event.type instanceof DeleteMatchEventType)
				events.delete(event.id)
			else
				events.set(event.id, event)

		if (update.fixture)
			this._fixture.value = update.fixture

		if (update.homeClub)
			this._homeClub.value = update.homeClub

		if (update.playerIdsOnField){
			this._playerIdsOnField.value = update.playerIdsOnField
		}

		if (update.players)
			this._players.value = update.players

		if (update.score)
			this._score.value = update.score

		if (update.statistics)
			this._statistics.value = update.statistics

		if (update.status)
			this._status.value = update.status

		// FIXME
		// if (update.timestamp)
		// 	this._timestamp.value = update.timestamp

		if (update.timing)
			this._timing.value = update.timing

		//if (update.framesBuffer)
		//	this._framesBuffer.value = update.framesBuffer

		}


	applyPositionsFrame(frame: LivePositionsFrame) {
		const ball = frame.ball
		const playerIdsOnField = this._playerIdsOnField.value
		if (!playerIdsOnField.length) // FIXME Is this an error?
			return

		const positions = new MatchPositions(
			new MatchPositionsBall(ball.x, ball.y, ball.z),
			associateByTo(frame.players, (player, index) => [
				playerIdsOnField[index], // FIXME Warn & ignore if missing.
				new MatchPositionsPlayer(player.x, player.y),
			]),
			frame.timestamp
		)
		this._positions.value = positions

		this._framesBuffer.add(positions)

		this._timestamp.value = frame.timestamp
	}


	get awayClub() {
		return this._awayClub.value
	}


	club(id: string): LiveStateClub {
		switch (id) {
			case this.awayClub.id:
				return this.awayClub
			case this.homeClub.id:
				return this.homeClub
			default:
				error(`Invalid club ID: ${id}`)
		}
	}


	get events(): ReadonlyMap<string, MatchEvent> {
		return this._events.value
	}


	get fixture() {
		return this._fixture.value
	}


	formattedMinuteOfPlay(timestamp: number): string | null {
		const timing = this._timing.value

		let firstMinute: number
		let kickoffTimestamp: number

		if (timestamp >= timing.secondHalfKickoffTimestamp.getTime()) {
			firstMinute = 46
			kickoffTimestamp = timing.secondHalfKickoffTimestamp.getTime()
		}
		else {
			firstMinute = 1
			kickoffTimestamp = timing.firstHalfKickoffTimestamp.getTime()
		}

		const relativeMinute = Math.floor((timestamp - kickoffTimestamp) / 60_000)
		if (relativeMinute < 0)
			return null

		let additionalMinute = 0
		let minute = relativeMinute

		if (minute >= 44.5) {
			additionalMinute = relativeMinute - 44
			minute = 44
		}

		minute += firstMinute

		return additionalMinute > 0 ? `${minute.toFixed(0)}+${additionalMinute.toFixed(0)}` : minute.toFixed(0)
	}


	formattedTime(): string | null {
		let kickoffTimestamp: Date
		let startSeconds = 0

		const status = this._status.value
		const timestamp = this._timestamp.value
		const timing = this._timing.value

		switch (status) {
			case "beforeMatch":
				return null
			case "inFirstHalf":
				kickoffTimestamp = timing.firstHalfKickoffTimestamp
				break
			case "beforeSecondHalf":
				return null
			case "inSecondHalf":
				kickoffTimestamp = timing.secondHalfKickoffTimestamp
				startSeconds = 45 * 60
				break
			case "afterMatch":
				return null
		}

		let seconds = Math.floor((timestamp - kickoffTimestamp.getTime()) / 1_000) + startSeconds
		let minutes = Math.floor(seconds / 60)
		seconds %= 60

		return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
	}


	get goalEventsByScoringSide() {
		const awayClubId = this.fixture.awayClub.id
		const homeClubId = this.fixture.homeClub.id

		const away: MatchEvent[] = []
		const home: MatchEvent[] = []

		for (const event of this.events.values()) {
			const type = event.type
			if (type instanceof GoalMatchEventType)
				switch (type.clubId) {
					case awayClubId:
						(type.isOwn ? home : away).push(event)
						break

					case homeClubId:
						(type.isOwn ? away : home).push(event)
						break
				}
		}

		return {
			away: away as unknown as ReadonlyArray<MatchEvent<GoalMatchEventType>>,
			home: home as unknown as ReadonlyArray<MatchEvent<GoalMatchEventType>>,
		}
	}


	get homeClub() {
		return this._homeClub.value
	}


	get players() {
		return this._players.value
	}


	get positions(): MatchPositions | null {
		return this._positions.value
	}


	get score() {
		return this._score.value
	}


	get statistics() {
		return this._statistics.value
	}


	get status() {
		return this._status.value
	}


	get timing() {
		return this._timing.value
	}

	get framesBuffer() {
		return this._framesBuffer
	}
}
