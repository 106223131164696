
	import LiveState from "@/live/model/LiveState"
	import Visualization from "@/live/views/visualization/Visualization"
	import CameraMode from "@/model/CameraMode"
	import { defineComponent, onMounted, onUnmounted, PropType, ref, useCssModule, watchEffect } from "vue"


	export default defineComponent({
		props: {
			cameraMode: { type: String as PropType<CameraMode>, default: "auto" },
			stadiumModelName: { type: String, required: true },
			state: { type: Object as PropType<LiveState>, required: true },
		},
		setup(props) {
			const $style = useCssModule()
			const containerRef = ref<HTMLDivElement>()
			const visualizationRef = ref<Visualization>()

			watchEffect(() => visualizationRef.value?.useState(props.state))
			watchEffect(() => {
				if (visualizationRef.value) visualizationRef.value.cameraMode = props.cameraMode
			})

			onMounted(() => {
				// TODO Do we need to clean up this element somehow on unmount?
				const ballPossessionLabelElement = document.createElement("div")
				ballPossessionLabelElement.className = $style.ballPossessionLabel

				const visualization = new Visualization(
					ballPossessionLabelElement,
					props.cameraMode,
					containerRef.value!!,
					props.stadiumModelName,
				)
				visualizationRef.value = visualization

				visualization.useState(props.state)
			})

			onUnmounted(() => {
				visualizationRef.value?.dispose()
			})

			return { containerRef }
		},
	})
