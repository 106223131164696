<template>
	<div :class="$style.root">
    <h2 :class="$style.title">{{ title }}</h2>
    <div :class="$style.score">
      <p>{{ score }}</p>
    </div>
	</div>
</template>

<script lang="ts">
	import { useLocalization } from "@/i18n/VueLocalization"
  import { FinalWhistleMatchEventType, KickOffMatchEventType } from "@/model/MatchEventType"
	import { computed, PropType } from "@vue/runtime-core"
	import { defineComponent } from "vue"


	export default defineComponent({
		props: {
			event: { type: Object as PropType<FinalWhistleMatchEventType|KickOffMatchEventType>, required: true },
			locale: String,
		},
		emits: {
			"playerClicked": (_: string) => true,
		},
		setup(props) {
      const $l = useLocalization()
      const title = computed<string | null>(() => {
        if (props.event instanceof FinalWhistleMatchEventType) {
          if (props.event.section === 'firstHalf') {
            return $l.timeline_halfTime
          }
          return $l.timeline_finalWhistle
        }
        if (props.event instanceof KickOffMatchEventType) {
          return $l.timeline_kickOff
        }
        return null
      })

      const score = computed(() => {
        if (props.event instanceof FinalWhistleMatchEventType) {
          return props.event.score.toString()
        }
        return '0:0'
      })

			return {
				score,
        title
			}
		},
	})
</script>

<style lang="scss" module>
.root {
  align-items: center;
  background-color: #404051;
  display: flex;
  margin-top: -1px;
  padding: 8px 16px;
}

.title {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  width: calc(50% - 4px);
}

.score {
  width: 29px;
  margin: 0 8px;
  p {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }
}
</style>
