import MatchSquadMember from "@/model/MatchSquadMember"


export default class MatchSquad {

	constructor(
		readonly formationId: string,
		readonly members: ReadonlyArray<MatchSquadMember>,
	) {
		Object.freeze(this)
	}


	member(id: string): MatchSquadMember | null {
		return this.members.find(it => it.id === id) ?? null
	}


	static fromJson(json: any) {
		return new this(
			json.formationId,
			json.members.map(it => MatchSquadMember.fromJson(it)),
		)
	}
}
