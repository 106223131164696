<template>
	<div :class="$style.root">
		<template v-for="(item, index) in statistics.items" :key="index.toString()">
			<div v-if="index > 0" :class="$style.separator"/>
			<v-match-statistics-card-comparison-item v-if="item.type === 'cards'" :item="item"/>
			<v-match-statistics-player-comparison-item v-if="item.type === 'player'" :item="item" @player-clicked="onPlayerClicked"/>
			<v-match-statistics-simple-comparison-item v-if="item.type === 'simple'" :item="item"/>
		</template>
	</div>
</template>

<script lang="ts">
	import VMMatchStatistics from "@/statistics/model/VMMatchStatistics"
	import VMatchStatisticsCardComparisonItem from "@/statistics/views/VMatchStatisticsCardComparisonItem.vue"
	import VMatchStatisticsPlayerComparisonItem from "@/statistics/views/VMatchStatisticsPlayerComparisonItem.vue"
	import VMatchStatisticsSimpleComparisonItem from "@/statistics/views/VMatchStatisticsSimpleComparisonItem.vue"
	import { defineComponent, PropType } from "vue"


	export default defineComponent({
		components: {
			VMatchStatisticsCardComparisonItem,
			VMatchStatisticsPlayerComparisonItem,
			VMatchStatisticsSimpleComparisonItem,
		},
		props: {
			statistics: { type: Object as PropType<VMMatchStatistics>, required: true },
		},
		emits: {
			"playerClicked": (_: string) => true,
		},
		setup(props, { emit }) {
			function onPlayerClicked(id: string) {
				emit("playerClicked", id)
			}

			return {
				onPlayerClicked,
			}
		},
	})
</script>

<style lang="scss" module>
	.root {
		user-select: none;
	}

	.separator {
		background: rgba(255, 255, 255, 0.2);
		height: calc(1 * var(--scale));
		margin: calc(-0.5 * var(--scale)) calc(16 * var(--scale));
	}
</style>
