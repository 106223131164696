
	import { useLocalization } from "@/i18n/VueLocalization"
	import LiveState from "@/live/model/LiveState"
	import Card from "@/model/Card"
	import MatchPlayerStatistics from "@/model/MatchPlayerStatistics"
	import { computed, PropType } from "@vue/runtime-core"
	import { useCssModule } from "@vue/runtime-dom"


	export default {
		props: {
			state: { type: Object as PropType<LiveState>, required: true },
			playerId: { type: String, required: true },
		},
		setup(props) {
			const $l = useLocalization()
			const $style = useCssModule()
			const statValueStyle = (card: Card | undefined) => {
				let cardStyle
				if (card === "red")
					cardStyle = $style.redCard
				if (card === "yellow")
					cardStyle = $style.yellowCard
				if (card === "yellowRed")
					cardStyle = $style.yellowRedCard
				return [
					$style.statValue,
					cardStyle,
				]
			}

			const player = computed(() => props.state.players.get(props.playerId))
			const club = computed(() => props.state.fixture.club(props.state.players.get(props.playerId)!!.clubId))
			const playingPositionRef = computed(() => player.value?.playingPositionLabel($l))
			const age = computed(() => {
				if (!player.value?.dateOfBirth) return null
				const now = new Date()
				const birthDate = new Date(player.value.dateOfBirth)
				const diff = now.getFullYear() - birthDate.getFullYear()
				const hadAnniversaryThisYear = now.getMonth() > birthDate.getMonth() ||
					now.getMonth() === birthDate.getMonth() && now.getDay() >= birthDate.getDay()
				if (hadAnniversaryThisYear) return diff
				return diff - 1
			})
			const clubString = computed<string>(() => {
				return club.value?.id === props.state.fixture.homeClub.id
					? "homeClubStatistics"
					: "awayClubStatistics"
			})
			const playerStatistic = computed<MatchPlayerStatistics>(() => {
				if (!props.state.statistics) return null
				return props.state.statistics[clubString.value].playerStatistics.find(p => p.id === props.playerId)
			})

			const playerStats = computed(() => {
				if (!playerStatistic.value) return []
				return [
					playerStatistic.value.formatStat({
						absoluteValue: playerStatistic.value.playDuration,
						divisor: 60,
						unitOfMeasurement: "min",
						title: $l.statistics_playDuration,
					}),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.goalCount, title: $l.statistics_goals }),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.goalAssistCount, title: $l.statistics_goalAssists }),
					// playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.ballContactCount, title: $l.statistics_ballContacts }),
					playerStatistic.value.formatStat({
						percentageValue: playerStatistic.value.passCompletionRate,
						absoluteValue: playerStatistic.value.passCompletionCount,
						title: $l.statistics_passCompletion,
					}),
					playerStatistic.value.formatStat({
						percentageValue: playerStatistic.value.duelSuccessRate,
						absoluteValue: playerStatistic.value.duelSuccessCount,
						title: $l.statistics_duelSuccess,
					}),
					playerStatistic.value.formatStat({
						absoluteValue: playerStatistic.value.distanceCovered,
						divisor: 1000,
						unitOfMeasurement: "km",
						title: $l.statistics_distanceCovered,
					}),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.shotCount, title: $l.statistics_shotCount }),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.goalShotCount, title: $l.statistics_goalShotCount }),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.goalShotAssistCount, title: $l.statistics_goalShotAssistCount }),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.foulCount, title: $l.statistics_fouls }),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.cardCountRed, card: "red", title: $l.statistics_redCard }),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.cardCountYellow, card: "yellow", title: $l.statistics_yellowCard }),
					playerStatistic.value.formatStat({
						absoluteValue: playerStatistic.value.cardCountYellowRed,
						card: "yellowRed",
						title: $l.statistics_yellowRedCard,
					}),
				].filter((i) => i)
			})

			return {
				age,
				club,
				player,
				playerStats,
				playingPosition: playingPositionRef,
				statValueStyle,
			}
		},
	}
