
	import { VMMatchStatisticsCardComparisonItem } from "@/statistics/model/VMMatchStatisticsComparisonItem"
	import { defineComponent, PropType } from "vue"


	export default defineComponent({
		props: {
			item: { type: Object as PropType<VMMatchStatisticsCardComparisonItem>, required: true },
		},
	})
