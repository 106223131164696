<template>
	<div :class="$style.root" :style="style">
		<div :class="$style.container">
			<div :class="$style.homeClub">
				<div :class="$style.clubLogoAndScore">
					<div :class="$style.clubLogo"/>
					<div :class="$style.clubScore" v-text="score.home"/>
				</div>
				<div :class="$style.clubCode" v-text="homeClub.code"/>
				<div :class="$style.goals">
					<div v-for="goal in goals.home" :class="$style.goal" :key="goal.id">
						<img alt="" :class="$style.goalIcon" :src="`/images/events/${goal.isOwn ? 'GoalOwn.svg' : 'Goal.svg'}`"/>
						<div :class="$style.goalMinuteOfPlay" v-text="goal.minuteOfPlay"/>
						<div :class="$style.goalPlayerName" v-text="goal.playerName"/>
					</div>
				</div>
			</div>
			<div :class="$style.content">
				<div :class="$style.caption">
				<span v-if="status === 'beforeSecondHalf'">
					<span v-if="countdown"><strong>{{ $l.caption_halfTime }} •</strong> {{ $l.caption_matchContinuesIn }} {{ countdown }}</span>
					<strong v-else v-text="$l.caption_matchContinuesAnyMoment"/>
				</span>
					<strong v-else v-text="$l.caption_matchHasEnded"/>
				</div>
				<div v-if="statistics" :class="$style.statistics">
					<v-match-statistics :statistics="statistics" @player-clicked="onPlayerClicked"/>
				</div>
				<div v-else :class="$style.statisticsNotAvailable" v-text="$l.caption_statisticsAreNotAvailable"/>
			</div>
			<div :class="$style.awayClub">
				<div :class="$style.clubLogoAndScore">
					<div :class="$style.clubLogo"/>
					<div :class="$style.clubScore" v-text="score.away"/>
				</div>
				<div :class="$style.clubCode" v-text="awayClub.code"/>
				<div :class="$style.goals">
					<div v-for="goal in goals.away" :class="$style.goal" :key="goal.id">
						<img alt="" :class="$style.goalIcon" :src="`/images/events/${goal.isOwn ? 'GoalOwn.svg' : 'Goal.svg'}`"/>
						<div :class="$style.goalMinuteOfPlay" v-text="goal.minuteOfPlay"/>
						<div :class="$style.goalPlayerName" v-text="goal.playerName"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { useLocalization } from "@/i18n/VueLocalization"
	import LiveState from "@/live/model/LiveState"
	import MatchEvent from "@/model/MatchEvent"
	import { GoalMatchEventType } from "@/model/MatchEventType"
	import { VMMatchStatistics } from "@/statistics/model/VMMatchStatistics"
	import VMatchStatistics from "@/statistics/views/VMatchStatistics.vue"
	import { computed, defineComponent, onBeforeUnmount, PropType, ref } from "vue"


	export default defineComponent({
		components: { VMatchStatistics },
		props: {
			state: { type: Object as PropType<LiveState>, required: true },
		},
		setup(props, { emit }) {
			const $l = useLocalization()

			const awayClubRef = computed(() => props.state.fixture.awayClub)
			const currentTimestampRef = ref(Date.now()) // FIXME use live clock
			const homeClubRef = computed(() => props.state.fixture.homeClub)
			const playersRef = computed(() => props.state.players)
			const scoreRef = computed(() => props.state.score)
			const statisticsRef = computed(() => props.state.statistics ? VMMatchStatistics(props.state.statistics, props.state.players, $l) : null)
			const statusRef = computed(() => props.state.status)
			const styleRef = computed(() => ({
				"--VMatchAfterSectionEnd-awayClubLogoBackground": `url("${awayClubRef.value.logoUrl}")`,
				"--VMatchAfterSectionEnd-homeClubLogoBackground": `url("${homeClubRef.value.logoUrl}")`,
			}))

			const interval = setInterval(() => currentTimestampRef.value = Date.now(), 1_000) // FIXME use live clock
			onBeforeUnmount(() => clearInterval(interval))

			const countdownRef = computed(() => {
				let targetTimestamp: number
				switch (statusRef.value) {
					case "beforeMatch":
					case "inFirstHalf":
					case "inSecondHalf":
					case "afterMatch":
						return null

					case "beforeSecondHalf":
						targetTimestamp = props.state.timing.secondHalfKickoffTimestamp.getTime()
				}

				let seconds = (targetTimestamp - currentTimestampRef.value) / 1_000
				if (seconds <= 0) {
					clearInterval(interval)

					return null
				}

				const secondsPerMinute = 60
				const minutes = Math.floor(seconds / secondsPerMinute)
				seconds = Math.floor(seconds % secondsPerMinute)

				return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
			})

			const goalsRef = computed(() => {
				const state = props.state
				const events = state.goalEventsByScoringSide

				return { away: prepareGoalEvents(events.away, state), home: prepareGoalEvents(events.home, state) }
			})


			function prepareGoalEvents(events: ReadonlyArray<MatchEvent<GoalMatchEventType>>, state: LiveState) {
				return events.map(event => ({
					id: event.id,
					isOwn: event.type.isOwn,
					minuteOfPlay: state.formattedMinuteOfPlay(event.timestamp) + "’",
					playerName: playersRef.value.get(event.type.playerId)?.shortName ?? "?",
				}))
			}

			const onPlayerClicked = (id: string) => {
				emit("playerClicked", id)
			}

			return {
				awayClub: awayClubRef,
				countdown: countdownRef,
				goals: goalsRef,
				homeClub: homeClubRef,
				score: scoreRef,
				statistics: statisticsRef,
				status: statusRef,
				style: styleRef,
				onPlayerClicked,
			}
		},
	})
</script>

<style lang="scss" module>
	.awayClub {
		margin-left: calc(8 * var(--scale));

		@media (orientation: landscape) {
			order: 3;
		}

		@media (orientation: portrait) {
			order: 2;
		}
	}

	.awayClub, .homeClub {
		align-self: stretch;
		background: rgba(18, 18, 42, 0.75);
		border-radius: calc(8 * var(--scale));

		@media (orientation: landscape) {
			height: calc(356 * var(--scale));
			width: calc(196 * var(--scale));
		}

		@media (orientation: portrait) {
			width: calc(50% - (8 * var(--scale)));
		}
	}

	.clubCode {
		background: #3E1047;
		box-shadow: 0 calc(6 * var(--scale)) calc(9 * var(--scale)) rgba(0, 0, 0, 0.25);
		font-size: calc(18 * var(--scale));
		font-weight: bold;
		height: calc(48 * var(--scale));
		letter-spacing: var(--scale);
		line-height: calc(48 * var(--scale));
		padding: 0 calc(16 * var(--scale));
	}

	.clubLogo {
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		height: calc(72 * var(--scale));
		width: calc(72 * var(--scale));

		.awayClub & {
			background-image: var(--VMatchAfterSectionEnd-awayClubLogoBackground);
		}

		.homeClub & {
			background-image: var(--VMatchAfterSectionEnd-homeClubLogoBackground);
		}
	}

	.clubLogoAndScore {
		align-items: center;
		display: flex;
		font-size: calc(80 * var(--scale));
		justify-content: space-between;
		line-height: calc(80 * var(--scale));
		padding: calc(16 * var(--scale));

		.awayClub > & {
			flex-direction: row-reverse;
		}
	}

	.caption {
		background: #3E1047;
		box-shadow: 0 calc(6 * var(--scale)) calc(9 * var(--scale)) rgba(0, 0, 0, 0.25);
		font-size: calc(16 * var(--scale));
		height: calc(48 * var(--scale));
		line-height: calc(48 * var(--scale));
		text-align: center;
		text-transform: uppercase;
	}

	.container {
		display: flex;
		flex-wrap: wrap;

		@media (orientation: portrait) {
			width: calc(328 * var(--scale));
		}
	}

	.content {
		background: rgba(18, 18, 42, 0.75);
		border-radius: calc(8 * var(--scale));
		display: flex;
		flex-direction: column;
		overflow: hidden;

		@media (orientation: landscape) {
			height: calc(356 * var(--scale));
			margin: 0 calc(8 * var(--scale));
			order: 2;
			width: calc(344 * var(--scale));
		}

		@media (orientation: portrait) {
			margin-top: calc(16 * var(--scale));
			order: 3;
			width: 100%;
		}
	}

	.goal {
		align-items: flex-start;
		display: flex;
		font-size: calc(14 * var(--scale));
		line-height: calc(21 * var(--scale));
		padding: calc(4 * var(--scale)) 0;

		&Icon {
			height: calc(12 * var(--scale));
			margin: calc(4.5 * var(--scale)) calc(8 * var(--scale)) 0 0;
			width: calc(12 * var(--scale));
		}

		&PlayerName {
			margin-left: calc(8 * var(--scale));
		}
	}

	.goals {
		padding: calc(12 * var(--scale)) calc(16 * var(--scale));
	}

	.homeClub {
		margin-right: calc(8 * var(--scale));
		order: 1;
	}

	.root {
		background: center no-repeat url(/public/images/stadium_background.jpeg);
		background-size: cover;
		display: flex;
		justify-content: center;
		user-select: none;

		@media (orientation: landscape) {
			align-items: center;
		}

		@media (orientation: portrait) {
			align-items: flex-start;
			overflow-y: auto;
			overflow-y: overlay;
			padding: calc(64 * var(--scale)) 0;
		}
	}

	.statistics {
		@media (orientation: landscape) {
			overflow-y: auto;
			overflow-y: overlay;
		}
	}

	.statisticsNotAvailable {
		font-size: calc(16 * var(--scale));
		line-height: calc(24 * var(--scale));
		padding: calc(32 * var(--scale)) calc(72 * var(--scale));
		text-align: center;
	}
</style>
