import LiveMatchConnection from "@/live/LiveMatchConnection"
import LiveStateController from "@/live/LiveStateController"
import LiveMessage from "@/live/model/LiveMessage"
import LiveState from "@/live/model/LiveState"
import FrameSetLiveMessage from "@/live/model/messages/FrameSetLiveMessage"
import PositionsFrameSetLiveMessage from "@/live/model/messages/PositionsFrameSetLiveMessage"
import UnknownLiveMessage from "@/live/model/messages/UnknownLiveMessage"
import WelcomeLiveMessage from "@/live/model/messages/WelcomeLiveMessage"
import Fixture from "@/model/Fixture"


export default class LiveMatch {

	private readonly connection: LiveMatchConnection
	private readonly stateController: LiveStateController


	private constructor(
		private readonly id: string,
		private readonly onFixture: (fixture: Fixture) => void,
		private readonly onState: (state: LiveState) => void,
	) {
		this.connection = new LiveMatchConnection(id, message => this.onMessage(message))
		this.stateController = new LiveStateController(state => this.onState(state))
	}


	static start(
		id: string,
		onFixture: (fixture: Fixture) => void,
		onState: (state: LiveState) => void,
	): LiveMatch {
		return new this(id, onFixture, onState)
	}


	private onMessage(message: LiveMessage) {
		if (message instanceof FrameSetLiveMessage)
			this.stateController.add(message.value)
		else if (message instanceof PositionsFrameSetLiveMessage) {
			this.stateController.add(message.value)
		}
		else if (message instanceof UnknownLiveMessage)
			console.error("Unknown live message", message)
		else if (message instanceof WelcomeLiveMessage)
			this.onFixture(message.fixture)
	}

	stop() {
		this.connection.disconnect()
	}
}
