<template>
	<div :class="$style.root" :style="style">
		<div :class="$style.header">
			<div :class="$style.headerBackground1"/>
			<div :class="$style.headerBackground2"/>
			<div :class="[$style.club, $style.homeClub]">
				<div :class="$style.clubLogo"/>
				<span :class="$style.clubCode" v-text="homeClub.code"/>
			</div>
			<div :class="$style.matchStatus">
				<div v-if="matchIsRunning" :class="$style.time">
					<v-live-match-time :state="state"/>
				</div>
				<div :class="$style.score">{{ score.home }} : {{ score.away }}</div>
			</div>
			<div :class="[$style.club, $style.awayClub]">
				<div :class="$style.clubLogo"/>
				<span :class="$style.clubCode" v-text="awayClub.code"/>
			</div>
		</div>
		<div v-if="statistics">
			<v-match-statistics :statistics="statistics" @player-clicked="onPlayerClicked"/>
		</div>
		<div v-else :class="$style.notAvailable" v-text="$l.caption_statisticsAreAvailableShortly"/>
	</div>
</template>

<script lang="ts">
	import { useLocalization } from "@/i18n/VueLocalization"
	import VLiveMatchTime from "@/live/components/VLiveMatchTime.vue"
	import LiveState from "@/live/model/LiveState"
	import { VMMatchStatistics } from "@/statistics/model/VMMatchStatistics"
	import VMatchStatistics from "@/statistics/views/VMatchStatistics.vue"
	import { computed, defineComponent, PropType } from "vue"


	export default defineComponent({
		components: {
			VMatchStatistics,
			VLiveMatchTime,
		},
		props: {
			state: { type: Object as PropType<LiveState>, required: true },
		},
		emits: {
			"playerClicked": (_: string) => true,
		},
		setup(props, { emit }) {
			const $l = useLocalization()

			const awayClubRef = computed(() => props.state.fixture.awayClub)
			const homeClubRef = computed(() => props.state.fixture.homeClub)
			const matchIsRunningRef = computed(() => ["inFirstHalf", "inSecondHalf"].includes(props.state.status))
			const scoreRef = computed(() => props.state.score)
			const statisticsRef = computed(() => props.state.statistics ? VMMatchStatistics(props.state.statistics, props.state.players, $l) : null)
			const styleRef = computed(() => ({
				"--VMatchStatisticsScreen-awayClubLogoBackground": `url("${awayClubRef.value.logoUrl}")`,
				"--VMatchStatisticsScreen-homeClubLogoBackground": `url("${homeClubRef.value.logoUrl}")`,
			}))

			function onPlayerClicked(id: string) {
				emit("playerClicked", id)
			}

			return {
				awayClub: awayClubRef,
				homeClub: homeClubRef,
				matchIsRunning: matchIsRunningRef,
				onPlayerClicked,
				score: scoreRef,
				statistics: statisticsRef,
				style: styleRef,
			}
		},
	})
</script>

<style lang="scss" module>
	.awayClub .clubLogo {
		background-image: var(--VMatchStatisticsScreen-awayClubLogoBackground);
	}

	.club {
		flex-grow: 1;
		justify-content: center;

		&Code {
			display: block;
			font-size: 18px;
			letter-spacing: 1px;
			line-height: 18px;
			margin-top: 8px;
		}

		&Logo {
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			height: 64px;
		}
	}

	.header {
		display: flex;
		padding: 16px 8px;
		position: relative;
		text-align: center;
		z-index: 0;

		&Background1 {
			background: rgba(18, 18, 42, 0.6);
			box-shadow: inset 0 0 34px rgba(0, 0, 0, 0.5);
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: -2;
		}

		&Background2 {
			background: radial-gradient(83.94% 151.33% at 50% 165.93%, #00C5CF 0%, rgba(0, 197, 207, 0) 100%);
			opacity: 0.6;
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: -1;
		}
	}

	.homeClub .clubLogo {
		background-image: var(--VMatchStatisticsScreen-homeClubLogoBackground);
	}

	.matchStatus {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 128px;
	}

	.notAvailable {
		font-size: 18px;
		line-height: 27px;
		padding: 32px;
		text-align: center;
	}

	.root {
		color: #EDE5DC;
		max-height: 100%;
		overflow-y: auto;
		overflow-y: overlay;
		user-select: none;
	}

	.score {
		font-size: 40px;
		font-weight: bold;
		line-height: 28px;
	}

	.time {
		background: rgba(18, 18, 42, 0.6);
		font-size: 22px;
		font-weight: bold;
		line-height: 32px;
		margin-bottom: 16px;
		padding: 0 8px;
	}
</style>
