import ResourceDisposer from "@/live/views/visualization/ResourceDisposer"
import MatchPositionsPlayer from "@/model/MatchPositionsPlayer"
import PitchSize from "@/model/PitchSize"
import { Color, Group, Mesh, MeshBasicMaterial, Object3D, Texture, TextureLoader, Vector3 } from "three"
import { BufferGeometry } from "three/src/core/BufferGeometry"
export default class {

	private readonly arrow: Mesh<BufferGeometry, MeshBasicMaterial>
	private readonly container: Group
	private readonly disposer = new ResourceDisposer()
	private readonly face: Mesh<BufferGeometry, MeshBasicMaterial>
	private readonly textureLoader: TextureLoader

	private _color: string
	private _faceImageIsPlaceholder = true
	private _faceImageUrl: string | null

	constructor(
		arrowGeometry: BufferGeometry,
		arrowTexture: Texture,
		faceGeometry: BufferGeometry,
		textureLoader: TextureLoader,
		color: string,
		faceImageUrl: string | null,
	) {
		this.textureLoader = textureLoader

		this._color = color
		this._faceImageUrl = faceImageUrl

		const container = new Group()
		container.renderOrder = 1
		container.scale.setScalar(4 / 96)

		const arrow = new Mesh(
			arrowGeometry,
			new MeshBasicMaterial({
				alphaTest: 0.95,
				map: arrowTexture,
				transparent: false,
			}),
		)
		arrow.position.set(0, 36 / 2, 0)
		container.add(arrow)

		const face = new Mesh(
			faceGeometry,
			new MeshBasicMaterial({
				alphaTest: 0.95,
				transparent: false,
			}),
		)
		face.position.set(0, (96 / 2) + 36, 0)
		container.add(face)

		this.arrow = arrow
		this.container = container
		this.face = face

		// Don't add arrow & face as their geometry & material is partially shared.
		this.disposer.add(container)

		this.applyFaceImageUrl()

		this.applyColor()
	}


	addChild(child: Object3D) {
		child.position.set(0, 96 + 36 + 32, 0) // face height + arrow height + space
		this.container.add(child)
	}


	addToParent(parent: Object3D) {
		parent.add(this.container)
	}


	set color(color: string) {
		if (color === this._color)
			return

		this._color = color

		this.applyColor()
	}


	dispose() {
		this.disposer.dispose()

		this.arrow.material.dispose()
		this.face.material.map?.dispose()
		this.face.material.dispose()
	}


	faceCamera(position: Vector3) {
		this.container.lookAt(position)
	}


	set faceImageUrl(faceImageUrl: string | null) {
		if (faceImageUrl === this._faceImageUrl)
			return

		this._faceImageUrl = faceImageUrl

		this.applyFaceImageUrl()
	}


	hasChild(child: Object3D) {
		return child.parent === this.container
	}


	get position(): Vector3 {
		return this.container.position
	}


	removeFromParent() {
		this.container.removeFromParent()
	}


	update(state: MatchPositionsPlayer, pitchSize: PitchSize) {
		const position = {
			x: state.x * pitchSize.x,
			y: state.y * pitchSize.y,
		}

		this.container.position.set(position.x,position.y, this.container.position.z)
	}


	private applyColor() {
		const color = new Color(parseInt(this._color.replace("#", ""), 16)) // TODO Improve.
		this.arrow.material.color = color
		this.face.material.color = this._faceImageIsPlaceholder ? color : new Color()
	}


	private applyFaceImageUrl() {
		this._faceImageIsPlaceholder = true

		this.face.material.map = this.textureLoader.load(
			this._faceImageUrl ?? "/images/grayMissingPlayer.png",
			() => {
				this._faceImageIsPlaceholder = !this._faceImageUrl
				this.applyColor()
			},
			undefined,
			() => this.face.material.map = this.textureLoader.load("/images/grayMissingPlayer.png"),
		)
	}
}
