import LiveMessage from "@/live/model/LiveMessage"


export default class UnknownLiveMessage extends LiveMessage {

	constructor(readonly json: any) {
		super()
	}


	static fromJson(json: any) {
		return new this(json)
	}
}
