import LiveState from "@/live/model/LiveState"
import BallVisualization from "@/live/views/visualization/BallVisualization"
import PlayerVisualization from "@/live/views/visualization/PlayerVisualization"
import ResourceDisposer from "@/live/views/visualization/ResourceDisposer"
import CameraMode from "@/model/CameraMode"
import MatchPositions from "@/model/MatchPositions"
import MatchPositionsBall from "@/model/MatchPositionsBall"
import MatchPositionsPlayer from "@/model/MatchPositionsPlayer"
import PitchSize from "@/model/PitchSize"
import Player from "@/model/Player"
import { error } from "@/utility"
import gsap from "gsap"
import { DirectionalLight, HemisphereLight, Cache, Color, Group, PerspectiveCamera, PlaneGeometry, Scene, TextureLoader, Vector2, WebGLRenderer } from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer"
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass"
import { CSS2DObject, CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer"
import { watchEffect } from "vue"
import ExpSmoothingPositionTransform from "./SmoothenedPosition"
import Stats from "three/examples/jsm/libs/stats.module"
import PositionInterpolatorLinear from "./PositionInterpolation"

export default class {

	private readonly ballPossessionLabel: CSS2DObject
	private readonly camera = new PerspectiveCamera(45, 1, 0.1, 1000)
	private readonly container: HTMLElement
	private readonly cssRenderer = new CSS2DRenderer()
	private readonly disposer = new ResourceDisposer()
	private readonly gltfLoader = new GLTFLoader()
	private readonly hemisphereLight = new HemisphereLight( 0xffffff, 0xffffff, 1 )
	private readonly directionalLight = new DirectionalLight(0xffffff, .15 )
	private readonly pitchSize = new PitchSize(139, 83) // Stadium model has oddly-sized pitch.
	private readonly playerVisualizations = new Map<string, PlayerVisualization>()
	private readonly renderer = new WebGLRenderer({ alpha: true, antialias: true, powerPreference: "low-power" })
	private readonly resizeHandler = () => this.onResize()
	private readonly scene = new Scene()
	private readonly textureLoader = new TextureLoader()

	private readonly ballVisualization = new BallVisualization(this.textureLoader)
	private readonly composer = new EffectComposer(this.renderer)
	private readonly halfPitchSizeX = this.pitchSize.x * 0.5
	private readonly halfPitchSizeY = this.pitchSize.y * 0.5
	private readonly maximumCameraRotationY = Math.PI / 8
	private readonly maximumCameraRotationZ = Math.PI / 16
	private readonly pitch = new Group()
	private readonly playerArrowGeometry = new PlaneGeometry(96, 36)
	private readonly playerArrowTexture = this.textureLoader.load("/images/basePoint_new.png")
	private readonly playerFaceGeometry = new PlaneGeometry(96, 96)

	private _stats = Stats()

	private _animationFrameKey = 0
	private _cameraMode: CameraMode = "auto"
	private _isDisposed = false
	private _players: ReadonlyMap<string, Player> = new Map<string, Player>()
	private _positions: MatchPositions | null = null
	private _render = this.render.bind(this)
	private _state: LiveState | null = null
	private _stopWatchEffects: (() => void)[] = []

	// make the auto camera movement follow a smoothened and lagged ball position.
	// this has the visual effect of inertia to camera movement.
	private autoCameraTargetPosition = new ExpSmoothingPositionTransform(750.0)

	private positionInterpolation = new PositionInterpolatorLinear(this._state)

	private playbackSpeed = 0
	private targetPlaybackSpeed = 1
	private accelerationRange = 1
	private clientLastFrameTS = 0
	private matchLastFrameTS = 0

	constructor(
		private readonly ballPossessionLabelElement: HTMLDivElement,
		cameraMode: CameraMode,
		container: HTMLElement,
		stadiumModelName: string,
	) {
		Cache.enabled = true

		const pixelRatio = window.devicePixelRatio

		this.container = container

		this._cameraMode = cameraMode

		this.ballPossessionLabel = new CSS2DObject(this.ballPossessionLabelElement)

		// Pitch is vertical so let's rotate it horizontally.
		this.pitch.rotation.set(-Math.PI / 2, 0, 0)

		// Render properly on high-resolution devices.
		this.renderer.setPixelRatio(pixelRatio) // TODO Update on change.

		this.cssRenderer.domElement.style.position = "absolute"
		this.cssRenderer.domElement.style.top = "0px"

		this.scene.background = new Color(0x8CBED6)
		this.scene.add(this.hemisphereLight)
		this.scene.add(this.directionalLight)
		this.scene.add(this.pitch)

		this.ballVisualization.addToParent(this.pitch)

		this.gltfLoader.load(
			`/stadium/${stadiumModelName}.glb`,
			texture => {
				texture.scene.scale.set(1.25, 1.05, 1.12)

				this.disposer.add(texture)
				this.scene.add(texture.scene)
			},
		)

		const renderPass = new RenderPass(this.scene, this.camera)
		this.composer.addPass(renderPass)

		const outlinePass = new OutlinePass(new Vector2(window.innerWidth, window.innerHeight), this.scene, this.camera)
		outlinePass.edgeGlow = 0
		outlinePass.edgeStrength = 2 * pixelRatio
		outlinePass.edgeThickness = 1
		outlinePass.hiddenEdgeColor = new Color("#999999")
		outlinePass.pulsePeriod = 2
		outlinePass.visibleEdgeColor = new Color("#333333")
		this.composer.addPass(outlinePass)

		outlinePass.selectedObjects = [this.ballVisualization.rootObject]

		this.disposer.add(
			this.ballPossessionLabel,
			this.ballVisualization,
			this.camera,
			this.hemisphereLight,
			this.pitch,
			this.playerArrowTexture,
			this.renderer,
			this.scene,
		)

		container.appendChild(this.renderer.domElement)
		container.appendChild(this.cssRenderer.domElement)

		if ( process.env.VUE_APP_ADMIN_MODE ) {
			this._stats.showPanel(1)
			container.appendChild(this._stats.domElement)
		}

		this.updateSizeDependentProperties()
		window.addEventListener("resize", this.resizeHandler)

		this._animationFrameKey = requestAnimationFrame(this._render)
	}


	// noinspection JSUnusedGlobalSymbols
	set cameraMode(cameraMode: CameraMode) {
		if (this._isDisposed)
			error("Cannot change camera mode after disposing.")

		if (cameraMode === this._cameraMode)
			return

		this._cameraMode = cameraMode
	}


	private clearWatchEffects() {
		for (const stop of this._stopWatchEffects)
			stop()

		this._stopWatchEffects = []
	}


	dispose() {
		if (this._isDisposed)
			return

		this._isDisposed = true

		window.removeEventListener("resize", this.resizeHandler)

		this.clearWatchEffects()

		this.ballVisualization.removeFromParent()

		for (const visualization of this.playerVisualizations.values()) {
			visualization.removeFromParent()
			visualization.dispose()
		}

		this.disposer.dispose()

		cancelAnimationFrame(this._animationFrameKey)
		this._animationFrameKey = 0
	}


	private onResize() {
		this.updateSizeDependentProperties()
	}

	private updatePositions(){
		if (!this._state) return

		/*
		input: 
		- clientNow
		- clientLastFrameTS
		- matchLastFrameTS
		- matchNewestFrameTS
		- livePlayback
		- playbackSpeed

		output:
		- playbackSpeed
		- matchThisFrameTS
		*/
		const matchNewestFrameTS = this._state._framesBuffer.newestFrame()!.timestamp
		const clientNow = Date.now()


		// initialization, first frame
		if (this.clientLastFrameTS == 0){
			this.clientLastFrameTS = clientNow
			this.matchLastFrameTS = matchNewestFrameTS - 2000
		}


		const minAcceleration = -this.playbackSpeed //- Math.min(this.accelerationRange / 2, this.playbackSpeed / 2)
		const maxAcceleration = 1 // inAcceleration + this.accelerationRange

		const clientDt  = this.clientLastFrameTS ? (clientNow - this.clientLastFrameTS)/1000 : 0

		const mode = matchNewestFrameTS - this.matchLastFrameTS < 1000 ? 'bufferUnderflow' : (
			matchNewestFrameTS - this.matchLastFrameTS > 3000 ? 'bufferOverflow' : 'onTarget'
		)

		let acceleration = 0
		if (mode == 'onTarget') acceleration = this.targetPlaybackSpeed - this.playbackSpeed
		else if (mode == 'bufferUnderflow') acceleration = minAcceleration // decelerate
		else if (mode == 'bufferOverflow') acceleration = maxAcceleration // accelerate

		this.playbackSpeed += acceleration * clientDt
		
		let matchThisFrameTS = this.matchLastFrameTS + 1000* this.playbackSpeed * clientDt
		matchThisFrameTS = Math.min(matchThisFrameTS, matchNewestFrameTS)
		
		const interpolatedFrame = this.positionInterpolation.interpolate(matchThisFrameTS)
		if (interpolatedFrame){
			this.updatePlayerPositions(interpolatedFrame.players)
			this.updateBallPosition(interpolatedFrame.ball)
		}

		this._state.stats['mode'] = mode
		this._state.stats['acceleration'] = acceleration.toFixed(3)
		this._state.stats['playbackSpeed'] = this.playbackSpeed.toFixed(3)
		this._state.stats['bufferDuration'] = ((matchNewestFrameTS - this.matchLastFrameTS)/1000).toFixed(3)
		this._state.stats['delay'] = ((clientNow - matchThisFrameTS)/1000).toFixed(3)
		this._state.stats['clientDt'] = clientDt.toFixed(3)

		this.clientLastFrameTS = clientNow
		this.matchLastFrameTS = matchThisFrameTS
		

	}

	private render() {
		this._animationFrameKey = requestAnimationFrame(this._render)

		this.updatePositions()

		const cameraPosition = this.camera.position

		this.updateCamera()

		this.ballVisualization.faceCamera(cameraPosition)

		for (const visualization of this.playerVisualizations.values())
			visualization.faceCamera(cameraPosition)

		this.updateBallPossessionLabel()

		this.cssRenderer.render(this.scene, this.camera)
		this.composer.render()
		if (process.env.VUE_APP_ADMIN_MODE ) this._stats.update()
	}


	private updateBallPosition(position: MatchPositionsBall) {
		this.ballVisualization.update(position, this.pitchSize)
	}


	private updateBallPossessionLabel() {
		const ballPosition = this.ballVisualization.position

		// Don't update possession if the ball is more 3m or higher in the air.
		if (ballPosition.z >= 3)
			return

		let closestPlayerDistance = Infinity
		let closestPlayer: PlayerVisualization | undefined
		let closestPlayerId: string | undefined

		for (const [playerId, player] of this.playerVisualizations.entries()) {
			const distance = player.position.distanceToSquared(ballPosition)
			if (distance < closestPlayerDistance) {
				closestPlayer = player
				closestPlayerId = playerId
				closestPlayerDistance = distance
			}
		}

		if (closestPlayer) {
			if (!closestPlayer.hasChild(this.ballPossessionLabel)) {
				let closestPlayerObject = this._players.get(closestPlayerId!!)
				if (!closestPlayerObject)
					error(`Missing data for player ${closestPlayerId}.`)

				this.ballPossessionLabelElement.textContent = closestPlayerObject.shortName
				closestPlayer.addChild(this.ballPossessionLabel)
			}
		}
		else
			this.ballPossessionLabel.removeFromParent()
	}


	private updateCamera() {
		const camera = this.camera

		switch (this._cameraMode) {
			case "auto":

				const { x, y } = this.ballVisualization.position

				this.autoCameraTargetPosition.update(x, y, 0)

				const laggedX = this.autoCameraTargetPosition.x
				const laggedY = this.autoCameraTargetPosition.y

				const halfPitchSizeX = this.halfPitchSizeX
				const halfPitchSizeY = this.halfPitchSizeY

				camera.position.set(
					Math.sign(laggedX) * Math.log((4 * Math.abs(laggedX / halfPitchSizeX)) + 1) / 4 * halfPitchSizeX,
					50,
					70 - (Math.sign(laggedY) * Math.log((4 * Math.abs(laggedY / halfPitchSizeY)) + 1) / 3 * halfPitchSizeY),
				)

				const normalizedX = -laggedX / halfPitchSizeX
				camera.rotation.set(-0.65, normalizedX * this.maximumCameraRotationY, normalizedX * this.maximumCameraRotationZ)

				break

			case "broadcast":
				gsap.to(camera.position, { duration: 1.5, x: 0, y: 85, z: 85 })
				gsap.to(camera.rotation, { duration: 1.5, x: -0.7853981633974484, y: 0, z: 0 })
				break

			case "left":
				gsap.to(camera.position, { duration: 1.5, x: -110, y: 30, z: 0 })
				gsap.to(camera.rotation, { duration: 1.5, x: -Math.PI / 2, y: -1.2, z: -Math.PI / 2 })
				break

			case "leftCorner":
				gsap.to(camera.position, { duration: 1.5, x: -100, y: 30, z: 50 })
				gsap.to(camera.rotation, {
					duration: 1.5,
					x: -0.5404195002705843,
					y: -1.042899579242163,
					z: -0.4781967964961644,
				})
				break

			case "right":
				gsap.to(camera.position, { duration: 1.5, x: 110, y: 30, z: 0 })
				gsap.to(camera.rotation, { duration: 1.5, x: -Math.PI / 2, y: 1.2, z: Math.PI / 2 })
				break

			case "rightCorner":
				gsap.to(camera.position, { duration: 1.5, x: 100, y: 30, z: 50 })
				gsap.to(camera.rotation, {
					duration: 1.5,
					x: -0.5404195002705843,
					y: 1.042899579242163,
					z: 0.4781967964961644,
				})
				break
		}
	}


	private updatePlayerPositions(positions: ReadonlyMap<string, MatchPositionsPlayer>) {
		for (const [id, position] of positions)
			this.playerVisualizations.get(id)?.update(position, this.pitchSize)
	}


	private updatePlayerVisualizations(state: LiveState) {
		
		const positions = state.positions?.players
		if (!positions)
			return

		for (const [id, visualization] of this.playerVisualizations)
			if (!positions.has(id)) {
				visualization.removeFromParent()
				visualization.dispose()

				this.playerVisualizations.delete(id)
			}

		for (const id of positions.keys()) {
			const player = state.players.get(id) // FIXME separate update
			if (!player)
				continue

			const club = state.club(player.clubId)

			let visualization = this.playerVisualizations.get(id)
			if (visualization) {
				visualization.color = club.shirtColors.primary
				visualization.faceImageUrl = player.faceImageUrl
			}
			else {
				visualization = new PlayerVisualization(
					this.playerArrowGeometry,
					this.playerArrowTexture,
					this.playerFaceGeometry,
					this.textureLoader,
					club.shirtColors.primary,
					player.faceImageUrl,
				)
				visualization.addToParent(this.pitch)

				this.playerVisualizations.set(id, visualization)
			}
		}
	}


	private updateSizeDependentProperties() {
		const container = this.container
		const width = container.clientWidth
		const height = container.clientHeight
		const pixelRatio = this.renderer.getPixelRatio()

		this.camera.aspect = width / height
		this.camera.updateProjectionMatrix()

		this.composer.setSize(width * pixelRatio, height * pixelRatio)
		this.cssRenderer.setSize(width, height)
		this.renderer.setSize(width, height)
	}

	useState(state: LiveState) {
		if (this._isDisposed)
			error("Cannot update state after disposing.")

		if (state === this._state)
			return

		this._state = state
		this.positionInterpolation.setState(this._state)

		this.clearWatchEffects()
		this._stopWatchEffects.push(watchEffect(() => this._players = state.players))
		this._stopWatchEffects.push(watchEffect(() => this.updatePlayerVisualizations(state)))
	}
}
