<template>
	<div :class="$style.root">
		<div :class="$style.left">
			<a v-if="showsSoundToggle" :class="$style.toggleButton" href="#" @click.prevent="onSoundToggleClicked">
				<img v-if="soundEnabled" alt="" src="@/assets/new/icon-sound-active.svg">
				<img v-else alt="" src="@/assets/new/icon-sound.svg">
			</a>
		</div>
		<div :class="$style.cameraChooser">
			<v-camera-chooser v-if="showsCameraChooser" :model-value="cameraMode" @update:modelValue="onCameraModeChanged"/>
		</div>
		<div :class="$style.right">
			<a v-if="showsCameraToggle" :class="[$style.cameraButton, $style.toggleButton]" href="#" @click.prevent="onCameraToggleClicked">
				<img alt="" src="@/assets/new/icon-camera.svg">
			</a>
			<a v-if="showsFullscreenToggle" :class="$style.toggleButton" href="#" @click.prevent="onFullscreenToggleClicked">
				<img v-if="isInFullscreen" alt="" src="@/assets/new/icon-fullscreen-active.svg">
				<img v-else alt="" src="@/assets/new/icon-fullscreen.svg">
			</a>
		</div>
	</div>
</template>

<script lang="ts">
	import VCameraChooser from "@/live/components/VCameraChooser.vue"
	import CameraMode from "@/model/CameraMode"
	import { injectRequired } from "@/utility"
	import FullscreenController, { FullscreenControllerInjectionKey } from "@/utility/FullscreenController"
	import { defineComponent, PropType, ref, watchEffect } from "vue"
	import { useGtm } from "vue-gtm"


	export default defineComponent({
		components: { VCameraChooser },
		props: {
			cameraMode: { type: String as PropType<CameraMode>, required: true },
			showsCameraToggle: { type: Boolean, required: true },
			showsSoundToggle: { type: Boolean, required: true },
			soundEnabled: { type: Boolean, required: true },
		},
		emits: {
			"update:cameraMode": (_: CameraMode) => true,
			"update:soundEnabled": (_: boolean) => true,
		},
		setup(props, { emit }) {
			const gtm = useGtm()
			const fullscreenController: FullscreenController = injectRequired(FullscreenControllerInjectionKey)

			const isInFullscreenRef = ref(false)
			const showsCameraChooserRef = ref(false)
			const showsFullscreenToggleRef = fullscreenController.isFullscreenSupported

			watchEffect(() => isInFullscreenRef.value = fullscreenController.isInFullscreen)
			watchEffect(() => {
				if (!props.showsCameraToggle)
					showsCameraChooserRef.value = false
			})

			function onCameraToggleClicked() {
				gtm?.trackEvent({
					category: "GoalVieww",
					action: "click",
					label: "cameraModeToggle",
					value: `${showsCameraChooserRef.value ? "off" : "on"}`,
				})
				showsCameraChooserRef.value = !showsCameraChooserRef.value
			}

			function onCameraModeChanged(mode: CameraMode) {
				gtm?.trackEvent({
					category: "GoalVieww",
					action: "click",
					label: "selectCameraMode",
					value: `${mode}`,
				})
				emit("update:cameraMode", mode)
			}

			function onFullscreenToggleClicked() {
				gtm?.trackEvent({
					category: "GoalVieww",
					action: "click",
					label: "toggleFullscreen",
					value: `${fullscreenController.isInFullscreen ? "exit" : "enter"}`,
				})
				if (fullscreenController.isInFullscreen)
					fullscreenController.exitFullscreen()
				else
					fullscreenController.enterFullscreen()
			}

			function onSoundToggleClicked() {
				gtm?.trackEvent({
					category: "GoalVieww",
					action: "click",
					label: "toggleSound",
					value: `${props.soundEnabled ? "off" : "on"}`,
				})
				emit("update:soundEnabled", !props.soundEnabled)
			}

			return {
				isInFullscreen: isInFullscreenRef,
				onCameraModeChanged,
				onCameraToggleClicked,
				onFullscreenToggleClicked,
				onSoundToggleClicked,
				showsCameraChooser: showsCameraChooserRef,
				showsFullscreenToggle: showsFullscreenToggleRef,
			}
		},
	})
</script>

<style lang="scss" module>
	@media (orientation: portrait) and (max-height: 400px),
	(orientation: portrait) and (max-width: 279px),
	(orientation: landscape) and (max-height: 230px),
	(orientation: landscape) and (max-width: 400px) {
		.cameraButton, .cameraChooser {
			display: none;
		}
	}

	.left, .right {
		display: flex;
	}

	.root {
		align-items: flex-end;
		background: linear-gradient(360deg, rgba(0,0,0,0.75) -25.31%, rgba(0, 0, 0, 0) 89.14%) no-repeat center;
		bottom: 0;
		display: flex;
		justify-content: space-between;
		left: 0;
		padding: 0 calc(8px + env(safe-area-inset-right, 0px)) calc(8px + env(safe-area-inset-bottom, 0px)) calc(8px + env(safe-area-inset-left, 0px));
		pointer-events: none;
		position: fixed;
		right: 0;
		user-select: none;
		z-index: 15;

		@media (min-width: 1000px) {
			height: 130px;
		}

		@media (max-width: 850px) {
			height: 60px;
			padding: 0 env(safe-area-inset-right, 0) env(safe-area-inset-bottom, 0) env(safe-area-inset-left, 0);
		}
	}

	.toggleButton {
		padding: 8px;
		pointer-events: auto;

		& + & {
			margin-left: 16px;

			@media (max-width: 850px) {
				margin-left: 8px;
			}
		}

		& > img {
			display: block;
			height: 32px;
			width: 32px;
		}

		@media (max-width: 850px) {
			padding: 4px;
		}
	}
</style>
