<template>
	<div :class="$style.root">
		<div :class="$style.time">
			{{ minuteOfPlay }}'
		</div>

		<div :class="historyEventsStyle()">
			<div :class="$style.historyEventContent">
				<div :class="$style.playerName">
					<div :class="$style.substitutionEvent" v-if="secondPlayer && event.type.__type === 'Substitution'">
						<p
							v-if="player"
							:class="$style.playerOut"
							@click="player && onPlayerClicked(player.id)"
						>
							{{ player.shortName }}
						</p>
						<p
							v-if="secondPlayer"
							:class="$style.playerIn"
							@click="secondPlayer && onPlayerClicked(secondPlayer.id)"
						>
							{{ secondPlayer.shortName }}
						</p>
					</div>
					<p
						v-else
						@click="player?.id && onPlayerClicked(player.id)"
					>
						{{ player?.shortName }}
					</p>
				</div>

				<div v-if="event.type.__type !== 'Substitution'" :class="$style.icon">
					<div v-if="event.type.__type === 'Caution'" :class="cardStyle(event.type.card)"/>
					<img v-else :src="eventIcon"/>
				</div>
			</div>

			<div :class="$style.score" v-if="event.type.__type === 'Goal'">
				{{ score }}
			</div>

			<div></div>
		</div>

	</div>
</template>

<script lang="ts">
	import LiveState from "@/live/model/LiveState"
	import Card from "@/model/Card"
	import Club from "@/model/Club"
	import MatchEvent from "@/model/MatchEvent"
	import { GoalMatchEventType, SubstitutionMatchEventType } from "@/model/MatchEventType"
	import Player from "@/model/Player"
	import { computed, PropType } from "@vue/runtime-core"
	import { defineComponent, useCssModule } from "vue"


	export default defineComponent({
		props: {
			state: { type: Object as PropType<LiveState>, required: true },
			event: { type: Object as PropType<MatchEvent>, required: true },
			locale: String,
		},
		emits: {
			"playerClicked": (_: string) => true,
		},
		setup(props, { emit }) {
			const minuteOfPlayRef = computed(() => props.state.formattedMinuteOfPlay(props.event.timestamp))
			const score = computed(() => {
				if (props.event.type instanceof GoalMatchEventType)
					return props.event.type.score
				return null
			})
			const playerId = computed<string | null>(() => {
				if (props.event.type instanceof SubstitutionMatchEventType) {
					return props.event.type.outPlayerId
				}
				if ("playerId" in props.event.type) {
					const type: any = props.event.type
					return type.playerId
				}
				return null
			})
			const player = computed<Player | undefined>(() => playerId.value ? props.state.players.get(playerId.value) : undefined)
			const secondPlayerId = computed<string | null>(() => {
				if (props.event.type instanceof SubstitutionMatchEventType) {
					return props.event.type.inPlayerId
				}
				return null
			})
			const secondPlayer = computed(() => secondPlayerId.value && props.state.players.get(secondPlayerId.value))
			const clubId = computed<string | null>(() => {
				if ("clubId" in props.event.type) {
					const type: any = props.event.type
					return type.clubId
				}
				return null
			})
			const club = computed<Club | null>(() => {
				if (props.state.fixture.homeClub.id === clubId.value)
					return props.state.fixture.homeClub
				if (props.state.fixture.awayClub.id === clubId.value)
					return props.state.fixture.awayClub
				return null
			})
			const homeClub = computed<Club>(() => props.state.fixture.homeClub)

			function onPlayerClicked(id: string) {
				emit("playerClicked", id)
			}

			function shouldAlignLeft() {
				const shouldAlignLeft = (club.value && club.value.id != homeClub.value.id)
				if (props.event.type instanceof GoalMatchEventType && props.event.type.isOwn) {
					return !shouldAlignLeft
				}
				return shouldAlignLeft
			}

			const $style = useCssModule()

			function historyEventsStyle() {
				return [
					$style.historyEvent,
					shouldAlignLeft() ? $style.reverse : null,
				]
			}

			function cardStyle(card: Card) {
				let cardStyle
				switch (card) {
					case "red":
						cardStyle = $style.cardRed
						break
					case "yellow":
						cardStyle = $style.cardYellow
						break
					case "yellowRed":
						cardStyle = $style.cardYellowRed
						break
					default:
						cardStyle = null
						break
				}
				return [$style.card, cardStyle]
			}

			const eventIcon = computed(() => {
				if (props.event.type instanceof GoalMatchEventType && props.event.type.isOwn) {
					return "/images/events/GoalOwn.svg"
				}
				return `/images/events/${props.event.type.__type}.svg`
			})

			return {
				cardStyle,
				club,
				eventIcon,
				historyEventsStyle,
				minuteOfPlay: minuteOfPlayRef,
				onPlayerClicked,
				player,
				score,
				secondPlayer,
			}
		},
	})
</script>

<style lang="scss" module>
	.card {
		border-radius: 2px;
		height: 16px;
		min-width: 12px;
	}

	.cardRed {
		background-color: #E92E2E;
	}

	.cardYellow {
		background-color: #F4CC3E;
	}

	.cardYellowRed {
		background-color: #F4CC3E;
		position: relative;

		&::before {
			background-color: #E92E2E;
			border-radius: 2px;
			bottom: 0;
			content: "";
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			transform: translate(5px, 3px);
		}
	}

	.historyEvent {
		display: grid;
		grid-template-columns: 5fr 2fr 5fr;
		width: 100%;
	}

	.historyEventContent {
		align-items: center;
		display: flex;
		justify-content: flex-end;
		text-align: right;
	}

	.icon {
		display: flex;
		margin-left: 8px;
		min-width: 16px;
		flex-basis: 16px;

		img {
			width: 100%
		}
	}

	.playerIn,
	.playerOut {
		line-height: 100%;

		&::after {
			content: "";
		}

		&::after,
		&::before {
			background-repeat: no-repeat;
			background-size: contain;
			display: inline-block;
			height: 10px;
			margin-left: 6px;
			min-width: 10px;
		}
	}

	.playerIn {
		align-items: flex-start;
		margin-top: 5px;

		&::after {
			background-image: url('/public/images/events/arrowUp.svg');
		}
	}

	.playerName {
		cursor: pointer;

		p {
			display: flex;
		}
	}

	.playerOut {
		align-items: flex-end;
		color: #EDE5DC99;

		&::after {
			background-image: url('/public/images/events/arrowDown.svg');
		}
	}

	.reverse {
		direction: rtl;

		.historyEventContent {
			text-align: left;

			.icon {
				margin-left: 0;
				margin-right: 8px;
			}
		}

		.playerIn,
		.playerOut {
			&::after {
				margin-right: 6px;
			}
		}

		.substitutionEvent {
			text-align: left;
		}
	}

	.root {
		align-items: center;
		border-bottom: 1px solid #4E4E5E;
		display: flex;
		font-size: 12px;
		margin: 0 16px;
		padding: 8px 0;
	}

	.score {
		font-size: 16px;
		font-weight: bold;
		line-height: 20px;
		margin: 0 8px;
		text-align: center;
	}

	.substitutionEvent {
		align-items: flex-end;
		display: flex;
		flex-direction: column;
		text-align: right;
	}

	.time {
		font-weight: normal;
		line-height: 15px;
		margin-right: 8px;
		width: 30px;
	}
</style>
