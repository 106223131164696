import Fixture from "@/model/Fixture"
import Partner from "@/model/Partner"


export default class IntegrationInfo {

	constructor(
		readonly fixture: Fixture | null,
		readonly partner: Partner,
	) {
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			Fixture.fromJson(json.fixture),
			Partner.fromJson(json.partner),
		)
	}
}
