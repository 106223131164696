export default class Score {

	constructor(
		readonly home: number,
		readonly away: number,
	) {
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(json.home, json.away)
	}


	toString() {
		return `${this.home}:${this.away}`
	}
}
