export default class LivePositionsPlayer {

	constructor(
		readonly x: number,
		readonly y: number,
	) {
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(json.x, json.y)
	}
}
