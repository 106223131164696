<template>
	<div :class="$style.root">
		<div :class="$style.clubCode">
			<div :class="$style.clubColor" v-bind:style="{ backgroundColor: homeClubColor }"/>
			{{ homeClub.code }}
		</div>
		<div :class="$style.score" v-text="score"/>
		<div :class="$style.clubCode">
			<div :class="$style.clubColor" v-bind:style="{ backgroundColor: awayClubColor }"/>
			{{ awayClub.code }}
		</div>
		<div :class="$style.time">
			<v-live-match-time :state="state"/>
		</div>
	</div>
</template>

<script lang="ts">
	import VLiveMatchTime from "@/live/components/VLiveMatchTime.vue"
	import LiveState from "@/live/model/LiveState"
	import { computed, defineComponent, PropType } from "vue"


	export default defineComponent({
		components: { VLiveMatchTime },
		props: {
			state: { type: Object as PropType<LiveState>, required: true },
		},
		setup(props) {
			const homeClub = computed(() => props.state.fixture.homeClub)
			const homeClubColor = computed(() => props.state.homeClub.shirtColors.primary)
			const awayClub = computed(() => props.state.fixture.awayClub)
			const awayClubColor = computed(() => props.state.awayClub.shirtColors.primary)
			const score = computed(() => {
				const state = props.state
				return `${state.score.home}:${state.score.away}`
			})

			return { awayClub, awayClubColor, homeClub, homeClubColor, score }
		},
	})
</script>

<style lang="scss" module>
	.clubCode {
		align-items: center;
		background: #EDE5DC;
		color: #000;
		display: flex;
		padding: 0 8px;
	}

	.clubColor {
		box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3);
		border-radius: 6px;
		font-weight: bold;
		height: 12px;
		margin-right: 6px;
		width: 12px;
	}

	.root {
		display: flex;
		font-size: 14px;
		left: calc(16px + env(safe-area-inset-left, 0px));
		line-height: 22px;
		position: absolute;
		top: calc(16px + env(safe-area-inset-top, 0px));
		z-index: 100;

		@media (max-width: 850px) {
			left: calc(8px + env(safe-area-inset-left, 0px));
			top: calc(8px + env(safe-area-inset-top, 0px));
		}
	}

	.score {
		background: #3D003B;
		color: #EDE5DC;
		font-size: 18px;
		font-weight: bold;
		padding: 0 8px;
	}

	.time {
		background: #000;
		color: #EDE5DC;
		font-weight: bold;
		padding: 0 8px;
	}
</style>
