
    import LiveState from "@/live/model/LiveState"
    import { defineComponent, PropType, watchEffect, ref , computed, toRefs, onMounted, watch, reactive} from 'vue'
    

    export default defineComponent({
        props:{
            stats: { type: Object, required:true },
        },
        setup(props) {
            const statsRe = ref<Object>({})
      
            window.setInterval(()=>{
                statsRe.value = toRefs(props).stats
            },40)
            return { statsRe }
        },
    })
