
	import Fixture from "@/model/Fixture"
	import { computed, onBeforeUnmount, PropType, ref } from "vue"


	export default {
		props: {
			fixture: { type: Object as PropType<Fixture>, required: true },
		},
		setup(props) {
			const awayClubRef = computed(() => props.fixture.awayClub)
			const currentTimestampRef = ref(Date.now())
			const homeClubRef = computed(() => props.fixture.homeClub)
			const styleRef = computed(() => ({
				"--VMatchBeforeStart-awayClubLogoBackground": `url("${awayClubRef.value.logoUrl}")`,
				"--VMatchBeforeStart-homeClubLogoBackground": `url("${homeClubRef.value.logoUrl}")`,
			}))

			const interval = setInterval(() => currentTimestampRef.value = Date.now(), 1_000)
			onBeforeUnmount(() => clearInterval(interval))

			const countdownRef = computed(() => {
				let seconds = (props.fixture.startTimestamp - currentTimestampRef.value) / 1_000
				if (seconds <= 0) {
					clearInterval(interval)

					return null
				}

				const secondsPerMinute = 60
				const secondsPerHour = 60 * secondsPerMinute
				const secondsPerDay = 24 * secondsPerHour

				const days = Math.floor(seconds / secondsPerDay)
				seconds %= secondsPerDay

				const hours = Math.floor(seconds / secondsPerHour)
				seconds %= secondsPerHour

				const minutes = Math.floor(seconds / secondsPerMinute)
				seconds = Math.floor(seconds % secondsPerMinute)

				return {
					days: `${days < 10 ? "0" : ""}${days}`,
					hours: `${hours < 10 ? "0" : ""}${hours}`,
					minutes: `${minutes < 10 ? "0" : ""}${minutes}`,
					seconds: `${seconds < 10 ? "0" : ""}${seconds}`,
				}
			})

			return {
				awayClub: awayClubRef,
				countdown: countdownRef,
				homeClub: homeClubRef,
				style: styleRef,
			}
		},
	}
