export default class LivePositionsBall {

	constructor(
		readonly x: number,
		readonly y: number,
		readonly z: number,
	) {
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.x,
			json.y,
			json.z,
		)
	}
}
