<template>
	<div :class="$style.root">
		<a
			v-for="mode in modes"
			:class="modeClasses(mode)"
			href="#"
			:key="mode"
			@click.prevent="onModeClicked(mode)"
		>
			<div :class="$style.image"/>
			<div :class="$style.indicator"/>
		</a>
	</div>
</template>

<script lang="ts">
	import CameraMode from "@/model/CameraMode"
	import { defineComponent, PropType, useCssModule } from "vue"


	export default defineComponent({
		props: {
			modelValue: { type: String as PropType<CameraMode>, required: true },
		},
		emits: {
			"update:modelValue": (_: CameraMode) => true,
		},
		setup(props, { emit }) {
			const $style = useCssModule()

			const modes: ReadonlyArray<CameraMode> = ["auto", "left", "leftCorner", "broadcast", "rightCorner", "right"]

			function modeClasses(mode: CameraMode) {
				return [
					$style.mode,
					$style[`mode_${mode}`],
					mode === props.modelValue ? $style.active : null,
				]
			}

			function onModeClicked(mode: CameraMode) {
				emit("update:modelValue", mode)
			}

			return {
				modeClasses,
				modes,
				onModeClicked,
			}
		},
	})
</script>

<style lang="scss" module>
	.image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		height: 53px;
		width: 80px;

		.mode_auto & {
			background-image: url(~@/assets/new/camera-auto.svg);
		}

		.mode_broadcast & {
			background-image: url(~@/assets/new/camera-broadcast.svg);
		}

		.mode_left & {
			background-image: url(~@/assets/new/camera-left.svg);
		}

		.mode_leftCorner & {
			background-image: url(~@/assets/new/camera-leftCorner.svg);
		}

		.mode_right & {
			background-image: url(~@/assets/new/camera-right.svg);
		}

		.mode_rightCorner & {
			background-image: url(~@/assets/new/camera-rightCorner.svg);
		}
	}

	.indicator {
		background-clip: content-box;
		border-radius: 50%;
		border: 2px solid #EDE5DC;
		height: 20px;
		padding: 3px;
		width: 20px;

		@media (orientation: portrait) {
			margin: 0 12px 0 0;
		}
		@media (orientation: landscape) {
			margin: 12px 0 0 0;
		}

		.active & {
			background-color: #EDE5DC;
		}
	}

	.mode {
		align-items: center;
		display: flex;
		flex-direction: row-reverse;
		justify-content: center;
		pointer-events: auto;

		@media (orientation: landscape) {
			flex-direction: column;
		}
	}

	.root {
		align-items: center;
		display: grid;
		justify-content: space-between;
		padding: 0 0 8px 0;
		pointer-events: none;

		@media (orientation: portrait) {
			grid-gap: 8px;
		}
		@media (orientation: landscape) {
			grid-template-columns: repeat(6, auto);
			grid-template-rows: auto;
			grid-gap: 16px;
		}
		@media (orientation: landscape) and (max-width: 700px) {
			grid-template-columns: repeat(3, auto);
			grid-template-rows: repeat(2, auto);
			grid-gap: 16px;
		}
	}
</style>
