
	import VCameraChooser from "@/live/components/VCameraChooser.vue"
	import CameraMode from "@/model/CameraMode"
	import { injectRequired } from "@/utility"
	import FullscreenController, { FullscreenControllerInjectionKey } from "@/utility/FullscreenController"
	import { defineComponent, PropType, ref, watchEffect } from "vue"
	import { useGtm } from "vue-gtm"


	export default defineComponent({
		components: { VCameraChooser },
		props: {
			cameraMode: { type: String as PropType<CameraMode>, required: true },
			showsCameraToggle: { type: Boolean, required: true },
			showsSoundToggle: { type: Boolean, required: true },
			soundEnabled: { type: Boolean, required: true },
		},
		emits: {
			"update:cameraMode": (_: CameraMode) => true,
			"update:soundEnabled": (_: boolean) => true,
		},
		setup(props, { emit }) {
			const gtm = useGtm()
			const fullscreenController: FullscreenController = injectRequired(FullscreenControllerInjectionKey)

			const isInFullscreenRef = ref(false)
			const showsCameraChooserRef = ref(false)
			const showsFullscreenToggleRef = fullscreenController.isFullscreenSupported

			watchEffect(() => isInFullscreenRef.value = fullscreenController.isInFullscreen)
			watchEffect(() => {
				if (!props.showsCameraToggle)
					showsCameraChooserRef.value = false
			})

			function onCameraToggleClicked() {
				gtm?.trackEvent({
					category: "GoalVieww",
					action: "click",
					label: "cameraModeToggle",
					value: `${showsCameraChooserRef.value ? "off" : "on"}`,
				})
				showsCameraChooserRef.value = !showsCameraChooserRef.value
			}

			function onCameraModeChanged(mode: CameraMode) {
				gtm?.trackEvent({
					category: "GoalVieww",
					action: "click",
					label: "selectCameraMode",
					value: `${mode}`,
				})
				emit("update:cameraMode", mode)
			}

			function onFullscreenToggleClicked() {
				gtm?.trackEvent({
					category: "GoalVieww",
					action: "click",
					label: "toggleFullscreen",
					value: `${fullscreenController.isInFullscreen ? "exit" : "enter"}`,
				})
				if (fullscreenController.isInFullscreen)
					fullscreenController.exitFullscreen()
				else
					fullscreenController.enterFullscreen()
			}

			function onSoundToggleClicked() {
				gtm?.trackEvent({
					category: "GoalVieww",
					action: "click",
					label: "toggleSound",
					value: `${props.soundEnabled ? "off" : "on"}`,
				})
				emit("update:soundEnabled", !props.soundEnabled)
			}

			return {
				isInFullscreen: isInFullscreenRef,
				onCameraModeChanged,
				onCameraToggleClicked,
				onFullscreenToggleClicked,
				onSoundToggleClicked,
				showsCameraChooser: showsCameraChooserRef,
				showsFullscreenToggle: showsFullscreenToggleRef,
			}
		},
	})
