<template>
    <div class="background">
        <div v-for="(value, key) in statsRe.value" :key="key">
            <i>{{ key }}</i> <b>{{ value }}</b>
        </div>
    </div>
</template>

<script lang="ts">
    import LiveState from "@/live/model/LiveState"
    import { defineComponent, PropType, watchEffect, ref , computed, toRefs, onMounted, watch, reactive} from 'vue'
    

    export default defineComponent({
        props:{
            stats: { type: Object, required:true },
        },
        setup(props) {
            const statsRe = ref<Object>({})
      
            window.setInterval(()=>{
                statsRe.value = toRefs(props).stats
            },40)
            return { statsRe }
        },
    })
</script>

<style lang="scss" scoped>
    .background {
        background-color: rgba(0,0,0, 0.5);
        color: white;
        position:absolute;
        left:10px;
        bottom:70px;
        margin:0 auto;
        padding:5px;
    }
</style>

