import { useLocalization } from "@/i18n/VueLocalization"
import LiveFrame from "@/live/model/LiveFrame"
import LiveFrameSet from "@/live/model/LiveFrameSet"
import LivePositionsFrame from "@/live/model/LivePositionsFrame"
import LivePositionsFrameSet from "@/live/model/LivePositionsFrameSet"
import LiveState from "@/live/model/LiveState"
import LiveStateUpdate from "@/live/model/LiveStateUpdate"
import MatchPositions from "@/model/MatchPositions"
//import { EllipseCurve } from "three"


// FIXME ignore past timestamps
// FIXME log timestamp collisions
export default class LiveStateController {

	private _initialUpdate: LiveStateUpdate | null = null
	private _state: LiveState | null = null

	constructor(
		private readonly onState: (state: LiveState) => void,
	) {}
	
	add(frameSet: LiveFrameSet | LivePositionsFrameSet) {
		for (const frame of frameSet.frames){
			this.addFrame(frame)
		}
	}
	
	private addFrame(frame: LiveFrame | LivePositionsFrame) {
		
		if (frame instanceof LiveFrame)
			this.applyFrame(frame)
		else
			this.applyPositionsFrame(frame)
	}

	private applyFrame(frame: LiveFrame) {
		// FIXME Handle initial updates.
		if (this._state)
			this._state.applyFrame(frame)
		else {
			if (!this._initialUpdate)
				this._initialUpdate = frame.update
			else
				this._initialUpdate.apply(frame.update)

			const state = this._initialUpdate.toState(frame.timestamp) // create state here
			if (state) {
				this._state = state
				this.onState(state)
			}
		}
	}

	private applyPositionsFrame(frame: LivePositionsFrame) {
		// FIXME Handle initial updates.
		if (this._state)
			this._state.applyPositionsFrame(frame)
		else 
			console.warn('Cannot apply positions frame: State on LiveStateController not set')
	}
}
