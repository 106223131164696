export default interface VMMatchStatisticsValue {

	readonly formatted: string
	readonly value: number
}


export function VMMatchStatisticsValue(
	value: number,
	decimals: number = 0,
	suffix?: string,
): VMMatchStatisticsValue {
	return {
		get formatted() {
			const stringValue = value.toFixed(decimals) // FIXME Use $l for number formatting.

			return suffix ? `${stringValue}${suffix}` : stringValue
		},
		value,
	}
}
