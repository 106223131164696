<template>
  <div :class="$style.root">
    <div :class="$style.leagueTable">
      <div :class="$style.tableHead">
        <div :class="$style.row">
          <div :class="$style.cell" data-role="offset" />
          <div :class="$style.cell">{{ $l.standings_plays }}</div>
          <div :class="$style.cell">{{ $l.standings_goals }}</div>
          <div :class="$style.cell">{{ $l.standings_points }}</div>
        </div>
      </div>

      <div :class="$style.tableBody">
        <!-- FIXME: Change hard coded data by API response once it's done -->
        <div v-for="(item, index) in standings" :key="index" :class="$style.row">
          <div :class="$style.cell">
            <span :class="$style.position" :style="{backgroundColor: getTagHandle(index + 1), color: getTagHandle(index + 1) ? '#121212' : null}">
              {{ index + 1 }}
            </span>
          </div>
          <div :class="$style.cell">
            <img v-if="item.change === 'down'" src="/images/events/arrowDown.svg" />
            <img v-if="item.change === 'up'" src="/images/events/arrowUp.svg" />
            <div v-if="item.change === 'same'" :class="$style.samePosition" />
          </div>
          <div :class="[$style.cell, $style.largeCell]">
            <img src="/images/logos/bayern_logo.svg" />
            Borussia Dortmund
          </div>
          <div :class="$style.cell">1</div>
          <div :class="$style.cell">+4</div>
          <div :class="$style.cell">5</div>
        </div>
      </div>

    </div>

    <div :class="$style.legends">
      <p
        v-for="legend in legends"
        :key="legend.title"
        :class="$style.legend"
        :style="{ '--color': legend.color }"
      >
        {{ legend.title }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

// FIXME: Create a model once backend API is done
export interface Legend {
  title: string,
  color: string,
}

type Change = 'up' | 'same' | 'down'

export interface Standing {
  change: Change
}

export default defineComponent({
  props: {
    getTagHandle: { type: Function, required: true },
    legends: { type: Object as PropType<Legend[]>, required: true },
    standings: { type: Object as PropType<Standing[]>, required: true }
  }
})
</script>

<style lang="scss" module>
.cell {
  align-items: center;
  display: flex;
  font-weight: normal;
  font-size: 14px;
  justify-content: center;
}

.largeCell {
  justify-content: flex-start;
  img {
    margin-right: 8px;
    max-width: 20px;
    width: 100%;
  }
}

.leagueTable {
  margin-bottom: 16px;
  margin-top: 20px;
}

.legend {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: nor;
  line-height: 15px;
  margin-bottom: 2px;
  &::before {
    border-radius: 50%;
    background-color: var(--color);
    content: '';
    display: inline-block;
    height: 6px;
    margin-right: 7px;
    width: 6px;
  }
}

.legends {
  margin-left: 7px;
}

.position {
  border-radius: 50%;
  display: block;
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  width: 20px;
}

.root {
  margin-bottom: 38px;
}

.row {
  display: grid;
  padding: 4px 0;
  text-align: center;
}

.samePosition {
  background-color: #EDE5DC;
  height: 1.33px;
  width: 8px;
}

.tableBody .row {
  grid-template-columns: 1fr  1.3fr 7fr 2fr 2fr 1fr;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.tableHead .row {
  grid-template-columns: 9.3fr 2fr 2fr 1fr;
}
</style>
