import VLiveMatch from "@/live/VLiveMatch.vue"
import { createRouter, createWebHistory } from "vue-router"

const routes = [
	{
		path: "/",
		name: "LiveMatch",
		component: VLiveMatch,
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

export default router
