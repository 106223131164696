<template>
	<div :class="$style.root">
		<img :src="ballSpining" alt="football ball spining" />
	</div>
</template>

<script lang="ts">
	import { defineComponent } from "vue"
	import ballSpining from '@/assets/waitingFor2.gif'

	export default defineComponent({
		setup() {
			return { ballSpining }
		},
	})
</script>

<style lang="scss" module>
	.root {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
</style>
