import MatchPositions from "@/model/MatchPositions"

export default class CyclcicFramesBuffer {
    private array: Array<MatchPositions | null>
    private index = -1 // always points to newest elem
    private size: number
    private maxTimestamp: number
    private minTimestamp: number
    private lastIndexByTimestamp: number
    cnt = 0

    constructor(size: number){
        this.size = size
        this.array = new Array<MatchPositions | null>(this.size).fill(null)
        this.maxTimestamp = 0
        this.minTimestamp = 0
        this.lastIndexByTimestamp = -1
    }

    add(frame: MatchPositions){
        this.cnt+=1
        if (this.index == -1){
            // initialization
            this.minTimestamp = frame.timestamp
        }

        this.index = this.nextIndex()
        const nextIndex = this.nextIndex()
        if (this.array[nextIndex] instanceof MatchPositions){
            this.minTimestamp = this.array[nextIndex]!.timestamp
        }
        this.array[this.index] = frame
        this.maxTimestamp = frame.timestamp
    }

    newestFrame(){
        return this.array[this.index]
    }
    newestIndex(){
        return this.index
    }
    oldestFrame(){
        return this.array[this.oldestIndex()]
    }
    oldestIndex(){
        return this.array[this.nextIndex()] == null ? 0 : this.nextIndex()
    }

    getNextIndexByTimestamp(timestamp: number){

        // most common case first
        if (this.lastIndexByTimestamp > -1){
            try {
                if (this.array[(this.lastIndexByTimestamp + 1) % this.size]!.timestamp > timestamp && this.array[this.lastIndexByTimestamp]!.timestamp <= timestamp){
                    return [this.lastIndexByTimestamp, (this.lastIndexByTimestamp + 1) % this.size]
                }
                if (this.array[(this.lastIndexByTimestamp + 2) % this.size]!.timestamp > timestamp && this.array[(this.lastIndexByTimestamp + 1) % this.size]!.timestamp <= timestamp){
                    return [(this.lastIndexByTimestamp + 1) % this.size, (this.lastIndexByTimestamp + 2) % this.size]
                }
            }catch{
                this.lastIndexByTimestamp = -1
            }
        }
        
        if (timestamp < this.minTimestamp){
            let minTimestampIndex = this.nextIndex() 
            if (this.array[minTimestampIndex] == null) minTimestampIndex = 0
            this.lastIndexByTimestamp = minTimestampIndex
            return minTimestampIndex
        }
        if(timestamp >= this.maxTimestamp){
            this.lastIndexByTimestamp = this.index
            return this.index
        }

        // interval nesting
        let minIndex = this.oldestIndex()
        let maxIndex = this.newestIndex() + (minIndex < this.newestIndex() ? 0 : this.size)
        if (this.array[minIndex] == null) minIndex = 0
        let findPair = (minIdx, maxIdx) => {
            let midIdx = ~~((minIdx + maxIdx) / 2) // middle index
            if (this.array[midIdx % this.size]!.timestamp <= timestamp){
                if (midIdx + 1 == maxIdx)
                    return [midIdx, maxIdx]
                else
                    return findPair(midIdx, maxIdx)
            } else {
                if (minIdx + 1 == midIdx)
                    return [minIdx, midIdx]
                else 
                    return findPair(minIdx, midIdx)
            }
        }
        [minIndex, maxIndex] = findPair(minIndex, maxIndex)
        this.lastIndexByTimestamp = minIndex % this.size
        return [minIndex % this.size, maxIndex % this.size]
    }

    getByIndex(index: number){
        // support wrapped index
        return this.array[(this.size + index) % this.size]
    }

    private nextIndex(){
        return (this.index + 1) % this.size
    }
}