
	import { computed, defineComponent } from "vue"


	export default defineComponent({
		props: {
			color: { type: String, required: true },
			homeClubLogoUrl: { type: String, required: true },
			homeScore: { type: Number, required: true },
			isOwn: { type: Boolean, required: true },
			minuteOfPlay: { type: String, required: true },
			playerImageUrl: String,
			playerName: { type: String, required: true },
			awayClubLogoUrl: { type: String, required: true },
			awayScore: { type: Number, required: true },
		},
		setup(props) {
			const styleRef = computed(() => ({
				"--VMatchGoalNotification-awayClubLogoBackground": `url("${props.awayClubLogoUrl}")`,
				"--VMatchGoalNotification-color": props.color,
				"--VMatchGoalNotification-homeClubLogoBackground": `url("${props.homeClubLogoUrl}")`,
				"--VMatchGoalNotification-playerImageBackground": props.playerImageUrl ? `url("${props.playerImageUrl}")` : "none",
			}))

			return { style: styleRef }
		},
	})
