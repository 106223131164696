import LiveMessage from "@/live/model/LiveMessage"
import Fixture from "@/model/Fixture"


export default class WelcomeLiveMessage extends LiveMessage {

	constructor(
		readonly fixture: Fixture,
	) {
		super()
	}


	static fromJson(json: any) {
		return new this(
			Fixture.fromJson(json.fixture),
		)
	}
}
