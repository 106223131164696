import ResourceDisposer from "@/live/views/visualization/ResourceDisposer"
import MatchPositionsBall from "@/model/MatchPositionsBall"
import PitchSize from "@/model/PitchSize"
import { CircleGeometry, Group, Mesh, MeshBasicMaterial, Object3D, TextureLoader, Vector3 } from "three"
export default class {

	private readonly ball: Mesh
	private readonly container: Group
	private readonly disposer = new ResourceDisposer()

	constructor(textureLoader: TextureLoader) {
		const size = 2

		const container = new Group()
		container.renderOrder = 1

		// TODO Needs proper implementation. Maybe even dynamic shadow without image.
		// const shadow = new Mesh(
		// 	new PlaneGeometry(size * 2.5, size * 2.5),
		// 	new MeshBasicMaterial({
		// 		map: textureLoader.load("/images/shadow.png"),
		// 		transparent: false,
		// 	}),
		// )
		// container.add(shadow)

		const ball = new Mesh(
			new CircleGeometry(size / 2, 32),
			new MeshBasicMaterial({
				map: textureLoader.load("/images/football2.png"),
				transparent: false,
			}),
		)
		ball.translateZ(size / 2)
		container.add(ball)

		this.ball = ball
		this.container = container

		this.disposer.add(ball, container) // TODO shadow
	}


	addToParent(parent: Object3D) {
		parent.add(this.container)
	}


	dispose() {
		this.disposer.dispose()
	}


	faceCamera(position: Vector3) {
		this.ball.lookAt(position)
	}


	get position(): Vector3 {
		return this.container.position
	}


	removeFromParent() {
		this.container.removeFromParent()
	}


	get rootObject() {
		return this.container
	}


	update(state: MatchPositionsBall, pitchSize: PitchSize) {

		const x = state.x * pitchSize.x
		const y = state.y * pitchSize.y
		const z = state.z	
		
		this.container.position.set(x, y, z)
	}
}
