export default class MatchPositionsBall {

	constructor(
		readonly x: number,
		readonly y: number,
		readonly z: number,
	) {
		Object.freeze(this)
	}
}
