<template>
	<div :class="$style.root">
		<div :class="$style.left">
			<template v-if="item.leftRedCount || item.leftYellowCount">
				<div v-for="n in item.leftRedCount" :class="$style.redCard"/>
				<div v-for="n in item.leftYellowCount" :class="$style.yellowCard"/>
			</template>
			<template v-else>–</template>
		</div>
		<span :class="$style.label" v-text="$l.statistics_cards"/>
		<div :class="$style.right">
			<template v-if="item.rightRedCount || item.rightYellowCount">
				<div v-for="n in item.rightRedCount" :class="$style.redCard"/>
				<div v-for="n in item.rightYellowCount" :class="$style.yellowCard"/>
			</template>
			<template v-else>–</template>
		</div>
	</div>
</template>

<script lang="ts">
	import { VMMatchStatisticsCardComparisonItem } from "@/statistics/model/VMMatchStatisticsComparisonItem"
	import { defineComponent, PropType } from "vue"


	export default defineComponent({
		props: {
			item: { type: Object as PropType<VMMatchStatisticsCardComparisonItem>, required: true },
		},
	})
</script>

<style lang="scss" module>
	.label {
		align-items: center;
		display: flex;
		padding: 0 calc(8 * var(--scale));
	}

	.left {
		margin-left: calc(-4 * var(--scale));
	}

	.left, .right {
		align-items: center;
		display: flex;
		flex: 1;
		flex-wrap: wrap;
	}

	.redCard, .yellowCard {
		border-radius: calc(2 * var(--scale));
		height: calc(20 * var(--scale));
		margin: calc(4 * var(--scale));
		padding-left: calc(1.5 * var(--scale));
		width: calc(14 * var(--scale));
	}

	.redCard {
		background: #E92E2E;
	}

	.right {
		flex-direction: row-reverse;
		margin-right: calc(-4 * var(--scale));
	}

	.root {
		color: #EDE5DC;
		display: flex;
		font-size: calc(14 * var(--scale));
		min-height: calc(44 * var(--scale));
		justify-content: space-evenly;
		line-height: calc(36 * var(--scale));
		padding: calc(4 * var(--scale)) calc(16 * var(--scale));
	}

	.yellowCard {
		background: #F4CC3E;
	}
</style>
