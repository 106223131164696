
	import VLiveMatchTime from "@/live/components/VLiveMatchTime.vue"
	import LiveState from "@/live/model/LiveState"
	import { computed, defineComponent, PropType } from "vue"


	export default defineComponent({
		components: { VLiveMatchTime },
		props: {
			state: { type: Object as PropType<LiveState>, required: true },
		},
		setup(props) {
			const homeClub = computed(() => props.state.fixture.homeClub)
			const homeClubColor = computed(() => props.state.homeClub.shirtColors.primary)
			const awayClub = computed(() => props.state.fixture.awayClub)
			const awayClubColor = computed(() => props.state.awayClub.shirtColors.primary)
			const score = computed(() => {
				const state = props.state
				return `${state.score.home}:${state.score.away}`
			})

			return { awayClub, awayClubColor, homeClub, homeClubColor, score }
		},
	})
