<template>
	<div :class="$style.root">
		<div :class="$style.leagueHead">
			<!-- <img src="@/assets/bundesliga.svg" alt=""> -->
			<div>
				<h2>Bundesliga</h2>
				<p>{{ $l.caption_matchday }} 4</p>
			</div>
		</div>
		<v-standing-table :standings="standings" :legends="bundesligaLegends" :getTagHandle="getBundesligaTagColor"/>

		<div :class="$style.leagueHead">
			<!--      <img src="@/assets/bundesliga.svg" alt="">-->
			<div>
				<h2>2. Bundesliga</h2>
				<p>{{ $l.caption_matchday }} 4</p>
			</div>
		</div>
		<v-standing-table :standings="standings" :legends="bundesliga2Legends" :getTagHandle="getBundesliga2TagColor"/>
	</div>
</template>

<script lang="ts">
	import { useLocalization } from "@/i18n/VueLocalization"
	import { computed, defineComponent } from "vue"
	import VStandingTable, { Legend, Standing } from "./components/VStandingTable.vue"


	export default defineComponent({
		components: {
			VStandingTable,
		},
		setup() {
			const $l = useLocalization()
			// FIXME: Standings object should come from backend
			const changes: any = ["up", "same", "down"]
			const standings: Standing[] = Array(18).fill({}).map(s => ({
				change: changes[Math.floor(Math.random() * changes.length)],
			}))
			const bundesligaLegends = computed<Legend[]>(() => ([
				{ title: $l.standings_championsLeague, color: "#32CB8B" },
				{ title: $l.standings_euroLeague, color: "#32A6CB" },
				{ title: $l.standings_playOffs, color: "#F4CC3E" },
				{ title: $l.standings_relegationMatch, color: "#E92E2E" },
			]))
			const bundesliga2Legends = computed<Legend[]>(() => ([
				{ title: $l.standings_promoted, color: "#32CB8B" },
				{ title: $l.standings_relegationMatch, color: "#32A6CB" },
				{ title: $l.standings_relegated, color: "#E92E2E" },
			]))

			function getBundesligaTagColor(position: number): string | null {
				if (position >= 1 && position <= 4) {
					return "#32CB8B"
				}
				if (position === 5 || position === 6) {
					return "#32A6CB"
				}
				if (position === 16) {
					return "#F4CC3E"
				}
				if (position === 17 || position === 18) {
					return "#E92E2E"
				}
				return null
			}

			function getBundesliga2TagColor(position: number): string | null {
				if (position === 1 || position === 2) {
					return "#32CB8B"
				}
				if (position >= 3 && position <= 16) {
					return "#32A6CB"
				}
				if (position === 17 || position === 18) {
					return "#E92E2E"
				}
				return null
			}

			return {
				bundesligaLegends,
				bundesliga2Legends,
				getBundesligaTagColor,
				getBundesliga2TagColor,
				standings,
			}
		},
	})
</script>

<style lang="scss" module>
	.leagueHead {
		display: flex;
		align-items: center;

		img {
			margin-right: 13px;
			max-width: 45px;
			width: 100%;
		}

		h2 {
			font-weight: bold;
			font-size: 18px;
		}

		p {
			font-size: 14px;
		}
	}

	.root {
		overflow: auto;
		overflow: overlay;
		padding: 16px;
	}
</style>
