import Club from "@/model/Club"
import Competition from "@/model/Competition"
import Score from "@/model/Score"
import { error } from "@/utility"


export default class Fixture {

	constructor(
		readonly awayClub: Club,
		readonly competition: Competition,
		readonly homeClub: Club,
		readonly id: string,
		readonly score: Score | null,
		readonly startDateTime: string,
		readonly startTimestamp: Date,
		readonly timeZone: string,
	) {
		Object.freeze(this)
	}


	club(id: string): Club {
		switch (id) {
			case this.awayClub.id:
				return this.awayClub
			case this.homeClub.id:
				return this.homeClub
			default:
				error(`Invalid club ID: ${id}`)
		}
	}


	static fromJson(json: any) {
		return new this(
			Club.fromJson(json.awayClub),
			Competition.fromJson(json.competition),
			Club.fromJson(json.homeClub),
			json.id,
			json.score ? Score.fromJson(json.score) : null,
			json.startDateTime,
			new Date(json.startTimestamp),
			json.timeZone,
		)
	}
}
