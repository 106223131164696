<template>
	<a class="menu__button" href="#" @click.prevent="openMenu()" v-if="!expanded">
		<img alt="" src="@/assets/new/icon-menu.svg">
	</a>
	<div class="menu" ref="menuElement" :class="{expanded}">
		<div
			class="menu__container"
			ref="menu"
			v-if="!Object.values(menus).includes(true)"
		>
			<div class="menu__header" @click="closeMenu()">
				<div class="menu__close">
					<img src="@/assets/union.svg" alt="">
				</div>
				<p>{{ $l.menu_title }}</p>
			</div>
			<div class="menu__items">

				<div
					class="menu__item"
					:class="{disabled: !state}"
					@click="state && openSubMenu('stat')"
				>
					<div>
						<img src="@/assets/stat.svg" alt="">
					</div>
					<p>{{ $l.menu_statistics }}</p>
				</div>

				<div
					class="menu__item"
					:class="{disabled: !state}"
					@click="state && openSubMenu('homeSquad')"
				>
					<div>
						<img :src="fixture.homeClub.logoUrl" alt="">
					</div>
					<p>{{ fixture.homeClub.name }}</p>
				</div>

				<div
					class="menu__item"
					:class="{disabled: !state}"
					@click="state && openSubMenu('awaySquad')"
				>
					<div>
						<img :src="fixture.awayClub.logoUrl" alt="">
					</div>
					<p>{{ fixture.awayClub.name }}</p>
				</div>

				<div
					class="menu__item"
					:class="{disabled: !state}"
					@click="state && openSubMenu('line')"
				>
					<div>
						<img src="@/assets/timeline.svg" alt="">
					</div>
					<p>{{ $l.menu_timeline }}</p>
				</div>

				<!-- <div
					class="menu__item"
					:class="{disabled: !state}"
					@click="state && openSubMenu('standings')"
				>
					<div>
						<img src="@/assets/standings.svg" alt="">
					</div>
					<p>{{ $l.menu_standings }}</p>
				</div> -->

				<div
					class="menu__item"
					:class="{disabled: !fixture}"
					@click="fixture && openSubMenu('matches')"
				>
					<div>
						<img src="@/assets/stad.svg" alt="">
					</div>
					<p>{{ $l.menu_moreMatches }}</p>
				</div>

				<div class="menu__item" @click="openSubMenu('sound')" v-if="hasSound">
					<div>
						<img src="@/assets/sound.svg" alt="">
					</div>
					<p>{{ $l.menu_sound }}</p>
				</div>
			</div>
		</div>

		<div
			class="menu__container"
			ref="menu"
			:class="{ expandedMenu: expanded }"
			v-if="menus.stat"
		>
			<div class="menu__header" @click="closeSubMenu()">
				<div class="menu__close">
					<img src="@/assets/union_left.svg" alt="">
				</div>
				<p>{{ $l.menu_statistics }}</p>
			</div>
			<v-match-statistics-screen :state="state" @player-clicked="onPlayerClicked"/>
		</div>

		<div
			class="menu__container"
			ref="menu"
			:class="{ expandedMenu: expanded }"
			v-if="menus.line"
		>
			<div class="menu__header" @click="closeSubMenu()">
				<div class="menu__close">
					<img src="@/assets/union_left.svg" alt="">
				</div>
				<p>{{ $l.menu_timeline }}</p>
			</div>
			<v-match-timeline :state="state" @player-clicked="onPlayerClicked"/>
		</div>

		<div
			class="menu__container"
			ref="menu"
			:class="{ expandedMenu: expanded }"
			v-if="menus.homeSquad"
		>
			<div class="menu__header" @click="closeSubMenu()">
				<div class="menu__close">
					<img src="@/assets/union_left.svg" alt="">
				</div>
				<div class="menu__header-squad">
					<img :src="state?.fixture.homeClub.logoUrl" :alt="`${state?.fixture.homeClub} logo`">
					<p>{{ state?.fixture.homeClub.shortName }}</p>
				</div>
			</div>
			<v-match-squads-club :club="state?.homeClub" :players="state?.players" @player-clicked="onPlayerClicked"/>
		</div>

		<div
			class="menu__container"
			ref="menu"
			:class="{ expandedMenu: expanded }"
			v-if="menus.awaySquad"
		>
			<div class="menu__header" @click="closeSubMenu()">
				<div class="menu__close">
					<img src="@/assets/union_left.svg" alt="">
				</div>
				<div class="menu__header-squad">
					<img :src="state?.fixture.awayClub.logoUrl" :alt="`${state?.fixture.awayClub} logo`">
					<p>{{ state?.fixture.awayClub.shortName }}</p>
				</div>
			</div>
			<v-match-squads-club :club="state?.awayClub" :players="state?.players" @player-clicked="onPlayerClicked"/>
		</div>

		<div
			class="menu__container"
			ref="menu"
			:class="{ expandedMenu: expanded }"
			v-if="menus.player"
		>
			<div class="menu__header" @click="closeSubMenu()">
				<div class="menu__close">
					<img src="@/assets/union_left.svg" alt="">
				</div>
				<p>{{ $l.menu_playerStatistic }}</p>
			</div>
			<v-match-player-statistics v-if="playerId" :state="state" :playerId="playerId"/>
		</div>

		<div
			class="menu__container menuMatches"
			ref="menu"
			:class="{ expandedMenu: expanded }"
			v-if="menus.matches"
		>
			<div class="menu__header" @click="closeSubMenu()">
				<div class="menu__close">
					<img src="@/assets/union_left.svg" alt="">
				</div>
				<p>{{ $l.menu_moreMatches }}</p>
			</div>
			<Suspense>
				<template #default>
					<v-match-list :fixture="fixture"/>
				</template>
				<template #fallback>
					<div class="loading">
						<img src="/images/loading_bar.gif" alt="loading"/>
					</div>
				</template>
			</Suspense>
		</div>

		<div
			class="menu__container menuMatches"
			ref="menu"
			:class="{ expandedMenu: expanded }"
			v-if="menus.standings"
		>
			<div class="menu__header" @click="closeSubMenu()">
				<div class="menu__close">
					<img src="@/assets/union_left.svg" alt="">
				</div>
				<p>{{ $l.menu_standings }}</p>
			</div>
			<v-standings/>
		</div>

		<div
			class="menu__container soundControls"
			ref="menu"
			:class="{ expandedMenu: expanded }"
			v-if="menus.sound"
		>
			<div class="menu__header" @click="closeSubMenu()">
				<div class="menu__close">
					<img src="@/assets/union_left.svg" alt="">
				</div>
				<p>{{ $l.menu_sound }}</p>
			</div>
			<v-sound-screen :volume="soundVolume" @update:volume="onSoundVolumeChanged"/>
		</div>
	</div>
</template>

<script lang="ts">
	import LiveState from "@/live/model/LiveState"
	import VMatchList from "@/menu/VMatchList.vue"
	import VMatchPlayerStatistics from "@/menu/VMatchPlayerStatistics.vue"
	import VMatchSquadsClub from "@/menu/VMatchSquadsClub.vue"
	import VMatchStatisticsScreen from "@/menu/VMatchStatisticsScreen.vue"
	import VMatchTimeline from "@/menu/VMatchTimeline.vue"
	import VSoundScreen from "@/menu/VSoundScreen.vue"
	import VStandings from "@/menu/VStandings.vue"
	import Fixture from "@/model/Fixture"
	import { computed, defineComponent, PropType, ref } from "vue"
	import { useGtm } from "vue-gtm"


	export default defineComponent({
		props: {
			fixture: { type: Object as PropType<Fixture>, required: true },
			hasSound: { type: Boolean, required: true },
			state: Object as PropType<LiveState>,
			soundVolume: { type: Number, required: true },
		},
		emits: {
			"update:soundVolume": (_: number) => true,
		},
		components: {
			VMatchList,
			VMatchPlayerStatistics,
			VMatchSquadsClub,
			VMatchStatisticsScreen,
			VMatchTimeline,
			VSoundScreen,
			VStandings,
		},
		setup(props, { emit }) {
			const gtm = useGtm()
			const menuElement = ref(null)
			const expanded = ref(false)
			const menuStack = ref<string[]>([])
			const menus = computed(() => {
				const initialState = {
					awaySquad: false,
					homeSquad: false,
					line: false,
					matches: false,
					player: false,
					sound: false,
					standings: false,
					stat: false,
				}

				if (!menuStack.value.length) {
					return initialState
				}
				initialState[menuStack.value[menuStack.value.length - 1]] = true
				return initialState
			})

			const playerId = ref<string | null>()

			const openMenu = () => {
				gtm?.trackEvent({
					category: "GoalVieww",
					action: "click",
					label: "openMenu",
				})
				expanded.value = true
			}
			const closeMenu = () => {
				expanded.value = false
			}
			const openSubMenu = (item) => {
				gtm?.trackEvent({
					category: "GoalVieww",
					action: "click",
					label: `openSubMenu_${item}`,
				})
				expanded.value = true
				if (menuStack.value.includes(item)) return
				menuStack.value.push(item)
			}
			const closeSubMenu = () => {
				menuStack.value.pop()
			}

			function onPlayerClicked(id: string) {
				const player = props.state?.players.get(id)
				const playerName = player?.shortName
				const playerClubId = !!player ? props.state?.fixture.club(player.clubId).code : ""
				gtm?.trackEvent({
					category: "GoalVieww",
					action: "click",
					label: `onPlayerClicked`,
					value: `${playerName} - ${playerClubId}`,
				})
				presentStatisticsOfPlayer(id)
			}

			function onSoundVolumeChanged(volume: number) {
				emit("update:soundVolume", volume)
			}

			function presentStatisticsOfPlayer(id: string) {
				playerId.value = id

				openSubMenu("player")
			}


			return {
				closeMenu,
				closeSubMenu,
				expanded,
				menuElement,
				menuStack,
				menus,
				onPlayerClicked,
				onSoundVolumeChanged,
				openMenu,
				openSubMenu,
				playerId,
				presentStatisticsOfPlayer,
			}
		},
	})
</script>

<style lang="scss" scoped>
	.menu {
		background: radial-gradient(101.99% 115.71% at 50% 117.08%, #00C5CF 0%, rgba(0, 197, 207, 0) 100%);
		bottom: 0;
		display: flex;
		justify-content: flex-end;
		max-width: 100%;
		overflow: hidden;
		position: fixed;
		right: 0;
		top: 0;
		transition: transform .4s ease-in;
		transform: translateX(100%);
		width: 380px;
		z-index: 150;

		&.expanded {
			transform: none;
		}

		&__button {
			padding: 8px;
			position: absolute;
			right: calc(8px + env(safe-area-inset-right, 0px));
			top: calc(8px + env(safe-area-inset-top, 0px));
			user-select: none;
			z-index: 150;

			& > img {
				display: block;
				height: 32px;
				width: 32px;
			}

			@media (max-width: 850px) {
				padding: 4px;
				right: env(safe-area-inset-right, 0);
				top: env(safe-area-inset-top, 0);
			}
		}

		&__container {
			align-content: flex-start;
			background: rgba(18, 18, 42, 0.95);
			box-shadow: inset 0 0 60px 16px rgba(#000, .75);
			display: grid;
			height: 100%;
			left: 0;
			min-height: 100%;
			max-height: 100%;
			position: absolute;
			width: 100%;
		}

		&__header {
			align-items: center;
			background: #3D003B;
			border: 1px solid rgba(#3D003B, 0.25);
			color: #FFF;
			cursor: pointer;
			display: flex;
			flex-shrink: 0;
			font-size: 28px;
			height: 56px;
			justify-content: flex-start;
			text-transform: uppercase;
			transition: .2s;
			user-select: none;
			width: 100%;

			p {
				font-size: 24px;
			}

			&-squad {
				display: flex;

				img {
					height: 32px;
					margin-right: 8px;
					width: 32px;
				}
			}

			&:hover {
				padding-left: 20px;
				box-shadow: inset 0 0 20px rgba(78, 196, 204, 0.43);
			}
		}

		&__close {
			padding: 0 20px;
		}

		&__items {
			padding: 0 10px calc(10px + env(safe-area-inset-bottom, 0px)) 10px;
			overflow-y: auto;
			overflow-y: overlay;
		}

		&__item {
			width: 100%;
			height: 50px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			border-bottom: 2px solid #FFFFFF40;;
			cursor: pointer;
			transition: .2s;

			img {
				max-width: 100%;
			}

			& > div {
				width: 30px;
				margin-right: 20px;
				padding-left: 10px;
				box-sizing: content-box;
			}

			& > p {
				font-size: 16px;
				color: #FFF;
			}

			&:hover {
				padding-left: 20px;
			}

			&.disabled {
				&:hover {
					padding-left: 0;
				}

				p {
					color: grey;
				}

				cursor: initial;
			}
		}
	}

	.menuMatches {
		display: flex;
		flex-direction: column;
	}

	.loading {
		align-items: center;
		display: flex;
		flex-grow: 1;
		justify-content: center;
	}
</style>
