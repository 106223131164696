<template>
	<div :class="$style.root">
		<h2>{{ $l.caption_radio }}</h2>
		<div :class="$style.soundControl">
			<img :src="soundIcon" alt="">
			<div :class="$style.slidecontainer">
				<span :style="{ width: `${volume * 100}%` }"></span>
				<input
					:class="$style.slider"
					max="1"
					min="0"
					step="0.01"
					type="range"
					:value="volume"
					@input="onInput"
				>
			</div>
			<span>{{ Math.round(volume * 100) }}%</span>
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from '@vue/runtime-core'
	import soundIcon from '@/assets/soundRadio.svg'

	export default defineComponent({
		props: {
			volume: { type: Number, required: true },
		},
		emits: {
			"update:volume": (_: number) => true,
		},
		setup(props, { emit }) {
			function onInput(event: InputEvent) {
				emit("update:volume", Number.parseFloat((event.target as HTMLInputElement).value))
			}

			return { onInput, soundIcon }
		},
	})
</script>

<style lang="scss" module>
.root {
	padding: 16px;
	h2 {
		font-weight: normal;
		font-size: 14px;
	}
}

.slidecontainer {
	flex: 1;
	height: 4px;
	margin: 0 18px;
	position: relative;
	span {
		background-color: #32CB8B;
		border-radius: 5px;
		height: 4px;
		position: absolute;
	}
}

.slider {
	-webkit-appearance: none;
	background: #ede5dc61;
	border-radius: 5px;
	height: 4px;
	outline: none;
	position: absolute;
	top: -2px;
	width: 100%;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	background: #EDE5DC;
	border-radius: 50%;
	cursor: pointer;
	height: 12px;
	opacity: 1 !important;
	width: 12px;
}

.slider::-moz-range-thumb {
	background: #EDE5DC;
	border-radius: 50%;
	cursor: pointer;
	height: 12px;
	opacity: 1 !important;
	width: 12px;
}

.soundControl {
	align-items: center;
	display: flex;
	margin-top: 12px;
	span {
		font-size: 14px;
		font-weight: bold;
	}
}

</style>
