import MatchEventType from "@/model/MatchEventType"


export default class MatchEvent<Type extends MatchEventType = MatchEventType> {

	constructor(
		readonly id: string,
		readonly timestamp: number, // FIXME Use Date?
		readonly type: Type,
	) {
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.id,
			json.timestamp,
			MatchEventType.fromJson(json.type),
		)
	}
}
