import LiveFrameSet from "@/live/model/LiveFrameSet"
import LiveMessage from "@/live/model/LiveMessage"


export default class FrameSetLiveMessage extends LiveMessage {

	constructor(
		readonly value: LiveFrameSet,
	) {
		super()
	}


	static fromJson(json: any) {
		return new this(
			LiveFrameSet.fromJson(json.value),
		)
	}
}
