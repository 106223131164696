
	import LiveState from "@/live/model/LiveState"
	import VMatchList from "@/menu/VMatchList.vue"
	import VMatchPlayerStatistics from "@/menu/VMatchPlayerStatistics.vue"
	import VMatchSquadsClub from "@/menu/VMatchSquadsClub.vue"
	import VMatchStatisticsScreen from "@/menu/VMatchStatisticsScreen.vue"
	import VMatchTimeline from "@/menu/VMatchTimeline.vue"
	import VSoundScreen from "@/menu/VSoundScreen.vue"
	import VStandings from "@/menu/VStandings.vue"
	import Fixture from "@/model/Fixture"
	import { computed, defineComponent, PropType, ref } from "vue"
	import { useGtm } from "vue-gtm"


	export default defineComponent({
		props: {
			fixture: { type: Object as PropType<Fixture>, required: true },
			hasSound: { type: Boolean, required: true },
			state: Object as PropType<LiveState>,
			soundVolume: { type: Number, required: true },
		},
		emits: {
			"update:soundVolume": (_: number) => true,
		},
		components: {
			VMatchList,
			VMatchPlayerStatistics,
			VMatchSquadsClub,
			VMatchStatisticsScreen,
			VMatchTimeline,
			VSoundScreen,
			VStandings,
		},
		setup(props, { emit }) {
			const gtm = useGtm()
			const menuElement = ref(null)
			const expanded = ref(false)
			const menuStack = ref<string[]>([])
			const menus = computed(() => {
				const initialState = {
					awaySquad: false,
					homeSquad: false,
					line: false,
					matches: false,
					player: false,
					sound: false,
					standings: false,
					stat: false,
				}

				if (!menuStack.value.length) {
					return initialState
				}
				initialState[menuStack.value[menuStack.value.length - 1]] = true
				return initialState
			})

			const playerId = ref<string | null>()

			const openMenu = () => {
				gtm?.trackEvent({
					category: "GoalVieww",
					action: "click",
					label: "openMenu",
				})
				expanded.value = true
			}
			const closeMenu = () => {
				expanded.value = false
			}
			const openSubMenu = (item) => {
				gtm?.trackEvent({
					category: "GoalVieww",
					action: "click",
					label: `openSubMenu_${item}`,
				})
				expanded.value = true
				if (menuStack.value.includes(item)) return
				menuStack.value.push(item)
			}
			const closeSubMenu = () => {
				menuStack.value.pop()
			}

			function onPlayerClicked(id: string) {
				const player = props.state?.players.get(id)
				const playerName = player?.shortName
				const playerClubId = !!player ? props.state?.fixture.club(player.clubId).code : ""
				gtm?.trackEvent({
					category: "GoalVieww",
					action: "click",
					label: `onPlayerClicked`,
					value: `${playerName} - ${playerClubId}`,
				})
				presentStatisticsOfPlayer(id)
			}

			function onSoundVolumeChanged(volume: number) {
				emit("update:soundVolume", volume)
			}

			function presentStatisticsOfPlayer(id: string) {
				playerId.value = id

				openSubMenu("player")
			}


			return {
				closeMenu,
				closeSubMenu,
				expanded,
				menuElement,
				menuStack,
				menus,
				onPlayerClicked,
				onSoundVolumeChanged,
				openMenu,
				openSubMenu,
				playerId,
				presentStatisticsOfPlayer,
			}
		},
	})
