
	import { VMMatchStatisticsPlayerComparisonItem } from "@/statistics/model/VMMatchStatisticsComparisonItem"
	import { computed, defineComponent, PropType } from "vue"


	export default defineComponent({
		props: {
			item: { type: Object as PropType<VMMatchStatisticsPlayerComparisonItem>, required: true },
		},
		setup(props, { emit }) {
			const styleRef = computed(() => ({
				"--VMatchStatisticsPlayerComparisonItem-leftPlayerImageBackground":
					props.item.leftPlayerImageUrl ? `url("${props.item.leftPlayerImageUrl}")` : "none",
				"--VMatchStatisticsPlayerComparisonItem-rightPlayerImageBackground":
					props.item.rightPlayerImageUrl ? `url("${props.item.rightPlayerImageUrl}")` : "none",
			}))

			const onPlayerClicked = (id: string) => {
				emit("playerClicked", id)
			}

			return { style: styleRef, onPlayerClicked }
		},
	})
