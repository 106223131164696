import Localization from "@/i18n/Localization"


export default class Player {

	constructor(
		readonly clubId: string,
		readonly dateOfBirth: string | null,
		readonly faceImageUrl: string | null,
		readonly height: number | null, // cm
		readonly id: string,
		readonly name: string,
		readonly nationalityLabel: string | null,
		readonly playingPosition: string | null,
		readonly shirtNumber: number | null,
		readonly shortName: string,
		readonly weight: number | null, // kg
	) {
		Object.freeze(this)
	}


	playingPositionLabel($l: Localization): string | null {
		if (!this.playingPosition)
			return null

		switch (this.playingPosition) {
			case "defense":
				return $l.playingPosition_defense
			case "goalkeeper":
				return $l.playingPosition_goalkeeper
			case "midField":
				return $l.playingPosition_midField
			case "offense":
				return $l.playingPosition_offense
			default:
				return this.playingPosition
		}
	}


	static fromJson(json: any) {
		return new this(
			json.clubId,
			json.dateOfBirth,
			json.faceImageUrl,
			json.height,
			json.id,
			json.name,
			json.nationalityLabel,
			json.playingPosition,
			json.shirtNumber,
			json.shortName,
			json.weight,
		)
	}
}
