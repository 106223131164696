import LiveMessage from "@/live/model/LiveMessage"
import LivePositionsBall from "@/live/model/LivePositionsBall"
import LivePositionsFrame from "@/live/model/LivePositionsFrame"
import LivePositionsFrameSet from "@/live/model/LivePositionsFrameSet"
import LivePositionsPlayer from "@/live/model/LivePositionsPlayer"


export default class PositionsFrameSetLiveMessage extends LiveMessage {

	constructor(
		readonly value: LivePositionsFrameSet,
	) {
		super()
	}


	static fromBinary(view: DataView): PositionsFrameSetLiveMessage {
		let offset = 0

		const timestampMs = view.getUint32(offset) * 1_000
		offset += 4

		const frameCount = view.getUint8(offset++)
		const frames = new Array<LivePositionsFrame>(frameCount)
		for (let frameIndex = 0; frameIndex < frameCount; ++frameIndex) {
			const timestampOffset = view.getUint16(offset)
			offset += 2

			const ballX = view.getInt16(offset) / 32766 * 1.05
			offset += 2
			const ballY = view.getInt16(offset) / 32766 * 1.05
			offset += 2
			const ballZ = view.getUint16(offset) / 1_000
			offset += 2

			const playerCount = view.getUint8(offset++)

			const players = new Array<LivePositionsPlayer>(playerCount)
			for (let index = 0; index < playerCount; ++index) {
				const x = view.getInt16(offset) / 32766 * 1.05
				offset += 2
				const y = view.getInt16(offset) / 32766 * 1.05
				offset += 2

				players[index] = new LivePositionsPlayer(x, y)
			}

			frames[frameIndex] = new LivePositionsFrame(
				new LivePositionsBall(ballX, ballY, ballZ),
				players,
				timestampMs + timestampOffset,
			)
		}

		return new PositionsFrameSetLiveMessage(new LivePositionsFrameSet(frames))
	}
}
