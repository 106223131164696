import Localization from "@/i18n/Localization"


type Card = "red" | "yellow" | "yellowRed"

export default Card


export function cardLabel(card: Card, $l: Localization): string {
	switch (card) {
		case "red":
			return $l.event_redCard
		case "yellow":
			return $l.event_yellowCard
		case "yellowRed":
			return $l.event_yellowRedCard
	}
}
