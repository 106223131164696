
	import { useLocalization } from "@/i18n/VueLocalization"
	import LiveState from "@/live/model/LiveState"
	import MatchEvent from "@/model/MatchEvent"
	import { GoalMatchEventType } from "@/model/MatchEventType"
	import { VMMatchStatistics } from "@/statistics/model/VMMatchStatistics"
	import VMatchStatistics from "@/statistics/views/VMatchStatistics.vue"
	import { computed, defineComponent, onBeforeUnmount, PropType, ref } from "vue"


	export default defineComponent({
		components: { VMatchStatistics },
		props: {
			state: { type: Object as PropType<LiveState>, required: true },
		},
		setup(props, { emit }) {
			const $l = useLocalization()

			const awayClubRef = computed(() => props.state.fixture.awayClub)
			const currentTimestampRef = ref(Date.now()) // FIXME use live clock
			const homeClubRef = computed(() => props.state.fixture.homeClub)
			const playersRef = computed(() => props.state.players)
			const scoreRef = computed(() => props.state.score)
			const statisticsRef = computed(() => props.state.statistics ? VMMatchStatistics(props.state.statistics, props.state.players, $l) : null)
			const statusRef = computed(() => props.state.status)
			const styleRef = computed(() => ({
				"--VMatchAfterSectionEnd-awayClubLogoBackground": `url("${awayClubRef.value.logoUrl}")`,
				"--VMatchAfterSectionEnd-homeClubLogoBackground": `url("${homeClubRef.value.logoUrl}")`,
			}))

			const interval = setInterval(() => currentTimestampRef.value = Date.now(), 1_000) // FIXME use live clock
			onBeforeUnmount(() => clearInterval(interval))

			const countdownRef = computed(() => {
				let targetTimestamp: number
				switch (statusRef.value) {
					case "beforeMatch":
					case "inFirstHalf":
					case "inSecondHalf":
					case "afterMatch":
						return null

					case "beforeSecondHalf":
						targetTimestamp = props.state.timing.secondHalfKickoffTimestamp.getTime()
				}

				let seconds = (targetTimestamp - currentTimestampRef.value) / 1_000
				if (seconds <= 0) {
					clearInterval(interval)

					return null
				}

				const secondsPerMinute = 60
				const minutes = Math.floor(seconds / secondsPerMinute)
				seconds = Math.floor(seconds % secondsPerMinute)

				return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
			})

			const goalsRef = computed(() => {
				const state = props.state
				const events = state.goalEventsByScoringSide

				return { away: prepareGoalEvents(events.away, state), home: prepareGoalEvents(events.home, state) }
			})


			function prepareGoalEvents(events: ReadonlyArray<MatchEvent<GoalMatchEventType>>, state: LiveState) {
				return events.map(event => ({
					id: event.id,
					isOwn: event.type.isOwn,
					minuteOfPlay: state.formattedMinuteOfPlay(event.timestamp) + "’",
					playerName: playersRef.value.get(event.type.playerId)?.shortName ?? "?",
				}))
			}

			const onPlayerClicked = (id: string) => {
				emit("playerClicked", id)
			}

			return {
				awayClub: awayClubRef,
				countdown: countdownRef,
				goals: goalsRef,
				homeClub: homeClubRef,
				score: scoreRef,
				statistics: statisticsRef,
				status: statusRef,
				style: styleRef,
				onPlayerClicked,
			}
		},
	})
