import { onBeforeMount, onBeforeUnmount, Ref, ref } from "vue"


export function usePageVisibility(): Ref<"hidden" | "visible"> {
	const valueRef = ref(document.visibilityState)
	const listener = () => valueRef.value = document.visibilityState

	onBeforeMount(() => document.addEventListener("visibilitychange", listener))
	onBeforeUnmount(() => document.removeEventListener("visibilitychange", listener))

	return valueRef
}
