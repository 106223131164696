import LiveMessage from "@/live/model/LiveMessage"
import ByeLiveMessage from "@/live/model/messages/ByeLiveMessage"
import FrameSetLiveMessage from "@/live/model/messages/FrameSetLiveMessage"
import UnknownLiveMessage from "@/live/model/messages/UnknownLiveMessage"
import WelcomeLiveMessage from "@/live/model/messages/WelcomeLiveMessage"


export default function parseLiveMessage(json: any): LiveMessage {
	const typeId = json.__type
	switch (typeId) {
		case "Bye":
			return ByeLiveMessage.fromJson(json)
		case "FrameSet":
			return FrameSetLiveMessage.fromJson(json)
		case "Welcome":
			return WelcomeLiveMessage.fromJson(json)
		default:
			return UnknownLiveMessage.fromJson(json)
	}
}
