import Localization from "@/i18n/Localization"


const en: Localization = {
	caption_halfTime: "Halftime",
	caption_matchContinuesAnyMoment: "Match continues any moment…",
	caption_matchContinuesIn: "next in",
	caption_matchday: "Matchday",
	caption_matchHasEnded: "Match has ended",
	caption_matchStartsAnyMoment: "Match starts any moment…",
	caption_matchStartsIn: "Match starts in…",
	caption_statisticsAreAvailableShortly: "Statistics are available shortly.",
	caption_statisticsAreNotAvailable: "Statistics are not available for this match.",
	caption_out: "out",
	caption_radio: "Radio",

	event_cornerKick: "Corner Kick",
	event_foul: "Foul",
	event_freeKick: "Free Kick",
	event_goal: "Goal",
	event_offside: "Offside",
	event_ownGoal: "Own Goal",
	event_penaltyKick: "Penalty Kick",
	event_redCard: "Red Card",
	event_throwIn: "Throw-in",
	event_yellowCard: "Yellow Card",
	event_yellowRedCard: "Yellow/Red Card",
	event_substitution: "Substitution",

	menu_moreMatches: "More Matches",
	menu_playerStatistic: "Player Statistics",
	menu_sound: "Sound",
	menu_standings: "Standings",
	menu_statistics: "Statistics",
	menu_timeline: "Timeline",
	menu_title: "Menu",

	player_age: "Age",
	player_height: "Height",
	player_information: "Basic information",
	player_matchInformation: "Match information",
	player_nationality: "Nationality",
	player_weight: "Weight",

	playingPosition_defense: "Defense",
	playingPosition_goalkeeper: "Goalkeeper",
	playingPosition_midField: "Midfield",
	playingPosition_offense: "Offense",

	squad_bench: "On the bench",
	squad_field: "On the field",

	standings_championsLeague: "Champions League",
	standings_euroLeague: "Euro League",
	standings_goals: "Goals",
	standings_playOffs: "Playoffs",
	standings_plays: "Plays",
	standings_points: "Pts",
	standings_promoted: "Promoted",
	standings_relegated: "Relegated",
	standings_relegationMatch: "Relegation match",

	statistics_ballContacts: "Ball contacts",
	statistics_ballPossession: "Ball possession",
	statistics_bestDuelSuccessRate: "Most duels won",
	statistics_bestPassCompletionRate: "Best pass completion",
	statistics_cards: "Cards",
	statistics_cornerKicks: "Corner kicks",
	statistics_distanceCovered: "Distance covered",
	statistics_duelSuccess: "Duels won",
	statistics_fouls: "Fouls",
	statistics_goalAssists: "Goals assists",
	statistics_goalShotAssistCount: "Shot on target assists",
	statistics_goalShotCount: "Shots on target",
	statistics_goals: "Goals",
	statistics_mostShotsAndOnTarget: "Most shots (on target)",
	statistics_passCompletion: "Pass completion",
	statistics_playDuration: "Minutes played",
	statistics_redCard: "Red Card",
	statistics_shotCount: "Total shots",
	statistics_shotOnTargetAssists: "Shot on target assists",
	statistics_shotsAndOnTarget: "Shots (on target)",
	statistics_yellowCard: "Yellow Card",
	statistics_yellowRedCard: "Yellow/Red Card",

	timeline_finalWhistle: "Final whistle",
	timeline_halfTime: "Half-time",
	timeline_kickOff: "Start",

	unit_days: "Days",
	unit_hours: "Hours",
	unit_minutes: "Minutes",
	unit_seconds: "Seconds",
}

export default en
