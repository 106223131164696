import Card from "./Card"

export default class MatchPlayerStatistics {

	constructor(
		readonly id: string,
		readonly ballContactCount: number,
		readonly cardCountRed: number,
		readonly cardCountYellow: number,
		readonly cardCountYellowRed: number,
		readonly distanceCovered: number,
		readonly duelSuccessCount: number,
		readonly duelSuccessRate: number,
		readonly foulCount: number,
		readonly goalAssistCount: number,
		readonly goalCount: number,
		readonly goalShotAssistCount: number,
		readonly goalShotCount: number,
		readonly passCompletionCount: number,
		readonly passCompletionRate: number,
		readonly playDuration: number,
		readonly shotCount: number,
	) {
		Object.freeze(this)
	}


	// FIXME Remove.
	formatStat({
		           absoluteValue,
							 card,
		           percentageValue,
		           divisor = 1,
		           unitOfMeasurement = "",
							 title,
	           }: FormatEntry): StatItem {
		if (percentageValue) {
			return {
				card,
				mainValue: `${Math.round(percentageValue * 100)}%`,
				secondaryValue: absoluteValue.toString(),
				title,
			}
		}
		return {
			card,
			mainValue: `${Math.round(absoluteValue / divisor)}${unitOfMeasurement}`,
			secondaryValue: null,
			title,
		}
	}


	static fromJson(json: any): MatchPlayerStatistics {
		return new this(
			json.id,
			json.ballContactCount,
			json.cardCountRed,
			json.cardCountYellow,
			json.cardCountYellowRed,
			json.distanceCovered,
			json.duelSuccessCount,
			json.duelSuccessRate,
			json.foulCount,
			json.goalAssistCount,
			json.goalCount,
			json.goalShotAssistCount,
			json.goalShotCount,
			json.passCompletionCount,
			json.passCompletionRate,
			json.playDuration,
			json.shotCount,
		)
	}
}

interface StatItem {
	card: Card|undefined,
	title: string,
	mainValue: string
	secondaryValue: string | null
}

interface FormatEntry {
	absoluteValue: number,
	card?: Card,
	percentageValue?: number,
	divisor?: number,
	unitOfMeasurement?: string,
	title: string
}
