import LiveStateUpdate from "@/live/model/LiveStateUpdate"


export default class LiveFrame {

	constructor(
		readonly timestamp: number,
		readonly update: LiveStateUpdate,
	) {}


	static fromJson(json: any) {
		return new this(
			json.timestamp,
			LiveStateUpdate.fromJson(json.update),
		)
	}
}
