import Localization from "@/i18n/Localization"


const de: Localization = {
	caption_halfTime: "Halbzeit",
	caption_matchContinuesAnyMoment: "Es geht jeden Moment weiter…",
	caption_matchContinuesIn: "weiter in",
	caption_matchday: "Spieltag",
	caption_matchHasEnded: "Spiel ist beendet",
	caption_matchStartsAnyMoment: "Spiel startet jeden Moment…",
	caption_matchStartsIn: "Spiel startet in…",
	caption_statisticsAreAvailableShortly: "Statistiken sind in Kürze verfügbar.",
	caption_statisticsAreNotAvailable: "Für dieses Match sind keine Statistiken verfügbar.",
	caption_out: "aus",
	caption_radio: "Radio",

	event_cornerKick: "Eckball",
	event_foul: "Foul",
	event_freeKick: "Freistoß",
	event_goal: "Tor",
	event_offside: "Abseits",
	event_ownGoal: "Eigentor",
	event_penaltyKick: "Elfmeter",
	event_redCard: "Rote Karte",
	event_throwIn: "Einwurf",
	event_yellowCard: "Gelbe Karte",
	event_yellowRedCard: "Gelb-Rote Karte",
	event_substitution: "Auswechslung",

	menu_moreMatches: "Weitere Spiele",
	menu_playerStatistic: "Spielerstatistik",
	menu_sound: "Klang",
	menu_standings: "Tabellenplatz",
	menu_statistics: "Statistiken",
	menu_timeline: "Spielverlauf",
	menu_title: "Menü",

	player_age: "Alter",
	player_height: "Höhe",
	player_information: "Grundinformation",
	player_matchInformation: "Spielinformationen",
	player_nationality: "Nationalität",
	player_weight: "Gewicht",

	playingPosition_defense: "Verteidigung",
	playingPosition_goalkeeper: "Torwart",
	playingPosition_midField: "Mittelfeld",
	playingPosition_offense: "Angriff",

	squad_bench: "Auf der Bank",
	squad_field: "Auf dem Feld",

	standings_championsLeague: "Champions League",
	standings_euroLeague: "Euroliga",
	standings_goals: "Ziele",
	standings_playOffs: "Playoffs",
	standings_plays: "Theaterstücke",
	standings_points: "Punkte",
	standings_promoted: "Gefördert",
	standings_relegated: "Absteiger",
	standings_relegationMatch: "Relegationsspiel",

	statistics_ballContacts: "Ballkontakte",
	statistics_ballPossession: "Ballbesitz",
	statistics_bestDuelSuccessRate: "Beste Zweikampfquote",
	statistics_bestPassCompletionRate: "Beste Passquote",
	statistics_cards: "Karten",
	statistics_cornerKicks: "Ecken",
	statistics_distanceCovered: "Laufleistung",
	statistics_duelSuccess: "Zweikampfquote",
	statistics_fouls: "Fouls",
	statistics_goalAssists: "Torvorlagen",
	statistics_goalShotAssistCount: "Torschussvorlagen",
	statistics_goalShotCount: "Schüsse aufs Tor",
	statistics_goals: "Tore",
	statistics_mostShotsAndOnTarget: "Meiste Schüsse (aufs Tor)",
	statistics_passCompletion: "Passquote",
	statistics_playDuration: "Gespielte Minuten",
	statistics_redCard: "Rote Karten",
	statistics_shotCount: "Schüsse insgesamt",
	statistics_shotOnTargetAssists: "Torschussvorlagen",
	statistics_shotsAndOnTarget: "Schüsse (aufs Tor)",
	statistics_yellowCard: "Gelbe Karten",
	statistics_yellowRedCard: "Gelbe/Rote Karten",

	timeline_finalWhistle: 'Abpfiff',
	timeline_halfTime: 'Halbzeit',
	timeline_kickOff: 'Starten',

	unit_days: "Tage",
	unit_hours: "Stunden",
	unit_minutes: "Minuten",
	unit_seconds: "Sekunden",
}
export default de
