<template>
	<div :class="$style.root">
		<div :class="$style.leagueMatches" v-for="(league, index) in Object.values(matchesByLeagues)" :key="index">
			<div :class="$style.league">
				<!-- <img :src="bundesligaLogo" alt=""> -->
				<h2 :class="$style.leagueName">{{ league.name }}</h2>
			</div>
			<template v-for="(matchesOfDay, index) in Object.values(league.matches)" :key="index">
				<div :class="$style.time">
					<img :src="clockIcon" alt="">
					<p>{{ getTime(matchesOfDay[0].startTimestamp) }}</p>
				</div>
				<div v-for="match in matchesOfDay" :key="match.id">
					<a
						:class="$style.matchItem"
						:href="`/?partner=${partner}&match=${match.homeClub.code}~${match.awayClub.code}`"
					>
						<div :class="$style.matchClubs">
							<div :class="$style.homeClub" v-if="match.homeClub">
								<div>
									<img :src="match.homeClub.logoUrl" alt="">
									<p>{{ match.homeClub.shortName || match.homeClub.name }}</p>
								</div>
								<span v-if="match.score">{{ match.score.home }}</span>
							</div>
							<div :class="$style.awayClub" v-if="match.awayClub">
								<div>
									<img :src="match.awayClub.logoUrl" alt="">
									<p>{{ match.awayClub.shortName || match.awayClub.name }}</p>
								</div>
								<span v-if="match.score">{{ match.score.away }}</span>
							</div>
						</div>
						<img :class="$style.playIcon" src="@/assets/play.svg" alt="">
					</a>
				</div>
			</template>

		</div>
	</div>
</template>

<script lang="ts">
	import ApiClient from "@/api/ApiClient"
	import clockIcon from "@/assets/clock.svg"
	import Fixture from "@/model/Fixture"
	import { computed, defineComponent, onBeforeUnmount, PropType, ref } from "@vue/runtime-core"
	import { useRoute } from "vue-router"


	interface MatchesByLeagues {
		[key: string]: {
			name: string;
			matches: Fixture[];
		}
	}

	interface MatchesByLeaguesGroupedByTime {
		[key: string]: {
			name: string;
			matches: {
				[ket: string]: Fixture[]
			};
		}
	}

	export default defineComponent({
		props: {
			fixture: { type: Object as PropType<Fixture>, required: true },
		},
		async setup(props) {
			const route = useRoute()
			const partner = ref(route.query.partner)
			const apiClient = new ApiClient()
			onBeforeUnmount(() => apiClient.abortRetries())
			const fixtures = ref((await apiClient.listFixtures(new Date(props.fixture.startTimestamp))).data)

			const matchesByLeagues = computed<MatchesByLeaguesGroupedByTime>(() => {
				const leaguesMatches: MatchesByLeagues = {}
				fixtures.value.forEach(m => {
					leaguesMatches[m.competition.id] = {
						name: m.competition.name,
						matches: leaguesMatches[m.competition.id]?.matches
							? [...leaguesMatches[m.competition.id].matches, m]
							: [m],
					}
				})
				return groupByTime(leaguesMatches)
			})

			const groupByTime = (leagueMatches: MatchesByLeagues): MatchesByLeaguesGroupedByTime => {
				const leaguesIds = Object.keys(leagueMatches)
				let leaguesGrouped: MatchesByLeaguesGroupedByTime = {}
				for (let i = 0; i < leaguesIds.length; i++) {
					let matches = {}

					leagueMatches[leaguesIds[i]].matches.forEach(match => {
						matches[match.startTimestamp.getTime()] = matches[match.startTimestamp.getTime()] ?
							[...matches[match.startTimestamp.getTime()], match] :
							[match]
					})

					leaguesGrouped[leaguesIds[i]] = {
						name: leagueMatches[leaguesIds[i]].name,
						matches,
					}
				}

				return leaguesGrouped
			}

			const getTime = (time) => {
				const options: any = {
					hour: "2-digit",
					minute: "2-digit",
				}
				return new Date(time).toLocaleTimeString(navigator.language, options)
			}

			return {
				clockIcon,
				getTime,
				matchesByLeagues,
				partner,
			}
		},
	})
</script>

<style lang="scss" module>
	.awayClub,
	.homeClub {
		display: flex;
		justify-content: space-between;
		padding-right: 16px;

		> div {
			display: flex;
		}

		img {
			height: 20px;
			margin-right: 8px;
			width: 20px;
		}

		p {
			font-size: 14px;
		}
	}

	.homeClub {
		margin-bottom: 4px;
	}

	.league {
		align-items: center;
		display: flex;
		margin: 0 16px 8px 16px;

		img {
			margin-right: 11px;
			max-width: 48px;
			width: 100%;
		}
	}

	.leagueName {
		font-size: 18px;
		font-weight: bold;
	}

	.leagueMatches {
		padding: 15px 0;
	}

	.matchClubs {
		border-right: 1px solid rgba(255, 255, 255, 0.2);
		flex: 1;
	}

	.matchItem {
		color: #EDE5DC;
		display: flex;
		padding: 8px 24px;
		text-decoration: none;

		@media (hover: hover) {
			&:hover {
				background-color: #FFFFFF26;
			}
		}
	}

	.playIcon {
		margin: 0 10px 0 18px;
	}

	.root {
		overflow: auto;
		overflow: overlay;
	}

	.time {
		align-items: center;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		display: flex;
		margin: 0 16px;
		padding: 10px;

		img {
			max-width: 20px;
			width: 100%;
		}

		p {
			font-size: 14px;
			font-weight: bold;
			margin-left: 6px;
		}
	}
</style>
