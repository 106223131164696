export default class MatchSquadMember {

	constructor(
		readonly id: string,
		readonly isDisqualified: boolean,
		readonly isPlaying: boolean,
		readonly isSubstituted: boolean,
	) {
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.id,
			json.isDisqualified,
			json.isPlaying,
			json.isSubstituted,
		)
	}
}
