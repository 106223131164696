export default class MatchTiming {

	constructor(
		readonly firstHalfFinalWhistleTimestamp: Date,
		readonly firstHalfKickoffTimestamp: Date,
		readonly secondHalfFinalWhistleTimestamp: Date,
		readonly secondHalfKickoffTimestamp: Date,
	) {
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			new Date(json.firstHalfFinalWhistleTimestamp),
			new Date(json.firstHalfKickoffTimestamp),
			new Date(json.secondHalfFinalWhistleTimestamp),
			new Date(json.secondHalfKickoffTimestamp),
		)
	}
}
