import Card from "@/model/Card"
import MatchSection from "@/model/MatchSection"
import Score from "@/model/Score"


export default abstract class MatchEventType {

	abstract readonly __type:
		"Caution" |
		"CornerKick" |
		"Delete" |
		"FinalWhistle" |
		"Foul" |
		"FreeKick" |
		"Goal" |
		"KickOff" |
		"Offside" |
		"PenaltyKick" |
		"Substitution" |
		"ThrowIn" |
		"Unknown"


	static fromJson(json: any): MatchEventType {
		const typeId = json.__type
		switch (typeId) {
			case "Caution":
				return CautionMatchEventType.fromJson(json)
			case "CornerKick":
				return CornerKickMatchEventType.fromJson(json)
			case "Delete":
				return DeleteMatchEventType.fromJson(json)
			case "FinalWhistle":
				return FinalWhistleMatchEventType.fromJson(json)
			case "Foul":
				return FoulMatchEventType.fromJson(json)
			case "FreeKick":
				return FreeKickMatchEventType.fromJson(json)
			case "Goal":
				return GoalMatchEventType.fromJson(json)
			case "KickOff":
				return KickOffMatchEventType.fromJson(json)
			case "Offside":
				return OffsideMatchEventType.fromJson(json)
			case "PenaltyKick":
				return PenaltyKickMatchEventType.fromJson(json)
			case "Substitution":
				return SubstitutionMatchEventType.fromJson(json)
			case "ThrowIn":
				return ThrowInMatchEventType.fromJson(json)
			default:
				return UnknownMatchEventType.fromJson(json)
		}
	}
}


export class CautionMatchEventType extends MatchEventType {

	readonly __type = "Caution"


	constructor(
		readonly card: Card,
		readonly clubId: string,
		readonly playerId: string,
	) {
		super()
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.card,
			json.clubId,
			json.playerId,
		)
	}
}


export class CornerKickMatchEventType extends MatchEventType {

	readonly __type = "CornerKick"


	constructor(
		readonly clubId: string,
	) {
		super()
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.clubId,
		)
	}
}


export class DeleteMatchEventType extends MatchEventType {

	readonly __type = "Delete"


	constructor() {
		super()
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this()
	}
}


export class FinalWhistleMatchEventType extends MatchEventType {

	readonly __type = "FinalWhistle"


	constructor(
		readonly isPremature: boolean,
		readonly score: Score,
		readonly section: MatchSection,
	) {
		super()
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.isPremature,
			Score.fromJson(json.score),
			json.section,
		)
	}
}


export class FoulMatchEventType extends MatchEventType {

	readonly __type = "Foul"


	constructor(
		readonly offenderClubId: string,
		readonly offenderId: string,
	) {
		super()
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.offenderClubId,
			json.offenderId,
		)
	}
}


export class FreeKickMatchEventType extends MatchEventType {

	readonly __type = "FreeKick"


	constructor(
		readonly clubId: string,
	) {
		super()
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.clubId,
		)
	}
}


export class GoalMatchEventType extends MatchEventType {

	readonly __type = "Goal"


	constructor(
		readonly assistPlayerId: string | null,
		readonly clubId: string,
		readonly isOwn: boolean,
		readonly playerId: string,
		readonly score: Score,
	) {
		super()
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.assistPlayerId,
			json.clubId,
			json.isOwn,
			json.playerId,
			Score.fromJson(json.score),
		)
	}
}


export class KickOffMatchEventType extends MatchEventType {

	readonly __type = "KickOff"


	constructor(
		readonly section: MatchSection,
	) {
		super()
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.section,
		)
	}
}


export class OffsideMatchEventType extends MatchEventType {

	readonly __type = "Offside"


	constructor(
		readonly clubId: string,
		readonly playerId: string,
	) {
		super()
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.clubId,
			json.playerId,
		)
	}
}


export class PenaltyKickMatchEventType extends MatchEventType {

	readonly __type = "PenaltyKick"


	constructor(
		readonly clubId: string,
	) {
		super()
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.clubId,
		)
	}
}


export class SubstitutionMatchEventType extends MatchEventType {

	readonly __type = "Substitution"


	constructor(
		readonly clubId: string,
		readonly inPlayerId: string,
		readonly outPlayerId: string,
	) {
		super()
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.clubId,
			json.inPlayerId,
			json.outPlayerId,
		)
	}
}


export class ThrowInMatchEventType extends MatchEventType {

	readonly __type = "CornerKick"


	constructor(
		readonly clubId: string,
	) {
		super()
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.clubId,
		)
	}
}


export class UnknownMatchEventType extends MatchEventType {

	readonly __type = "Unknown"


	constructor(
		readonly json: any,
	) {
		super()
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(json)
	}
}
