
	import CameraMode from "@/model/CameraMode"
	import { defineComponent, PropType, useCssModule } from "vue"


	export default defineComponent({
		props: {
			modelValue: { type: String as PropType<CameraMode>, required: true },
		},
		emits: {
			"update:modelValue": (_: CameraMode) => true,
		},
		setup(props, { emit }) {
			const $style = useCssModule()

			const modes: ReadonlyArray<CameraMode> = ["auto", "left", "leftCorner", "broadcast", "rightCorner", "right"]

			function modeClasses(mode: CameraMode) {
				return [
					$style.mode,
					$style[`mode_${mode}`],
					mode === props.modelValue ? $style.active : null,
				]
			}

			function onModeClicked(mode: CameraMode) {
				emit("update:modelValue", mode)
			}

			return {
				modeClasses,
				modes,
				onModeClicked,
			}
		},
	})
