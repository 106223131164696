import LiveFrame from "@/live/model/LiveFrame"


export default class LiveFrameSet {

	constructor(
		readonly endTimestamp: number,
		readonly frames: ReadonlyArray<LiveFrame>,
		readonly startTimestamp: number,
	) {}


	static fromJson(json: any) {
		return new this(
			json.endTimestamp,
			json.frames.map(it => LiveFrame.fromJson(it)),
			json.startTimestamp,
		)
	}
}
