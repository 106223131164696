import MatchPlayerStatistics from "@/model/MatchPlayerStatistics"


export default class MatchClubStatistics {
	constructor(
		readonly ballPossessionRate: number,
		readonly bestDuelSuccessCount: number,
		readonly bestDuelSuccessRate: number,
		readonly bestDuelSuccessRatePlayerId: string | null,
		readonly bestPassCompletionCount: number,
		readonly bestPassCompletionRate: number,
		readonly bestPassCompletionRatePlayerId: string | null,
		readonly cardCountRed: number,
		readonly cardCountYellow: number,
		readonly cardCountYellowRed: number,
		readonly cornerKickCount: number,
		readonly distanceCovered: number,
		readonly duelSuccessCount: number,
		readonly duelSuccessRate: number,
		readonly foulCount: number,
		readonly goalAssistCount: number,
		readonly goalCount: number,
		readonly goalShotAssistCount: number,
		readonly goalShotCount: number,
		readonly mostShotsCount: number,
		readonly mostShotsCountPlayerId: string | null,
		readonly mostShotsOnTargetCount: number,
		readonly passCompletionCount: number,
		readonly passCompletionRate: number,
		readonly shotCount: number,
		readonly playerStatistics: MatchPlayerStatistics[],
	) {
		Object.freeze(this)
	}

	static fromJson(json: any) {
		return new this(
			json.ballPossessionRate,
			json.bestDuelSuccessCount,
			json.bestDuelSuccessRate,
			json.bestDuelSuccessRatePlayerId,
			json.bestPassCompletionCount,
			json.bestPassCompletionRate,
			json.bestPassCompletionRatePlayerId,
			json.cardCountRed,
			json.cardCountYellow,
			json.cardCountYellowRed,
			json.cornerKickCount,
			json.distanceCovered,
			json.duelSuccessCount,
			json.duelSuccessRate,
			json.foulCount,
			json.goalAssistCount,
			json.goalCount,
			json.goalShotAssistCount,
			json.goalShotCount,
			json.mostShotsCount,
			json.mostShotsCountPlayerId,
			json.mostShotsOnTargetCount,
			json.passCompletionCount,
			json.passCompletionRate,
			json.shotCount,
			json.playerStatistics.map(playerStats => MatchPlayerStatistics.fromJson(playerStats)),
		)
	}
}
