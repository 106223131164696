<template>
	<div :class="$style.root">
		<div :class="$style.head">
			<div :class="$style.club">
				<p>{{ homeClub.code }}</p>
				<img :src="homeClub.logoUrl" :alt="`${homeClub.logoUrl} logo`">
			</div>
			<span :class="$style.vs">VS</span>
			<div :class="$style.club">
				<img :src="awayClub.logoUrl" :alt="`${awayClub.logoUrl} logo`">
				<p>{{ awayClub.code }}</p>
			</div>
		</div>

		<template
			v-for="(event, index) in events"
			:key="index"
		>
			<v-match-timeline-event-section v-if="isEventTransition(event.type)" :event="event.type"/>
			<v-match-timeline-event
				v-else
				:state="state"
				:event="event"
				:locale="getTranslation(event.type)"
				@player-clicked="onPlayerClicked"
			/>
		</template>
	</div>
</template>

<script lang="ts">
	import { useLocalization } from "@/i18n/VueLocalization"
	import LiveState from "@/live/model/LiveState"
	import VMatchTimelineEvent from "@/menu/components/VMatchTimelineEvent.vue"
	import VMatchTimelineEventSection from "@/menu/components/VMatchTimelineEventSection.vue"
	import Club from "@/model/Club"
	import MatchEventType, {
		CautionMatchEventType,
		FinalWhistleMatchEventType,
		GoalMatchEventType,
		KickOffMatchEventType,
		SubstitutionMatchEventType,
	} from "@/model/MatchEventType"
	import { computed, defineComponent, PropType } from "@vue/runtime-core"


	export default defineComponent({
		components: { VMatchTimelineEvent, VMatchTimelineEventSection },
		props: {
			state: { type: Object as PropType<LiveState>, required: true },
		},
		emits: {
			"playerClicked": (_: string) => true,
		},
		setup(props, { emit }) {
			const $l = useLocalization()
			const homeClub = computed<Club>(() => props.state.fixture.homeClub)
			const awayClub = computed<Club>(() => props.state.fixture.awayClub)
			const events = computed(() =>
				Array.from(props.state.events.values())
					.filter(e =>
						(e.type instanceof KickOffMatchEventType &&
							e.type.section === "firstHalf") ||
						e.type instanceof FinalWhistleMatchEventType ||
						e.type instanceof CautionMatchEventType ||
						e.type instanceof GoalMatchEventType ||
						e.type instanceof SubstitutionMatchEventType,
					)
					.sort((a, b) => b.timestamp - a.timestamp),
			)

			function onPlayerClicked(id: string) {
				emit("playerClicked", id)
			}

			const getTranslation = (type: MatchEventType): string | null => {
				if (type instanceof CautionMatchEventType) {
					if (type.card === "red") {
						return $l.event_redCard
					}
					if (type.card === "yellow") {
						return $l.event_yellowCard
					}
					if (type.card === "yellowRed") {
						return $l.event_yellowRedCard
					}
				}
				if (type instanceof GoalMatchEventType)
					return $l.event_goal
				if (type instanceof SubstitutionMatchEventType)
					return $l.event_substitution
				return null
			}

			function isEventTransition(event: MatchEventType) {
				return event instanceof FinalWhistleMatchEventType ||
					event instanceof KickOffMatchEventType
			}

			return {
				awayClub,
				events,
				homeClub,
				isEventTransition,
				onPlayerClicked,
				getTranslation,
			}
		},
	})
</script>

<style lang="scss" module>
	.club {
		align-items: center;
		display: flex;

		img {
			width: 32px;

			&:first-child {
				margin-right: 8px;
			}

			&:last-child {
				margin-left: 8px;
			}
		}

		p {
			font-size: 16px;
			line-height: 20px;
		}
	}

	.head {
		align-items: center;
		background: radial-gradient(83.94% 151.33% at 50% 165.93%, #00C5CF 0%, rgba(0, 197, 207, 0) 100%);
		display: flex;
		justify-content: center;
		padding: 8px;
	}

	.root {
		overflow: auto;
		overflow: overlay;
	}

	.vs {
		font-size: 16px;
		margin: 0 24px;
		text-transform: uppercase;
	}
</style>
