import * as Commands from "@/api/Commands"
import Fixture from "@/model/Fixture"
import IntegrationInfo from "@/model/IntegrationInfo"
import LeagueTable from "@/model/LeagueTable"
import axios, { AxiosResponse } from "axios"


export default class ApiClient {

	private readonly axios = axios
	private retryDelay = 0
	private keepTrying = true


	async fetchIntegrationInfo(matchKey: string | null, partnerId: string): Promise<AxiosResponse<IntegrationInfo>> {
		return this.sendCommand<IntegrationInfo>(
			Commands.default.FetchIntegrationInfoCommand,
			{ matchKey, partnerId },
			data => IntegrationInfo.fromJson(JSON.parse(data).result),
		)
	}


	async fetchLeagueTable(leagueId: String): Promise<AxiosResponse<LeagueTable>> {
		return this.sendCommand<LeagueTable>(Commands.default.FetchLeagueTableCommand, { leagueId })
	}


	async listFixtures(date: Date): Promise<AxiosResponse<ReadonlyArray<Fixture>>> {
		return this.sendCommand<ReadonlyArray<Fixture>>(
			Commands.default.ListFixturesCommand,
			{ date: date.toISOString().split("T")[0] }, // FIXME Rework. This will lead to an incorrect date due to wrong time zone.
			data => JSON.parse(data).result.map(m => Fixture.fromJson(m)),
		)
	}
	
	private async sendCommand<T>(commandName: String, attributes: Object = {}, transformResponse?: (data: string) => T) {
		if (!this.keepTrying) return
		try {
			return await this.axios.post<T>("/commands", { __type: commandName, ...attributes }, { transformResponse })
		} catch (error) {
			this.retryDelay++
			await new Promise(resolve => setTimeout(resolve, this.retryDelay * 1000))
			return this.sendCommand(commandName, attributes, transformResponse)
		}
	}

	public abortRetries() {
		this.keepTrying = false
	}
}
