import LivePositionsFrame from "@/live/model/LivePositionsFrame"


export default class LivePositionsFrameSet {

	constructor(
		readonly frames: ReadonlyArray<LivePositionsFrame>,
	) {
		Object.freeze(this)
	}
}
