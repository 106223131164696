
	import LiveStateClub from "@/live/model/LiveStateClub"
	import VMatchSquadsClubMember from "@/menu/components/VMatchSquadsClubMember.vue"
	import MatchSquadMember from "@/model/MatchSquadMember"
	import Player from "@/model/Player"
	import { computed, defineComponent, PropType } from "vue"


	export interface MatchSquadsMemberFull extends MatchSquadMember, Player {
	}

	export default defineComponent({
		components: { VMatchSquadsClubMember },
		props: {
			club: { type: Object as PropType<LiveStateClub>, required: true },
			players: { type: Object as PropType<ReadonlyMap<string, Player>>, required: true },
		},
		emits: {
			"playerClicked": (_: string) => true,
		},
		setup(props, { emit }) {
			const members = computed<MatchSquadsMemberFull[]>(() => props.club.squad.members.map(member => {
				const player = props.players.get(member.id)!!
				return { ...member, ...player, playingPositionLabel: player.playingPositionLabel }
			}))
			const actualFormation = computed(() => members.value.filter(member => member.isPlaying))
			const bank = computed(() => members.value.filter(member => !member.isPlaying))

			function onPlayerClicked(id: string) {
				emit("playerClicked", id)
			}

			return {
				actualFormation,
				bank,
				onPlayerClicked,
			}
		},
	})
