import translations from "@/i18n"
import VueLocalization from "@/i18n/VueLocalization"
import axios from "axios"
import { createApp } from "vue"
import { dragscrollNext } from "vue-dragscroll"
import App from "./App.vue"
import router from "./router"
import { createGtm } from "vue-gtm"

// FIXME No globals.
axios.defaults.baseURL = process.env.VUE_APP_API_URL

const app = createApp(App)
	.use(router)
	.use(VueLocalization)
	.use(createGtm({id:`${process.env.VUE_APP_GTM_CONTAINER_ID}`}))
	.directive("dragscroll", dragscrollNext)

app.mount("#app")
