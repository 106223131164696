
	import FullscreenController, { FullscreenControllerInjectionKey } from "@/utility/FullscreenController"
	import { defineComponent, onBeforeUnmount, provide } from "vue"


	export default defineComponent({
		setup() {
			const fullscreenController = new FullscreenController()

			provide(FullscreenControllerInjectionKey, fullscreenController)

			function onResize() {
				// iOS Safari incorrectly scrolls page on orientation change.
				document.documentElement.scrollTop = 0
			}

			window.addEventListener("resize", onResize)

			onBeforeUnmount(() => {
				window.removeEventListener("resize", onResize)
			})
		},
	})
