export default class Club {

	constructor(
		readonly code: string,
		readonly id: string,
		readonly name: string,
		readonly shortName: string,
		readonly logoUrl: string,
	) {
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.code,
			json.id,
			json.name,
			json.shortName,
			json.logoUrl,
		)
	}
}
