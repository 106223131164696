<template>
	<div :class="$style.root">
		<div :class="$style.head">
			<div :class="$style.playerImage" :style="{backgroundImage: `url(${player?.faceImageUrl})`}"/>
			<div :class="$style.playerDescription">
				<p :class="$style.playerName">{{ player?.name }}</p>
				<p>
					<span>{{ playingPosition }}</span>
					<span>{{ club.code }}</span>
					<span>{{ player?.shirtNumber }}</span>
				</p>
			</div>
		</div>
		<div :class="$style.playerStats">
			<h4 :class="$style.statsTitle">{{ $l.player_information }}</h4>
			<div :class="$style.playerStatsItem">
				<span :class="$style.statName">{{ $l.player_nationality }}:</span>
				<span :class="$style.statValue">{{ player?.nationalityLabel }}</span>
			</div>
			<div :class="$style.playerStatsItem">
				<span :class="$style.statName">{{ $l.player_age }}:</span>
				<span :class="$style.statValue">{{ age }}</span>
			</div>
			<div :class="$style.playerStatsItem">
				<span :class="$style.statName">{{ $l.player_height }}:</span>
				<span :class="$style.statValue" v-if="player?.height">{{
						(player.height / 100).toFixed(2)
							.replace('.', ',')
					}}m</span>
			</div>
			<div :class="$style.playerStatsItem">
				<span :class="$style.statName">{{ $l.player_weight }}:</span>
				<span :class="$style.statValue">{{ player?.weight }}kg</span>
			</div>
			<h4 :class="$style.statsTitle" v-if="playerStats.length">{{ $l.player_matchInformation }}</h4>
			<div
				:class="$style.playerStatsItem"
				v-for="(stat, index) in playerStats"
				:key="index"
			>
				<span :class="$style.statName">{{ stat.title }}</span>
				<div>
					<span :class="statValueStyle(stat.card)"> {{ stat.mainValue }}</span>
					<span v-if="stat.secondaryValue" :class="$style.secondaryValue">({{ stat.secondaryValue }})</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { useLocalization } from "@/i18n/VueLocalization"
	import LiveState from "@/live/model/LiveState"
	import Card from "@/model/Card"
	import MatchPlayerStatistics from "@/model/MatchPlayerStatistics"
	import { computed, PropType } from "@vue/runtime-core"
	import { useCssModule } from "@vue/runtime-dom"


	export default {
		props: {
			state: { type: Object as PropType<LiveState>, required: true },
			playerId: { type: String, required: true },
		},
		setup(props) {
			const $l = useLocalization()
			const $style = useCssModule()
			const statValueStyle = (card: Card | undefined) => {
				let cardStyle
				if (card === "red")
					cardStyle = $style.redCard
				if (card === "yellow")
					cardStyle = $style.yellowCard
				if (card === "yellowRed")
					cardStyle = $style.yellowRedCard
				return [
					$style.statValue,
					cardStyle,
				]
			}

			const player = computed(() => props.state.players.get(props.playerId))
			const club = computed(() => props.state.fixture.club(props.state.players.get(props.playerId)!!.clubId))
			const playingPositionRef = computed(() => player.value?.playingPositionLabel($l))
			const age = computed(() => {
				if (!player.value?.dateOfBirth) return null
				const now = new Date()
				const birthDate = new Date(player.value.dateOfBirth)
				const diff = now.getFullYear() - birthDate.getFullYear()
				const hadAnniversaryThisYear = now.getMonth() > birthDate.getMonth() ||
					now.getMonth() === birthDate.getMonth() && now.getDay() >= birthDate.getDay()
				if (hadAnniversaryThisYear) return diff
				return diff - 1
			})
			const clubString = computed<string>(() => {
				return club.value?.id === props.state.fixture.homeClub.id
					? "homeClubStatistics"
					: "awayClubStatistics"
			})
			const playerStatistic = computed<MatchPlayerStatistics>(() => {
				if (!props.state.statistics) return null
				return props.state.statistics[clubString.value].playerStatistics.find(p => p.id === props.playerId)
			})

			const playerStats = computed(() => {
				if (!playerStatistic.value) return []
				return [
					playerStatistic.value.formatStat({
						absoluteValue: playerStatistic.value.playDuration,
						divisor: 60,
						unitOfMeasurement: "min",
						title: $l.statistics_playDuration,
					}),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.goalCount, title: $l.statistics_goals }),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.goalAssistCount, title: $l.statistics_goalAssists }),
					// playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.ballContactCount, title: $l.statistics_ballContacts }),
					playerStatistic.value.formatStat({
						percentageValue: playerStatistic.value.passCompletionRate,
						absoluteValue: playerStatistic.value.passCompletionCount,
						title: $l.statistics_passCompletion,
					}),
					playerStatistic.value.formatStat({
						percentageValue: playerStatistic.value.duelSuccessRate,
						absoluteValue: playerStatistic.value.duelSuccessCount,
						title: $l.statistics_duelSuccess,
					}),
					playerStatistic.value.formatStat({
						absoluteValue: playerStatistic.value.distanceCovered,
						divisor: 1000,
						unitOfMeasurement: "km",
						title: $l.statistics_distanceCovered,
					}),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.shotCount, title: $l.statistics_shotCount }),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.goalShotCount, title: $l.statistics_goalShotCount }),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.goalShotAssistCount, title: $l.statistics_goalShotAssistCount }),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.foulCount, title: $l.statistics_fouls }),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.cardCountRed, card: "red", title: $l.statistics_redCard }),
					playerStatistic.value.formatStat({ absoluteValue: playerStatistic.value.cardCountYellow, card: "yellow", title: $l.statistics_yellowCard }),
					playerStatistic.value.formatStat({
						absoluteValue: playerStatistic.value.cardCountYellowRed,
						card: "yellowRed",
						title: $l.statistics_yellowRedCard,
					}),
				].filter((i) => i)
			})

			return {
				age,
				club,
				player,
				playerStats,
				playingPosition: playingPositionRef,
				statValueStyle,
			}
		},
	}
</script>

<style lang="scss" module>
	.root {
		color: #EDE5DC;
		overflow: auto;
		overflow: overlay;
	}

	.head {
		align-items: center;
		display: flex;
		padding: 16px 16px 0 16px;
	}

	.playerImage {
		border-radius: 50%;
		height: 64px;
		margin-right: 16px;
		overflow: hidden;
		width: 64px;
		background-color: #EDE5DC;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}

	.playerDescription {
		span {
			font-weight: normal;
			font-size: 12px;
			line-height: 15px;

			&:not(:first-child)::before {
				content: ".";
				font-size: 18px;
				margin: 0 4px;
				vertical-align: top;
			}
		}
	}

	.playerName {
		font-weight: bold;
		font-size: 16px;
		line-height: 100%;
		margin-bottom: 4px;
	}

	.playerStats {
		padding: 16px;
	}


	.playerStatsItem {
		font-size: 12px;
		line-height: 15px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		padding: 4px 0;
	}

	.statValue {
		font-weight: bold;
	}

	.secondaryValue {
		margin-left: 4px;
	}

	.statsTitle {
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;
		text-transform: capitalize;
		margin-bottom: 8px;
		margin-top: 32px;
	}

	.redCard,
	.yellowCard,
	.yellowRedCard {
		padding: 0 2.5px;
		color: #121212;
		border-radius: 2px;
	}

	.yellowCard {
		background-color: #F4CC3E;
	}

	.redCard {
		background-color: #E92E2E;
	}

	.yellowRedCard {
		position: relative;
		overflow: hidden;
		z-index: 22;
		display: block;

		&::before {
			background-image: linear-gradient(#F4CC3E 50%, #E92E2E 50%);
			content: "";
			height: 200%;
			width: 200%;
			position: absolute;
			top: -50%;
			left: -50%;
			bottom: 0;
			right: 0;
			transform: rotate(45deg);
			z-index: -1;
		}
	}

</style>
