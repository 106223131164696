<template>
	<div :class="$style.root" @click="onPlayerClicked(member.id)">
		<div :class="$style.memberInfo">
			<div :class="$style.memberFace" :style="`background-image: url(${member.faceImageUrl})`"/>

			<div>
				<div :class="$style.memberName">
					{{ member.name }}
				</div>
				<div :class="$style.numberAndPosition">
					<span>{{ member.shirtNumber }}</span>
					<span>{{ member.playingPositionLabel($l) }}</span>
				</div>
			</div>
		</div>

		<div :class="$style.substitutedOrDisqualified">
			<img src="/images/events/SUBSTITUTION.svg" alt="" v-if="member.isSubstituted">
			<div :class="$style.disqualified" v-if="member.isDisqualified"/>
		</div>
	</div>
</template>

<script lang="ts">
	import { MatchSquadsMemberFull } from "@/menu/VMatchSquadsClub.vue"
	import { defineComponent, PropType } from "vue"


	export default defineComponent({
		props: {
			member: { type: Object as PropType<MatchSquadsMemberFull>, required: true },
		},
		emits: {
			"playerClicked": (_: string) => true,
		},
		setup(props, { emit }) {
			function onPlayerClicked(id: string) {
				emit("playerClicked", id)
			}

			return { onPlayerClicked }
		},
	})
</script>

<style lang="scss" module>
	.root {
		align-items: center;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		padding: 8px 0;
		transition: 0.5s;

		@media (hover: hover) {
			&:hover {
				padding-left: 10px;
				transition: 0.5s ease;
			}
		}
	}

	.memberInfo {
		align-items: center;
		display: flex;
		justify-content: flex-start;
	}

	.memberFace {
		background-color: #EDE5DC;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		border-radius: 50%;
		height: 48px;
		margin-right: 8px;
		width: 48px;
	}

	.memberName {
		font-weight: bold;
		line-height: 100%;
	}

	.numberAndPosition {
		span {
			font-size: 12px;
			line-height: 100%;

			&:last-child::before {
				content: '.';
				vertical-align: top;
				margin: 0 4px;
				font-size: 1.2rem;
			}
		}
	}

	.substitutedOrDisqualified {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	.disqualified {
		border-radius: 2px;
		background-color: #E92E2E;
		height: 20px;
		width: 14px;
		margin-left: 10px;
	}
</style>
