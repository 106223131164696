export default class ShirtColors {

	constructor(
		readonly number: string | null,
		readonly primary: string,
		readonly secondary: string | null,
	) {
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.number,
			json.primary,
			json.secondary,
		)
	}
}
