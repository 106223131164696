<template>
	<div :class="$style.root" :style="style">
		<div :class="$style.image"/>
		<div :class="$style.details">
			<div :class="$style.title">
				<span :class="$style.title1" v-text="title"/>
				<span v-if="title2" :class="$style.titleSeparator" v-text="' • '"/>
				<span v-if="title2" :class="$style.title2" v-text="title2"/>
			</div>
			<div v-if="subtitle" :class="$style.subtitle" v-text="subtitle"/>
		</div>
		<div v-if="iconUrl" :class="$style.icon"/>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent } from "vue"


	export default defineComponent({
		props: {
			color: { type: String, required: true },
			iconUrl: String,
			imageUrl: String,
			showsImageBackground: Boolean,
			subtitle: String,
			title: { type: String, required: true },
			title2: String,
		},
		setup(props) {
			const styleRef = computed(() => ({
				"--VMatchEventNotification-color": props.color,
				"--VMatchEventNotification-iconBackground": props.iconUrl ? `url("${props.iconUrl}")` : "none",
				"--VMatchEventNotification-imageBackgroundColor": props.showsImageBackground ? "#EDE5DC" : "none",
				"--VMatchEventNotification-imageBorderRadius": props.showsImageBackground ? "26px" : "0",
				"--VMatchEventNotification-imageBackground": props.imageUrl ? `url("${props.imageUrl}")` : "none",
			}))

			return { style: styleRef }
		},
	})
</script>

<style lang="scss" module>
	$breakpoint: 750px;

	.icon {
		background-image: var(--VMatchEventNotification-iconBackground);
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		flex-shrink: 0;
		height: 24px;
		margin-left: 20px;
		width: 24px;

		@media (min-width: $breakpoint) {
			height: 36px;
			margin-left: 24px;
			width: 36px;
		}
	}

	.image {
		background-color: var(--VMatchEventNotification-imageBackgroundColor);
		background-image: var(--VMatchEventNotification-imageBackground);
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		border-radius: var(--VMatchEventNotification-imageBorderRadius);
		flex-shrink: 0;
		margin-right: 16px;
		height: 52px;
		width: 52px;
	}

	.root {
		align-items: center;
		background: rgba(18, 18, 42, 0.75);
		border-radius: 8px;
		color: #EDE5DC;
		display: flex;
		overflow: hidden;
		padding: 16px 24px;
		position: relative;
		user-select: none;
		width: fit-content;

		@media (min-width: $breakpoint) {
			padding: 24px 32px;
		}

		&::after {
			background-color: var(--VMatchEventNotification-color);
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 8px;
		}
	}

	.subtitle {
		font-size: 14px;
		line-height: 22px;
		margin-top: 4px;
	}

	.title {
		font-size: 16px;
		line-height: 24px;

		@media (min-width: $breakpoint) {
			font-size: 20px;
			line-height: 30px;
		}
	}

	.title1, .titleSeparator {
		font-weight: bold;
	}

	.title2 {
		white-space: nowrap;
	}

	.titleSeparator {
		white-space: nowrap;
	}
</style>
