<template>
	<div :class="$style.root">
		<div>
			<div :class="$style.onField">
				<div :class="$style.squadSubtitle">{{ $l.squad_field }}</div>
				<v-match-squads-club-member
					v-for="(member) in actualFormation" :key="member.id"
					:member="member"
					@player-clicked="onPlayerClicked"
				/>
			</div>

			<div :class="$style.onBench">
				<div :class="$style.squadSubtitle">{{ $l.squad_bench }}</div>
				<v-match-squads-club-member
					v-for="(member) in bank" :key="member.id"
					:member="member"
					@player-clicked="onPlayerClicked"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import LiveStateClub from "@/live/model/LiveStateClub"
	import VMatchSquadsClubMember from "@/menu/components/VMatchSquadsClubMember.vue"
	import MatchSquadMember from "@/model/MatchSquadMember"
	import Player from "@/model/Player"
	import { computed, defineComponent, PropType } from "vue"


	export interface MatchSquadsMemberFull extends MatchSquadMember, Player {
	}

	export default defineComponent({
		components: { VMatchSquadsClubMember },
		props: {
			club: { type: Object as PropType<LiveStateClub>, required: true },
			players: { type: Object as PropType<ReadonlyMap<string, Player>>, required: true },
		},
		emits: {
			"playerClicked": (_: string) => true,
		},
		setup(props, { emit }) {
			const members = computed<MatchSquadsMemberFull[]>(() => props.club.squad.members.map(member => {
				const player = props.players.get(member.id)!!
				return { ...member, ...player, playingPositionLabel: player.playingPositionLabel }
			}))
			const actualFormation = computed(() => members.value.filter(member => member.isPlaying))
			const bank = computed(() => members.value.filter(member => !member.isPlaying))

			function onPlayerClicked(id: string) {
				emit("playerClicked", id)
			}

			return {
				actualFormation,
				bank,
				onPlayerClicked,
			}
		},
	})
</script>

<style lang="scss" module>
	.root {
		overflow: auto;
		overflow: overlay;
		padding: 16px;
	}

	.onField,
	.onBench {
		margin-bottom: 32px;
	}

	.squadSubtitle {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 11px;
	}
</style>
