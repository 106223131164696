<template>
	<div :class="$style.root" :style="style">
		<div :class="$style.row1">
			<div :class="$style.title" v-text="isOwn ? $l.event_ownGoal : $l.event_goal"/>
			<div :class="$style.clubsAndScore">
				<span :class="$style.homeClubLogo"/>
				<span :class="$style.score">{{ homeScore }} : {{ awayScore }}</span>
				<span :class="$style.awayClubLogo"/>
			</div>
		</div>
		<div :class="$style.row2">
			<span :class="$style.playerImage"/>
			<span :class="$style.minuteOfPlay">{{ minuteOfPlay }}’</span>
			<span :class="$style.playerName" v-text="playerName"/>
		</div>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent } from "vue"


	export default defineComponent({
		props: {
			color: { type: String, required: true },
			homeClubLogoUrl: { type: String, required: true },
			homeScore: { type: Number, required: true },
			isOwn: { type: Boolean, required: true },
			minuteOfPlay: { type: String, required: true },
			playerImageUrl: String,
			playerName: { type: String, required: true },
			awayClubLogoUrl: { type: String, required: true },
			awayScore: { type: Number, required: true },
		},
		setup(props) {
			const styleRef = computed(() => ({
				"--VMatchGoalNotification-awayClubLogoBackground": `url("${props.awayClubLogoUrl}")`,
				"--VMatchGoalNotification-color": props.color,
				"--VMatchGoalNotification-homeClubLogoBackground": `url("${props.homeClubLogoUrl}")`,
				"--VMatchGoalNotification-playerImageBackground": props.playerImageUrl ? `url("${props.playerImageUrl}")` : "none",
			}))

			return { style: styleRef }
		},
	})
</script>

<style lang="scss" module>
	$breakpoint: 750px;

	.clubsAndScore {
		align-items: center;
		display: flex;
		flex-shrink: 0;
		margin-left: 32px;
		white-space: nowrap;
	}

	.minuteOfPlay {
		font-weight: bold;
		padding: 0 4px 0 8px;
	}

	.playerImage {
		background-color: #EDE5DC;
		background-image: var(--VMatchGoalNotification-playerImageBackground);
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		border-radius: 16px;
		height: 32px;
		width: 32px;
	}

	.root {
		background: rgba(18, 18, 42, 0.75);
		border-radius: 8px;
		color: #EDE5DC;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		padding: 16px 24px;
		position: relative;
		user-select: none;
		width: fit-content;

		@media (min-width: $breakpoint) {
			padding: 24px 32px;
		}

		&::after {
			background-color: var(--VMatchGoalNotification-color);
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 8px;
		}
	}

	.row1 {
		align-items: center;
		display: flex;
		font-size: 18px;
		font-weight: bold;
		line-height: 27px;
		justify-content: space-between;

		@media (min-width: $breakpoint) {
			font-size: 24px;
			line-height: 36px;
		}
	}

	.row2 {
		align-items: center;
		display: flex;
		font-size: 14px;
		line-height: 21px;
		margin-top: 12px;
	}

	.score {
		padding: 0 8px;
	}

	.title {
		text-transform: uppercase;
	}

	.homeClubLogo, .awayClubLogo {
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		height: 24px;
		width: 24px;
	}

	.homeClubLogo {
		background-image: var(--VMatchGoalNotification-homeClubLogoBackground);
	}

	.awayClubLogo {
		background-image: var(--VMatchGoalNotification-awayClubLogoBackground);
	}
</style>
