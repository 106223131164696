<template>
	<div :class="$style.root" :style="style">
		<div :class="$style.row1">
			<div :class="$style.leftPlayerImage" @click="onPlayerClicked(item.leftPlayerId)"/>
			<span :class="$style.label" v-text="item.label"/>
			<div :class="$style.rightPlayerImage" @click="onPlayerClicked(item.rightPlayerId)"/>
		</div>
		<div :class="$style.row2">
			<div :class="$style.leftPlayer">
				<div :class="$style.playerName" v-text="item.leftPlayerName" @click="onPlayerClicked(item.leftPlayerId)"/>
				<div :class="$style.playerValue">
					{{ item.leftPlayerValue.formatted }}
					<span v-if="item.leftPlayerSecondaryValue" :class="$style.secondaryValue">({{ item.leftPlayerSecondaryValue.formatted }})</span>
				</div>
			</div>
			<div :class="$style.rightPlayer">
				<div :class="$style.playerName" v-text="item.rightPlayerName" @click="onPlayerClicked(item.rightPlayerId)"/>
				<div :class="$style.playerValue">
					<span v-if="item.rightPlayerSecondaryValue" :class="$style.secondaryValue">({{ item.rightPlayerSecondaryValue.formatted }})</span>
					{{ item.rightPlayerValue.formatted }}
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { VMMatchStatisticsPlayerComparisonItem } from "@/statistics/model/VMMatchStatisticsComparisonItem"
	import { computed, defineComponent, PropType } from "vue"


	export default defineComponent({
		props: {
			item: { type: Object as PropType<VMMatchStatisticsPlayerComparisonItem>, required: true },
		},
		setup(props, { emit }) {
			const styleRef = computed(() => ({
				"--VMatchStatisticsPlayerComparisonItem-leftPlayerImageBackground":
					props.item.leftPlayerImageUrl ? `url("${props.item.leftPlayerImageUrl}")` : "none",
				"--VMatchStatisticsPlayerComparisonItem-rightPlayerImageBackground":
					props.item.rightPlayerImageUrl ? `url("${props.item.rightPlayerImageUrl}")` : "none",
			}))

			const onPlayerClicked = (id: string) => {
				emit("playerClicked", id)
			}

			return { style: styleRef, onPlayerClicked }
		},
	})
</script>

<style lang="scss" module>
	.label {
		align-items: center;
		display: flex;
		padding: 0 calc(8 * var(--scale));
	}

	.leftPlayerImage, .rightPlayerImage {
		background-color: #EDE5DC;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		border-radius: calc(24 * var(--scale));
		cursor: pointer;
		flex-shrink: 0;
		height: calc(48 * var(--scale));
		width: calc(48 * var(--scale));
	}

	.leftPlayerImage {
		background-image: var(--VMatchStatisticsPlayerComparisonItem-leftPlayerImageBackground);
	}

	.playerName {
		line-height: calc(21 * var(--scale));
		margin-top: calc(8 * var(--scale));

		.leftPlayer > & {
			cursor: pointer;
			padding-right: calc(16 * var(--scale));
		}

		.rightPlayer > & {
			cursor: pointer;
			padding-left: calc(16 * var(--scale));
		}
	}

	.playerValue {
		font-size: calc(20 * var(--scale));
		font-weight: bold;
		line-height: calc(20 * var(--scale));
		margin-top: calc(8 * var(--scale));
	}

	.rightPlayer {
		text-align: right;
	}

	.rightPlayerImage {
		background-image: var(--VMatchStatisticsPlayerComparisonItem-rightPlayerImageBackground);
	}

	.root {
		color: #EDE5DC;
		font-size: calc(14 * var(--scale));
		padding: calc(16 * var(--scale));
	}

	.row1 {
		display: flex;
		justify-content: space-between;
	}

	.row2 {
		display: flex;
		justify-content: space-between;
	}

	.secondaryValue {
		font-weight: normal;
		opacity: 0.65;

		.leftPlayer > & {
			padding-left: calc(4 * var(--scale));
		}

		.rightPlayer > & {
			padding-right: calc(4 * var(--scale));
		}
	}
</style>
