import MatchSquad from "@/model/MatchSquad"
import ShirtColors from "@/model/ShirtColors"


export default class LiveStateClub {

	constructor(
		readonly id: string,
		readonly shirtColors: ShirtColors,
		readonly squad: MatchSquad,
	) {}


	static fromJson(json: any) {
		return new this(
			json.id,
			ShirtColors.fromJson(json.shirtColors),
			MatchSquad.fromJson(json.squad),
		)
	}
}
