
	import { useLocalization } from "@/i18n/VueLocalization"
	import LiveState from "@/live/model/LiveState"
	import VMatchEventNotification from "@/live/views/VMatchEventNotification.vue"
	import VMatchGoalNotification from "@/live/views/VMatchGoalNotification.vue"
	import Card, { cardLabel } from "@/model/Card"
	import Club from "@/model/Club"
	import MatchEvent from "@/model/MatchEvent"
	import {
		CautionMatchEventType,
		CornerKickMatchEventType,
		FoulMatchEventType,
		FreeKickMatchEventType,
		GoalMatchEventType,
		OffsideMatchEventType,
		PenaltyKickMatchEventType,
		SubstitutionMatchEventType,
		ThrowInMatchEventType,
	} from "@/model/MatchEventType"
	import Player from "@/model/Player"
	import { usePageVisibility } from "@/utility/PageVisibility"
	import { computed, defineComponent, onBeforeUnmount, PropType, ref, watchEffect } from "vue"


	export default defineComponent({
		components: { VMatchGoalNotification, VMatchEventNotification },
		props: {
			state: { type: Object as PropType<LiveState>, required: true },
		},
		setup(props) {
			const $l = useLocalization()
			const pageVisibility = usePageVisibility()

			const contentRef = ref<Content>()
			const currentTimerId = ref(0)
			const isInitialUpdateRef = ref(true)
			const minimumReceptionTimestampRef = ref(Infinity)
			const processedEventIds = new Set<string>()
			let pendingContent: Content[] = []

			const stateRef = computed(() => props.state)
			const eventsRef = computed(() => stateRef.value.events)
			const fixtureRef = computed(() => stateRef.value.fixture)

			watchEffect(() => {
				const isInitialUpdate = isInitialUpdateRef.value

				for (const event of eventsRef.value.values()) {
					if (processedEventIds.has(event.id))
						continue

					processedEventIds.add(event.id)

					if (!isInitialUpdate)
						pendingContent.push(...contentForEvent(event))
				}

				isInitialUpdateRef.value = false

				if (!isInitialUpdate)
					startPresentation()
			})

			watchEffect(() => {
				minimumReceptionTimestampRef.value = pageVisibility.value !== "hidden" ? Date.now() : Infinity
			})

			onBeforeUnmount(() => window.clearTimeout(currentTimerId.value))


			function contentForEvent(event: MatchEvent): Content[] {
				const state = stateRef.value
				const type = event.type

				if (type instanceof CautionMatchEventType)
					return [{
						color: clubColor(type.clubId),
						duration: 5_000,
						iconUrl: cardIconUrl(type.card),
						id: event.id,
						imageUrl: player(type.playerId).faceImageUrl,
						receptionTimestamp: Date.now(),
						showsImageBackground: true,
						subtitle: player(type.playerId).shortName,
						timestamp: event.timestamp,
						title: cardLabel(type.card, $l),
						title2: club(type.clubId).code,
						type: "event",
					}]

				if (type instanceof CornerKickMatchEventType)
					return [{
						color: clubColor(type.clubId),
						duration: 4_000,
						iconUrl: require("@/assets/new/event-corner-kick.png").default,
						id: event.id,
						imageUrl: club(type.clubId).logoUrl,
						receptionTimestamp: Date.now(),
						timestamp: event.timestamp,
						title: $l.event_cornerKick,
						type: "event",
					}]

				if (type instanceof FoulMatchEventType)
					return [{
						color: clubColor(type.offenderClubId),
						duration: 4_000,
						iconUrl: require("@/assets/new/event-foul.png").default,
						id: event.id,
						imageUrl: player(type.offenderId).faceImageUrl,
						receptionTimestamp: Date.now(),
						showsImageBackground: true,
						subtitle: player(type.offenderId).shortName,
						timestamp: event.timestamp,
						title: $l.event_foul,
						title2: club(type.offenderClubId).code,
						type: "event",
					}]

				if (type instanceof FreeKickMatchEventType)
					return [{
						color: clubColor(type.clubId),
						duration: 3_000,
						id: event.id,
						imageUrl: club(type.clubId).logoUrl,
						receptionTimestamp: Date.now(),
						timestamp: event.timestamp,
						title: $l.event_freeKick,
						type: "event",
					}]

				if (type instanceof GoalMatchEventType)
					return [{
						awayClubLogoUrl: state.fixture.awayClub.logoUrl,
						awayScore: type.score.away,
						color: type.isOwn ? clubColor(state.fixture.homeClub.id === type.clubId ? state.fixture.awayClub.id : state.fixture.homeClub.id) : clubColor(type.clubId),
						duration: 10_000,
						homeClubLogoUrl: state.fixture.homeClub.logoUrl,
						homeScore: type.score.home,
						id: event.id,
						isOwn: type.isOwn,
						minuteOfPlay: state.formattedMinuteOfPlay(event.timestamp) || "?",
						playerImageUrl: player(type.playerId).faceImageUrl,
						playerName: player(type.playerId).shortName,
						receptionTimestamp: Date.now(),
						timestamp: event.timestamp,
						type: "goal",
					}]

				if (type instanceof OffsideMatchEventType)
					return [{
						color: clubColor(type.clubId),
						duration: 3_000,
						iconUrl: require("@/assets/new/event-offside.png").default,
						id: event.id,
						imageUrl: player(type.playerId).faceImageUrl,
						receptionTimestamp: Date.now(),
						showsImageBackground: true,
						subtitle: player(type.playerId).shortName,
						timestamp: event.timestamp,
						title: $l.event_offside,
						title2: club(type.clubId).code,
						type: "event",
					}]

				if (type instanceof PenaltyKickMatchEventType)
					return [{
						color: clubColor(type.clubId),
						duration: 4_000,
						iconUrl: require("@/assets/new/event-penalty-kick.png").default,
						id: event.id,
						imageUrl: club(type.clubId).logoUrl,
						receptionTimestamp: Date.now(),
						timestamp: event.timestamp,
						title: $l.event_penaltyKick,
						type: "event",
					}]

				if (type instanceof SubstitutionMatchEventType)
					return [
						{
							color: clubColor(type.clubId),
							duration: 2_500,
							iconUrl: require("@/assets/new/event-substitution-out.svg").default,
							id: `${event.id}.out`,
							imageUrl: player(type.outPlayerId).faceImageUrl,
							receptionTimestamp: Date.now(),
							showsImageBackground: true,
							subtitle: player(type.outPlayerId).playingPositionLabel($l),
							timestamp: event.timestamp,
							title: player(type.outPlayerId).shortName,
							title2: club(type.clubId).code,
							type: "event",
						},
						{
							color: clubColor(type.clubId),
							duration: 2_500,
							iconUrl: require("@/assets/new/event-substitution-in.svg").default,
							id: `${event.id}.in`,
							imageUrl: player(type.inPlayerId).faceImageUrl,
							receptionTimestamp: Date.now(),
							showsImageBackground: true,
							subtitle: player(type.inPlayerId).playingPositionLabel($l),
							timestamp: event.timestamp,
							title: player(type.inPlayerId).shortName,
							title2: club(type.clubId).code,
							type: "event",
						},
					]

				if (type instanceof ThrowInMatchEventType)
					return [{
						color: clubColor(type.clubId),
						duration: 3_000,
						id: event.id,
						imageUrl: club(type.clubId).logoUrl,
						receptionTimestamp: Date.now(),
						timestamp: event.timestamp,
						title: $l.event_throwIn,
						type: "event",
					}]

				return []
			}


			function dismissCurrentContent() {
				contentRef.value = undefined
			}


			function onAppear() {
				const content = contentRef.value
				if (!content) {
					console.error("Content appeared but no content is set.")
					return
				}

				window.clearTimeout(currentTimerId.value)
				currentTimerId.value = window.setTimeout(() => dismissCurrentContent(), content.duration)
			}


			function onDisappear() {
				startPresentation()
			}


			function startPresentation() {
				if (contentRef.value)
					return

				const content = takeNextContent()
				if (!content)
					return

				contentRef.value = content
			}


			function takeNextContent(): Content | null {
				pendingContent = pendingContent.sort((a, b) => a.timestamp - b.timestamp)

				const minimumTimestamp = minimumReceptionTimestampRef.value

				while (pendingContent.length) {
					const content = pendingContent.shift()
					if (content && content.receptionTimestamp >= minimumTimestamp)
						return content
				}

				return null
			}


			function cardIconUrl(card: Card) {
				switch (card) {
					case "red":
						return require("@/assets/new/event-card-red.png").default
					case "yellow":
						return require("@/assets/new/event-card-yellow.png").default
					case "yellowRed":
						return require("@/assets/new/event-card-yellow-red.png").default
				}
			}

			function club(id: string): Club {
				return fixtureRef.value.club(id)
			}

			function clubColor(id: string): string {
				return stateRef.value.club(id).shirtColors.primary
			}

			function player(id: string): Player {
				return stateRef.value.players.get(id)!!
			}

			return {
				content: contentRef,
				onAppear,
				onDisappear,
			}
		},
	})


	interface ContentBase {

		readonly color: string
		readonly duration: number
		readonly id: string
		readonly receptionTimestamp: number
		readonly timestamp: number
		readonly type: "event" | "goal"
	}

	interface EventContent extends ContentBase {

		readonly iconUrl?: string | null
		readonly imageUrl?: string | null
		readonly showsImageBackground?: boolean
		readonly subtitle?: string | null
		readonly title: string
		readonly title2?: string | null
		readonly type: "event"
	}

	interface GoalContent extends ContentBase {

		readonly awayClubLogoUrl: string
		readonly awayScore: number
		readonly homeClubLogoUrl: string
		readonly homeScore: number
		readonly isOwn: boolean
		readonly minuteOfPlay: string
		readonly playerImageUrl: string | null
		readonly playerName: string
		readonly type: "goal"
	}

	type Content = EventContent | GoalContent
