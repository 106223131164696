<template>
	<div :class="$style.root" :style="style">
		<div :class="$style.container">
			<div :class="$style.homeClub">
				<div :class="$style.clubLogo"/>
				<div :class="$style.clubName" v-text="homeClub.name"/>
			</div>
			<div :class="$style.content">
				<template v-if="countdown">
					<h2 :class="$style.caption" v-text="$l.caption_matchStartsIn"/>
					<div :class="$style.countdown">
						<div>
							<span :class="$style.countdownDigits" v-text="countdown.days"/>
							<span :class="$style.countdownLabel" v-text="$l.unit_days"/>
						</div>
						<div>
							<span :class="$style.countdownDigits" v-text="countdown.hours"/>
							<span :class="$style.countdownLabel" v-text="$l.unit_hours"/>
						</div>
						<div>
							<span :class="$style.countdownDigits" v-text="countdown.minutes"/>
							<span :class="$style.countdownLabel" v-text="$l.unit_minutes"/>
						</div>
						<div>
							<span :class="$style.countdownDigits" v-text="countdown.seconds"/>
							<span :class="$style.countdownLabel" v-text="$l.unit_seconds"/>
						</div>
					</div>
				</template>
				<h2 v-else :class="$style.caption" v-text="$l.caption_matchStartsAnyMoment"/>
			</div>
			<div :class="$style.awayClub">
				<div :class="$style.clubLogo"/>
				<div :class="$style.clubName" v-text="awayClub.name"/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import Fixture from "@/model/Fixture"
	import { computed, onBeforeUnmount, PropType, ref } from "vue"


	export default {
		props: {
			fixture: { type: Object as PropType<Fixture>, required: true },
		},
		setup(props) {
			const awayClubRef = computed(() => props.fixture.awayClub)
			const currentTimestampRef = ref(Date.now())
			const homeClubRef = computed(() => props.fixture.homeClub)
			const styleRef = computed(() => ({
				"--VMatchBeforeStart-awayClubLogoBackground": `url("${awayClubRef.value.logoUrl}")`,
				"--VMatchBeforeStart-homeClubLogoBackground": `url("${homeClubRef.value.logoUrl}")`,
			}))

			const interval = setInterval(() => currentTimestampRef.value = Date.now(), 1_000)
			onBeforeUnmount(() => clearInterval(interval))

			const countdownRef = computed(() => {
				let seconds = (props.fixture.startTimestamp - currentTimestampRef.value) / 1_000
				if (seconds <= 0) {
					clearInterval(interval)

					return null
				}

				const secondsPerMinute = 60
				const secondsPerHour = 60 * secondsPerMinute
				const secondsPerDay = 24 * secondsPerHour

				const days = Math.floor(seconds / secondsPerDay)
				seconds %= secondsPerDay

				const hours = Math.floor(seconds / secondsPerHour)
				seconds %= secondsPerHour

				const minutes = Math.floor(seconds / secondsPerMinute)
				seconds = Math.floor(seconds % secondsPerMinute)

				return {
					days: `${days < 10 ? "0" : ""}${days}`,
					hours: `${hours < 10 ? "0" : ""}${hours}`,
					minutes: `${minutes < 10 ? "0" : ""}${minutes}`,
					seconds: `${seconds < 10 ? "0" : ""}${seconds}`,
				}
			})

			return {
				awayClub: awayClubRef,
				countdown: countdownRef,
				homeClub: homeClubRef,
				style: styleRef,
			}
		},
	}
</script>

<style lang="scss" module>
	.awayClub {
		border-bottom-right-radius: calc(8 * var(--scale));
		order: 3;
		position: relative;

		@media (orientation: portrait) {
			&::before {
				background: rgba(255, 255, 255, 0.5);
				content: '';
				font-size: calc(200 * var(--scale));
				font-weight: bold;
				height: calc(200 * var(--scale));
				left: 0;
				opacity: 0.5;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%) rotate(8deg);
				width: calc(3 * var(--scale));
			}
		}

		@media (orientation: landscape) {
			border-top-right-radius: calc(8 * var(--scale));
		}
	}

	.awayClub, .homeClub {
		align-items: center;
		background: rgba(18, 18, 42, 0.75);
		display: flex;
		flex-direction: column;
		height: calc(272 * var(--scale));
		justify-content: center;
		padding: 0 calc(16 * var(--scale));

		@media (orientation: landscape) {
			width: calc(196 * var(--scale));
		}

		@media (orientation: portrait) {
			width: 50%;
		}
	}

	.clubLogo {
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		height: calc(128 * var(--scale));
		width: calc(128 * var(--scale));

		.awayClub > & {
			background-image: var(--VMatchBeforeStart-awayClubLogoBackground);
		}

		.homeClub > & {
			background-image: var(--VMatchBeforeStart-homeClubLogoBackground);
		}
	}

	.clubName {
		font-size: calc(24 * var(--scale));
		font-weight: bold;
		line-height: calc(32 * var(--scale));
		margin-top: calc(16 * var(--scale));
		text-align: center;
		text-transform: uppercase;
	}

	.caption {
		font-size: calc(24 * var(--scale));
		line-height: calc(32 * var(--scale));
		text-align: center;
		text-transform: uppercase;
	}

	.container {
		display: flex;
		flex-wrap: wrap;

		@media (orientation: portrait) {
			width: calc(328 * var(--scale));
		}
	}

	.content {
		align-items: center;
		background: rgba(18, 18, 42, 0.75);
		display: flex;
		flex-direction: column;
		height: calc(272 * var(--scale));
		justify-content: center;
		padding: 0 calc(16 * var(--scale));

		@media (orientation: landscape) {
			order: 2;
			width: calc(344 * var(--scale));
		}

		@media (orientation: portrait) {
			border-top-left-radius: calc(8 * var(--scale));
			border-top-right-radius: calc(8 * var(--scale));
			order: 1;
			width: 100%;
		}
	}

	.countdown {
		display: flex;
		margin-top: calc(10 * var(--scale));
		text-align: center;

		> div {
			display: grid;
			grid-auto-flow: column;
			grid-template-columns: auto auto;
			grid-template-rows: auto auto;

			&:not(:last-child)::after {
				content: ':';
				font-size: calc(48 * var(--scale));
				font-weight: normal;
				line-height: calc(48 * var(--scale));
				margin: calc(-3 * var(--scale)) calc(4 * var(--scale)) 0 calc(4 * var(--scale));
			}
		}

		&Digits {
			font-size: calc(48 * var(--scale));
			font-weight: bold;
			line-height: calc(48 * var(--scale));
		}

		&Label {
			font-size: calc(12 * var(--scale));
			line-height: calc(12 * var(--scale));
			margin-top: calc(8 * var(--scale));
			text-transform: uppercase;
		}
	}

	.homeClub {
		border-bottom-left-radius: calc(8 * var(--scale));

		@media (orientation: landscape) {
			border-top-left-radius: calc(8 * var(--scale));
			order: 1;
		}

		@media (orientation: portrait) {
			order: 2;
		}
	}

	.root {
		align-items: center;
		background: center no-repeat url(/public/images/stadium_background.jpeg);
		background-size: cover;
		display: flex;
		justify-content: center;
		user-select: none;
	}
</style>
