<template>
	<div :class="$style.root">
		<div :class="$style.left">
			<span v-text="item.leftValue.formatted"/>
			<span v-if="item.leftSecondaryValue" :class="$style.secondaryValue">({{ item.leftSecondaryValue.formatted }})</span>
		</div>
		<span :class="$style.label" v-text="item.label"/>
		<div :class="$style.right">
			<span v-if="item.rightSecondaryValue" :class="$style.secondaryValue">({{ item.rightSecondaryValue.formatted }})</span>
			<span v-text="item.rightValue.formatted"/>
		</div>
	</div>
</template>

<script lang="ts">
	import { VMMatchStatisticsSimpleComparisonItem } from "@/statistics/model/VMMatchStatisticsComparisonItem"
	import { defineComponent, PropType } from "vue"


	export default defineComponent({
		props: {
			item: { type: Object as PropType<VMMatchStatisticsSimpleComparisonItem>, required: true },
		},
	})
</script>

<style lang="scss" module>
	.label {
		align-items: center;
		display: flex;
		font-weight: normal;
		padding: 0 calc(8 * var(--scale));
	}

	.left, .right {
		align-items: center;
		display: flex;
		flex: 1;
	}

	.right {
		justify-content: flex-end;
		text-align: right;
	}

	.root {
		color: #EDE5DC;
		display: flex;
		font-size: calc(14 * var(--scale));
		font-weight: bold;
		height: calc(44 * var(--scale));
		justify-content: space-evenly;
		line-height: calc(44 * var(--scale));
		padding: 0 calc(16 * var(--scale));
	}

	.secondaryValue {
		font-weight: normal;
		opacity: 0.65;

		.left > & {
			padding-left: calc(4 * var(--scale));
		}

		.right > & {
			padding-right: calc(4 * var(--scale));
		}
	}
</style>
