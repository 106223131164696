
import { defineComponent, PropType } from 'vue'

// FIXME: Create a model once backend API is done
export interface Legend {
  title: string,
  color: string,
}

type Change = 'up' | 'same' | 'down'

export interface Standing {
  change: Change
}

export default defineComponent({
  props: {
    getTagHandle: { type: Function, required: true },
    legends: { type: Object as PropType<Legend[]>, required: true },
    standings: { type: Object as PropType<Standing[]>, required: true }
  }
})
