import LiveState from "@/live/model/LiveState";
import MatchPositions from "@/model/MatchPositions";
import MatchPositionsBall from "@/model/MatchPositionsBall";
import MatchPositionsPlayer from "@/model/MatchPositionsPlayer";
import { associateByTo, error } from "@/utility"
import { stringifyQuery } from "vue-router";

export default class PositionInterpolatorLinear {
    
    private _state // chronologically sorted array of frames

    constructor(
        state: LiveState | null, 
    ) {
        this._state = state
    }

    interpolate(timestamp: number): any{
        if (!this._state)
            return null
        //if (positionsFrames.length == 0)
        //    throw('cannot interpolate, no positions.')
        let index = this._state.framesBuffer.getNextIndexByTimestamp(timestamp)
        if (typeof(index)==='number'){
            return this._state.framesBuffer.getByIndex(index)
        }
        
        let frame1 = this._state.framesBuffer.getByIndex(index[0])
        let frame2 = this._state.framesBuffer.getByIndex(index[1])
        let r1 = (frame2.timestamp - timestamp) / (frame2.timestamp - frame1.timestamp)
        let r2 = (timestamp - frame1.timestamp) / (frame2.timestamp - frame1.timestamp)
        let ball = new MatchPositionsBall(
            r1 * frame1.ball.x + r2 * frame2.ball.x, 
            r1 * frame1.ball.y + r2 * frame2.ball.y, 
            r1 * frame1.ball.z + r2 * frame2.ball.z
        )
        
        let players = new Map<string, MatchPositionsPlayer>()
        for (const [playerId, frame1Player] of frame1.players.entries()){
            
            if (frame2.players.has(playerId)){
                const frame2Player = frame2.players.get(playerId)
                players.set(playerId, new MatchPositionsPlayer(
                    r1 * frame1Player.x + r2 * frame2Player.x, 
                    r1 * frame1Player.y + r2 * frame2Player.y, 
                ))
            }
        }
        return new MatchPositions(
            ball, 
            players,
            timestamp
        )
        
    }

    setState( state: LiveState) {
        this._state = state
    }
}