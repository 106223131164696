
	import { defineComponent } from '@vue/runtime-core'
	import soundIcon from '@/assets/soundRadio.svg'

	export default defineComponent({
		props: {
			volume: { type: Number, required: true },
		},
		emits: {
			"update:volume": (_: number) => true,
		},
		setup(props, { emit }) {
			function onInput(event: InputEvent) {
				emit("update:volume", Number.parseFloat((event.target as HTMLInputElement).value))
			}

			return { onInput, soundIcon }
		},
	})
