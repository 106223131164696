<template>
	<div :class="$style.root">
		<router-view/>
	</div>
</template>

<script lang="ts">
	import FullscreenController, { FullscreenControllerInjectionKey } from "@/utility/FullscreenController"
	import { defineComponent, onBeforeUnmount, provide } from "vue"


	export default defineComponent({
		setup() {
			const fullscreenController = new FullscreenController()

			provide(FullscreenControllerInjectionKey, fullscreenController)

			function onResize() {
				// iOS Safari incorrectly scrolls page on orientation change.
				document.documentElement.scrollTop = 0
			}

			window.addEventListener("resize", onResize)

			onBeforeUnmount(() => {
				window.removeEventListener("resize", onResize)
			})
		},
	})
</script>

<style lang="scss" module>
	@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

	html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
	pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset,
	form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas,
	details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section,
	summary, time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}

	/* HTML5 display-role reset for older browsers */

	article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
		display: block;
	}

	html, body, :global(#app) {
		display: grid;
		grid-area: child;
		height: 100%;
		max-height: 100%;
		max-width: 100%;
		min-height: 100%;
		min-width: 100%;
		overflow: hidden;
		width: 100%;

		// Some browser extensions add additional elements to <html> and <body>.
		// We force the grid to use full size for the expected element with 'grid-area: child'.
		grid-template-columns: 100%;
		grid-template-rows: 100%;
		grid-template-areas: "child";
	}

	html, body {
		background: #8CBED6;
		color: #EDE5DC;
	}

	ol, ul {
		list-style: none;
	}

	blockquote, q {
		quotes: none;
	}

	blockquote {
		&:before, &:after {
			content: '';
			content: none;
		}
	}

	q {
		&:before, &:after {
			content: '';
			content: none;
		}
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	body {
		font-family: 'Source Sans Pro', sans-serif;
		letter-spacing: 0.5px;
	}

	* {
		box-sizing: border-box;
	}

	.root {
		display: grid;
		grid-area: child;
		height: 100%;
		max-height: 100%;
		max-width: 100%;
		min-height: 100%;
		min-width: 100%;
		width: 100%;

		* {
			scrollbar-color: rgb(237, 229, 220, 0.5) transparent;
			scrollbar-width: thin;

			&::-webkit-scrollbar {
				width: 12px;
			}

			&::-webkit-scrollbar-track {
				background: transparent;
			}

			&::-webkit-scrollbar-thumb {
				background-clip: content-box;
				background-color: rgb(237, 229, 220, 0.5);
				border-radius: 6px;
				border: 3px solid transparent;
				transition: background-color 0.3s ease-in-out;

				&:hover {
					background-color: rgb(237, 229, 220, 1);
				}
			}
		}

		@media (orientation: landscape) {
			--scale: calc(min(min(100vh / 9, 100vw / 16) / (780 / 16), 1px));
		}

		@media (orientation: portrait) {
			--scale: calc(min(min(100vh / 4, 100vw / 3) / (360 / 3), 1px));
		}
	}
</style>
