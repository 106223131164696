import LiveState from "@/live/model/LiveState"
import LiveStateClub from "@/live/model/LiveStateClub"
import Fixture from "@/model/Fixture"
import MatchEvent from "@/model/MatchEvent"
import { DeleteMatchEventType } from "@/model/MatchEventType"
import MatchPositions from "@/model/MatchPositions"
import MatchStatistics from "@/model/MatchStatistics"
import MatchStatus from "@/model/MatchStatus"
import MatchTiming from "@/model/MatchTiming"
import Player from "@/model/Player"
import Score from "@/model/Score"
import CyclcicFramesBuffer from "@/utility/CyclicFramesBuffer"
import { associateBy, objectToMap } from "@/utility"


export default class LiveStateUpdate {

	public awayClub: LiveStateClub | null = null
	public events: Map<string, MatchEvent> = new Map()
	public fixture: Fixture | null = null
	public homeClub: LiveStateClub | null = null
	public playerIdsOnField: ReadonlyArray<string> | null = null
	public players: Map<string, Player> | null = null
	public score: Score | null = null
	public statistics: MatchStatistics | null = null
	public status: MatchStatus | null = null
	public timing: MatchTiming | null = null
	public framesBuffer: Array<CyclcicFramesBuffer> | null = null


	apply(update: LiveStateUpdate) {
		if (update.awayClub)
			this.awayClub = update.awayClub

		const events = this.events
		for (const event of update.events.values())
			if (event.type instanceof DeleteMatchEventType)
				events.delete(event.id)
			else
				events.set(event.id, event)

		if (update.fixture)
			this.fixture = update.fixture

		if (update.homeClub)
			this.homeClub = update.homeClub

		if (update.playerIdsOnField)
			this.playerIdsOnField = update.playerIdsOnField

		if (update.players)
			this.players = update.players

		if (update.score)
			this.score = update.score

		if (update.statistics)
			this.statistics = update.statistics

		if (update.status)
			this.status = update.status

		if (update.timing)
			this.timing = update.timing

	}


	static fromJson(json: any) {
		const update = new LiveStateUpdate()
		update.awayClub = json.awayClub ? LiveStateClub.fromJson(json.awayClub) : null
		update.events = json.events ? objectToMap(json.events, it => MatchEvent.fromJson(it)) : new Map()
		update.fixture = json.fixture ? Fixture.fromJson(json.fixture) : null
		update.homeClub = json.homeClub ? LiveStateClub.fromJson(json.homeClub) : null
		update.playerIdsOnField = json.playerIdsOnField ?? null
		update.players = json.players ? associateBy(json.players.map(it => Player.fromJson(it)), it => it.id) : null
		update.score = json.score ? Score.fromJson(json.score) : null
		update.statistics = json.statistics ? MatchStatistics.fromJson(json.statistics) : null
		update.status = json.status ?? null
		update.timing = json.timing ? MatchTiming.fromJson(json.timing) : null

		return update
	}


	toState(timestamp: number): LiveState | null {
		if (!this.awayClub)
			return null
		if (!this.fixture)
			return null
		if (!this.homeClub)
			return null
		if (!this.players)
			return null
		if (!this.score)
			return null
		if (!this.status)
			return null
		if (!this.timing)
			return null

		return new LiveState(
			this.awayClub,
			this.events,
			this.fixture,
			this.homeClub,
			this.playerIdsOnField ?? [],
			this.players,
			null,
			this.score,
			this.statistics,
			this.status,
			timestamp,
			this.timing
		)
	}
}
