export default class Competition {

	constructor(
		readonly id: string,
		readonly name: string,
	) {
		Object.freeze(this)
	}


	static fromJson(json: any) {
		return new this(
			json.id,
			json.name,
		)
	}
}
